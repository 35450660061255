import React, { useContext, useEffect, useRef, useState } from "react";
import "./styles.scss";
import photoDefault from "../../assets/images/roses-photo-default.png";
import { ICatalogResponse } from "../../types/catalog";
import { IMomentsUnseen } from "../../types/moments";
import { useUserInfo } from "../../hooks/userInfo";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useModal } from "../../hooks/useModal";
import { ToggleChatOpenContext } from "../../contexts/toggleChatOpen";
import { MomentsTriggerContext } from "../../contexts/momentTrigger";
import { FavoritesCatalogContext } from "../../contexts/favoritesCatalogTrigger";
import { useIconContext } from "../../contexts/iconNavContext";
import { useChat } from "../../contexts/openedChatContext";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "../../components/Buttons/ButtonNavigation";
import MomentsCircles from "../../components/Moments/MomentsCircles";
import MomentsTrack from "../../components/Moments/MomentsTrack";
import getUsersListAll from "../../api/getUsersListAll";
import getMomentsUnseen from "../../api/moments/getMomentsUnseen";
import getMomentSeen from "../../api/moments/getMomentsSeen";
import AddMediaPage from "../AddMediaPage";
import { FavoritesListContext } from "../../contexts/favoritesListTrigger";
import { useSearchCatalog } from "hooks/useSearchCatalog";

const CatalogPage: React.FC = () => {
  // Contexts
  const { userInfo } = useUserInfo();
  const { handleButtonClick, getIconClass, activeIcon } = useIconContext();
  const { setOpenUseModal, setModalContent } = useModal();
  const { setPrincipalScreen, setSecondaryScreen, setTertiaryScreen } =
    useDeskNavigation();
  const { favoritesCatalogTrigger, setFavoritesCatalogTrigger } = useContext(
    FavoritesCatalogContext
  );
  const { favoritesListTrigger } = useContext(FavoritesListContext);
  const { momentTrigger } = useContext(MomentsTriggerContext);
  const { isChatPageOpen } = useContext(ToggleChatOpenContext);
  const { activeChatId } = useChat();

  // Local States
  const [classWidth, setClassWidth] = useState("mobile-width");
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [counters, setCounters] = useState<string[]>([]); // MOCK
  const [filterType, setFilterType] = useState<
    "all" | "vip" | "favorites" | "guys" | "top-creators" | "girls"
  >("all");
  const [catalogData, setCatalogData] = useState<ICatalogResponse[] | []>([]);
  const [catalogFilteredData, setCatalogFilteredData] = useState<
    ICatalogResponse[] | []
  >([]);
  const [momentsUnseen, setMomentsUnseen] = useState<IMomentsUnseen[] | []>([]);
  const [momentsSeen, setMomentsSeen] = useState<IMomentsUnseen[] | []>([]);
  const [activatedButton, setActivatedButton] = useState(0);
  const divRef = useRef<HTMLDivElement | null>(null);

  const { searchValue, setSearchValue } = useSearchCatalog();

  useEffect(() => {
    const filteredCatalogData = catalogData
      .filter((profileCard) => {
        switch (filterType) {
          case "top-creators":
            return profileCard.isVip;
          case "girls":
            return (
              profileCard?.profile?.gender?.toLowerCase() === "woman" ||
              profileCard?.profile?.gender === null
            );
          case "guys":
            return profileCard?.profile?.gender?.toLowerCase() === "man";
          default:
            return profileCard;
        }
      })
      .sort((a, b) => (b.isVip ? 1 : 0) - (a.isVip ? 1 : 0));

    const search = filteredCatalogData.filter((catalog) =>
      catalog.displayname.toLowerCase().startsWith(searchValue.toLowerCase())
    );
    setCatalogFilteredData(search);
  }, [searchValue]);

  useEffect(() => {
    getCatalogData(userInfo.access_token);
  }, [userInfo.access_token, favoritesListTrigger]);

  useEffect(() => {
    momentsSeenRequest();
    momentsUnseenRequest();
  }, [momentTrigger]);

  useEffect(() => {
    if (activeIcon === "grid") {
      buttonGroupClickHandler("all");
      // the next line is necessary to activate the useEffect
      setActivatedButton(4);
      // >>
      setTimeout(() => {
        setActivatedButton(1);
      }, 100);
    }
  }, [handleButtonClick]);

  useEffect(() => {
    if (favoritesCatalogTrigger) {
      setCatalogFilteredData(
        catalogData
          .filter((profile) => profile.isFavorite)
          .sort((a, b) => (b.isVip ? 1 : 0) - (a.isVip ? 1 : 0))
      );
    } else {
      const filteredCatalogData = catalogData
        .filter((profileCard) => {
          switch (filterType) {
            case "top-creators":
              return profileCard.isVip;
            case "girls":
              return (
                profileCard?.profile?.gender?.toLowerCase() === "woman" ||
                profileCard?.profile?.gender === null
              );
            case "guys":
              return profileCard?.profile?.gender?.toLowerCase() === "man";
            default:
              return profileCard;
          }
        })
        .sort((a, b) => (b.isVip ? 1 : 0) - (a.isVip ? 1 : 0));
      setCatalogFilteredData(filteredCatalogData);
    }
  }, [catalogData, favoritesCatalogTrigger, userInfo.access_token, filterType]);

  useEffect(() => {
    const generateRandomNumber = (index: number) => {
      const randomNum = Math.floor(Math.random() * 1799) + 200 + index * 100;
      return randomNum;
    };

    const formatCounter = (num: number) => {
      if (num >= 1000) {
        return (num / 1000).toFixed(1) + "k";
      }
      return num.toString();
    };

    const randomCounters = catalogData.map((_, index) => {
      const randomCounter = generateRandomNumber(index);
      return formatCounter(randomCounter);
    });

    setCounters(randomCounters);
  }, [catalogData]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === divRef.current) {
          const { width } = entry.contentRect;

          let size = Math.floor(width + 48);

          if (size < 560) {
            setClassWidth("mobile-width");
          } else if (size >= 560 && size < 760) {
            setClassWidth("mobile-width-plus");
          } else if (size >= 760 && size < 1024) {
            setClassWidth("super-mobile-width");
          } else if (size >= 1024 && size < 1140) {
            setClassWidth("tablet-width");
          } else if (size >= 1140 && size < 1600) {
            setClassWidth("widescreen-width");
          } else {
            setClassWidth("hyper-widescreen-width");
          }
        }
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  const catalogHandlerCallback = async (profile: ICatalogResponse) => {
    if (isChatPageOpen) {
      setTimeout(() => {
        setSecondaryScreen("/personal-chat", {
          chatId: activeChatId ?? "",
          userId: profile.userId,
        });
      }, 0);
    } else {
      setSecondaryScreen("");
    }

    setTertiaryScreen("");

    setPrincipalScreen("/interaction", { userId: profile.userId });
  };

  const momentsSeenRequest = async () => {
    try {
      const resMomentsSeen = await getMomentSeen(userInfo.access_token, 10);

      switch (resMomentsSeen.status) {
        case 200:
          setMomentsSeen(resMomentsSeen.res);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const momentsUnseenRequest = async () => {
    try {
      const resMomentsUnseen = await getMomentsUnseen(
        userInfo.access_token,
        10
      );

      switch (resMomentsUnseen.status) {
        case 200:
          setMomentsUnseen(resMomentsUnseen.res);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getCatalogData = async (userToken: string) => {
    let response = await getUsersListAll(userToken);

    switch (response?.status) {
      case 200:
        setCatalogData(response.res);
        if (activeIcon === "heart-outline") {
          const favorites = catalogData
            .filter((profile) => profile.isFavorite)
            .sort((a, b) => (b.isVip ? 1 : 0) - (a.isVip ? 1 : 0));

          setCatalogFilteredData(favorites);
        } else {
          setCatalogData(response.res);
          setCatalogFilteredData(response.res);
        }
        break;
      default:
        break;
    }
  };

  const buttonGroupClickHandler = (
    filter: "all" | "vip" | "favorites" | "guys" | "top-creators" | "girls"
  ) => {
    setFavoritesCatalogTrigger(false);
    setFilterType(filter);
    handleButtonClick("grid");
  };

  const buttonsGroup: ButtonNavigationInfo[] = [
    {
      text: "All",
      clickAction: () => buttonGroupClickHandler("all"),
      id: 1,
      textRight: catalogData.length.toString(),
    },
    {
      text: "Girls",
      clickAction: () => buttonGroupClickHandler("girls"),
      id: 2,
      textRight: catalogData
        .filter(
          (catalog) =>
            catalog.profile.gender === "WOMAN" ||
            catalog.profile.gender === null
        )
        .length.toString(),
    },
    {
      text: "Guys",
      clickAction: () => buttonGroupClickHandler("guys"),
      id: 3,
      textRight: catalogData
        .filter((catalog) => catalog.profile.gender === "MAN")
        .length.toString(),
    },
    {
      text: "Top Creators",
      clickAction: () => buttonGroupClickHandler("top-creators"),
      id: 4,
      textRight: catalogData
        .filter((catalog) => catalog.isVip)
        .length.toString(),
    },
  ];

  const addMomentHandler = () => {
    setModalContent(<AddMediaPage previousPage="home" />);
    setOpenUseModal(true);
  };

  const clickMoment = (userId: string, index: number) => {
    setModalContent(
      <MomentsTrack
        indexClicked={index}
        userIdToStarted={userId}
        allUnseenUserIds={momentsUnseen.map((moment) => moment.userId)}
        allSeenUserIds={momentsSeen.map((moment) => moment.userId)}
      />
    );

    setOpenUseModal(true);
  };

  const classNamesCatalog = ["catalog-page", classWidth].join(" ").trim();

  return (
    <div className="catalog-page-wrapper">
      <div className="actions-wrapper">
        <MomentsCircles
          showAddMoment={userInfo.account_type === "CREATOR"}
          // showAddMoment
          clickMoment={clickMoment}
          addMomentClick={addMomentHandler}
          momentsDetails={{ momentsSeen, momentsUnseen }}
          className="add-moments-container__moments padding-left-24"
        />
        <ButtonNavigation
          buttons={buttonsGroup}
          className="button-navigation-catalog"
          activatedButton={activatedButton}
        />
      </div>

      <div ref={divRef} className={classNamesCatalog}>
        {catalogFilteredData?.map((profileCard, index) => {
          return (
            <div
              onClick={() => {
                if (catalogHandlerCallback) {
                  catalogHandlerCallback(profileCard);
                }
              }}
              //className={`profile-card ${index % 7 === 0 ? "grid-2" : ""} ${
              className={`profile-card ${index % 7 === 0 ? "" : ""} ${
                !isHovered ? "remove-linear-gradient" : ""
              }`}
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <img
                src={
                  hoveredIndex === index
                    ? profileCard?.profile.photos[1]?.url
                    : profileCard?.profile.photos[0]?.url || photoDefault
                }
                alt="catalog gallery"
                className="catalog-image"
                loading="lazy"
              />

              <div className="profile-title">
                <div className="user-info">
                  <div className="user-title">
                    <p className="profile-info small no-margin">
                      {profileCard?.displayname}
                    </p>
                    {profileCard?.isVip && (
                      <span className="profile__icon icon icon-burn"></span>
                    )}
                    {profileCard.profile?.isAI && (
                      <span className="profile-ia-box">AI</span>
                    )}
                  </div>

                  <div className="user-stats">
                    <p className="counter">{profileCard?.profile.age} years</p>
                    <div className="followers-counter">
                      <span className="icon icon-heart-solid icon-sm icon-gray-light"></span>
                      <p className="counter">{counters[index]}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CatalogPage;
