import React, { useEffect, useState } from "react";
import "./styles.scss";
interface ToastProps {
  type: "error" | "success" | "warning" | "info" | "password" | undefined;
  title: string;
  description: string;
  isVisible: boolean;
  timeout?: number;
  setIsVisible: (a: boolean) => void;
}

const Toast: React.FC<ToastProps> = ({
  type,
  title,
  description,
  isVisible,
  timeout = 5000,
  setIsVisible,
}) => {
  const [toastState, setToastState] = useState("" as string);

  useEffect(() => {
    if (isVisible) {
      setToastState("show");
    } else {
      setToastState("hide");
    }
  }, [isVisible]);

  useEffect(() => {
    if (toastState === "hide") {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, timeout);

      return () => clearTimeout(timer);
    }
  }, [setIsVisible, toastState]);

  let iconColor;
  let iconType;
  switch (type) {
    case "success":
      iconColor = "success";
      iconType = "check";
      break;
    case "warning":
      iconColor = "warning";
      iconType = "info";
      break;
    case "info":
      iconColor = "info";
      iconType = "info";
      break;
    case "error":
    default:
      iconColor = "error";
      iconType = "info";
      break;
  }

  return (
    <div className={`toastContainer ${toastState}`} role="">
      {/* <div className="iconContainer margin-right-16">
        <img
          src={iconSource}
          alt={`${type} icon`}
          style={{
            width: 19,
            height: 19,
          }}
        />
      </div> */}
      <span
        className={`icon icon-${iconType} icon-md icon-${iconColor} icon-round`}
      ></span>
      <div className="textContainer">
        <p className="textPrimary no-margin">{title}</p>
        <p className="textSecondary no-margin">{description}</p>
      </div>
    </div>
  );
};

export default Toast;
