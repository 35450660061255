import React, { useEffect, useState } from "react";
import "./styles.scss";
import MomentsCircles from "components/Moments/MomentsCircles";
import { useUserInfo } from "hooks/userInfo";
import MomentsTrack from "components/Moments/MomentsTrack";
import AddMediaPage from "pages/AddMediaPage";
import { useModal } from "hooks/useModal";
import { IMoments, IMomentsUnseen } from "types/moments";
import Toast from "components/Toast";
import { useToast } from "hooks/useToast";
import getUserMoments from "api/moments/getUserMoments";

const CreatorDashboardPage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const { setOpenUseModal, setModalContent } = useModal();
  const { toastShow, toastConfig, setToastShow, handleToast } = useToast();
  const [momentsUnseen, setMomentsUnseen] = useState<IMomentsUnseen[] | []>([]);
  const [momentsSeen, setMomentsSeen] = useState<IMomentsUnseen[] | []>([]);
  const [creatorSelfMoments, setCreatorSelfMoments] = useState<IMoments[] | []>(
    []
  );

  useEffect(() => {
    getSelfMoments();
  }, []);

  const addMomentHandler = () => {
    setModalContent(<AddMediaPage previousPage="home" />);
    setOpenUseModal(true);
  };

  const clickMoment = (userId: string, index: number) => {
    setModalContent(
      <MomentsTrack
        indexClicked={index}
        userIdToStarted={userId}
        allUnseenUserIds={momentsUnseen.map((moment) => moment.userId)}
        allSeenUserIds={momentsSeen.map((moment) => moment.userId)}
      />
    );

    setOpenUseModal(true);
  };

  const getSelfMoments = async () => {
    try {
      const request = await getUserMoments(
        userInfo.access_token,
        userInfo.user_id
      );

      switch (request.status) {
        case 200:
          const moments: IMoments[] = request.res;

          setCreatorSelfMoments(moments);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="creator-dashboard">
      <MomentsCircles
        showAddMoment={userInfo.account_type === "CREATOR"}
        clickMoment={clickMoment}
        addMomentClick={addMomentHandler}
        momentsDetails={{ momentsSeen, momentsUnseen }}
        className="creator-dashboard padding-left-24"
        creatorSelfMoments={creatorSelfMoments}
      />

      <Toast
        type={toastConfig.type}
        title={toastConfig.title}
        description={toastConfig.description}
        isVisible={toastShow}
        setIsVisible={setToastShow}
      />
    </div>
  );
};

export default CreatorDashboardPage;
