import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./styles.scss";
import MomentsCards from "../MomentsCards";
import Progressbar from "../../Progressbar";
import CloseIcon from "../../../assets/icons/closeIcons/close-icon-peach.svg";
import photoDefault from "../../../assets/icons/profile-icon-gray.svg";
import { useModal } from "../../../hooks/useModal";
import { useUserInfo } from "../../../hooks/userInfo";
import { IMoments } from "../../../types/moments";
import getUserMoments from "../../../api/moments/getUserMoments";
import postMomentView from "../../../api/moments/postMomentView";
import getMomentsUnseen from "../../../api/moments/getMomentsUnseen";
import getMomentSeen from "../../../api/moments/getMomentsSeen";
import getPublicDetails from "../../../api/publicDetails/getProfileDetails";
import { IUserPublicInfo } from "../../../types/userInfo";
import Loading from "../../Loading";
import postLikeMoment from "../../../api/moments/postLikeMoment";
import deleteLikeMoment from "../../../api/moments/deleteLikeMoment";
import postMomentPay from "../../../api/moments/postMomentPay";
import CloseIconPink from "../../../assets/icons/closeIcons/close-icon-pink.svg";
import CheckSimpleGreen from "../../../assets/icons/check-simple-green.svg";
import Button from "../../Buttons/Button";
import { useDeskNavigation } from "../../../hooks/useDeskNavigation";
import { useWebSocket } from "../../../contexts/webSocketContext";
import { Chat } from "../../../types/chatInfo";
import CreateChatContact from "../../../api/postCreateChatContact";
import { useChat } from "../../../contexts/openedChatContext";
import { MomentsTriggerContext } from "../../../contexts/momentTrigger";
import Toast from "../../Toast";
import { IToast } from "../../../types/toast";

interface MomentsTrackProps {
  className?: string;
  userIdToStarted: string;
  allUnseenUserIds: string[];
  allSeenUserIds: string[];
  indexClicked: number;
}

const MomentsTrack: React.FC<MomentsTrackProps> = (props) => {
  const {
    allUnseenUserIds,
    userIdToStarted,
    className,
    allSeenUserIds,
    indexClicked,
  } = props;
  const { setMomentTrigger } = useContext(MomentsTriggerContext);

  const { userInfo } = useUserInfo();
  const { setOpenUseModal, setModalContent } = useModal();
  const { getChats, sendMessage } = useWebSocket();
  const {
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    setFeedScreen,
  } = useDeskNavigation();
  const { setActiveChatId } = useChat();

  const [profileAvatar, setProfileAvatar] = useState("");

  const [momentIndexCurrent, setMomentIndexCurrent] = useState(0);
  const [creatorIndexCurrent, setCreatorIndexCurrent] = useState(indexClicked);
  const [momentsByUserId, setMomentsByUserId] = useState<IMoments[]>([]);
  const [creatorMomentInfo, setCreatorMomentInfo] = useState(
    {} as IUserPublicInfo
  );
  const [momentsMessage, setMomentsMessage] = useState("");
  const [userIdList, setUserIdList] = useState<string[]>([]);
  const [userIdListFilter, setUserIdListFilter] = useState<string[]>([]);
  const [isLoadingMoments, setIsLoadingMoments] = useState(true);
  const [userIdShowing, setUserIdShowing] = useState(userIdToStarted);
  const [isPausedMoment, setIsPausedMoment] = useState(false);
  const [paymentMomentStatus, setPaymentMomentStatus] = useState<
    "success" | "error" | undefined
  >(undefined);
  const [momentAbleToShow, setMomentAbleToShow] = useState(false);
  const [chats, setChats] = useState<Chat[]>([]);
  const momentsTrackRef = useRef<HTMLDivElement>(null);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const startX = useRef<number | null>(null);

  const [clickTime, setClickTime] = useState(0);

  useEffect(() => {
    getListChats();
    const handleClick = (event: MouseEvent) => {
      if (
        momentsTrackRef.current &&
        !momentsTrackRef.current.contains(event.target as Node)
      ) {
        closeModalHandler();
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    const combinedList = [
      userIdToStarted,
      ...allUnseenUserIds,
      ...allSeenUserIds,
    ];

    const removeRepetitionList = Array.from(new Set(combinedList));
    setUserIdListFilter(removeRepetitionList);
    setUserIdList(removeRepetitionList);
  }, [userIdToStarted, allUnseenUserIds, allSeenUserIds]);

  useEffect(() => {
    setIsLoadingMoments(true);
    Promise.all([
      getMomentsByUserId(userIdShowing),
      getMomentCreatorInfo(userIdShowing),
      // momentsSeenRequest(),
    ]).finally(() => setIsLoadingMoments(false));
  }, [userIdShowing, userInfo.access_token]);

  const getMomentsByUserId = async (userId: string, indexCurrent = 0) => {
    const request = await getUserMoments(userInfo.access_token, userId);
    setMomentsByUserId(request.res);
    setMomentIndexCurrent(indexCurrent);
  };

  const getMomentCreatorInfo = async (userId: string) => {
    const request = await getPublicDetails(userInfo.access_token, userId);
    setCreatorMomentInfo(request.res);

    setProfileAvatar(
      request.res.profile?.photos[0].thumbnailUrl || photoDefault
    );
  };

  useEffect(() => {
    const currentMoment = momentsByUserId[momentIndexCurrent];
    const canShowMoment = !currentMoment?.paidContent || currentMoment?.hasPaid;
    setMomentAbleToShow(canShowMoment);
  }, [momentIndexCurrent, momentsByUserId]);

  const getListChats = useCallback(() => {
    getChats({}, (res) => {
      setChats(res);
    });
  }, [getChats]);

  const momentView = async (momentId: string) => {
    const request = await postMomentView(userInfo.access_token, momentId);
  };

  const momentsUnseenRequest = async () => {
    const request = await getMomentsUnseen(userInfo.access_token, 10);
  };

  // const momentsSeenRequest = async () => {
  //   const request = await getMomentSeen(userInfo.access_token);
  // };

  const clickLikeDislikeMoment = useCallback(
    async (momentId: string) => {
      const currentMoment = momentsByUserId[momentIndexCurrent];

      if (currentMoment.hasLiked) {
        await deleteLikeMoment(userInfo.access_token, momentId);

        setMomentsByUserId((prevMoments) =>
          prevMoments.map((moment) =>
            moment.momentId === momentId
              ? {
                  ...moment,
                  hasLiked: false,
                  _count: {
                    ...moment._count,
                    likedBy: moment._count.likedBy - 1,
                  },
                }
              : moment
          )
        );
      } else {
        await postLikeMoment(userInfo.access_token, momentId);
        setMomentsByUserId((prevMoments) =>
          prevMoments.map((moment) =>
            moment.momentId === momentId
              ? {
                  ...moment,
                  hasLiked: true,
                  _count: {
                    ...moment._count,
                    likedBy: moment._count.likedBy + 1,
                  },
                }
              : moment
          )
        );
      }
    },
    [momentIndexCurrent, momentsByUserId, userInfo.access_token]
  );

  const conveyorActionsHandler = (completed: boolean) => {
    setIsLoadingMoments(true);

    momentView(momentsByUserId[momentIndexCurrent].momentId);
    if (completed && momentIndexCurrent < momentsByUserId.length - 1) {
      setMomentIndexCurrent((prev) => prev + 1);
    } else if (completed && momentIndexCurrent === momentsByUserId.length - 1) {
      const remainingIds = userIdListFilter.filter(
        (id) => id !== userIdShowing
      );
      setUserIdListFilter(remainingIds);
      setCreatorIndexCurrent((prev) => prev + 1);

      if (remainingIds.length > 0) {
        setUserIdShowing(remainingIds[0]);
      } else {
        closeModalHandler();
      }
    }

    setIsLoadingMoments(false);
  };

  const closeModalHandler = () => {
    setMomentTrigger((prev) => !prev);
    setOpenUseModal(false);
  };

  const playPauseMoment = (event: boolean) => {
    setIsPausedMoment(event);
  };

  const unlockMomentHandler = async (momentId: string) => {
    setIsLoadingMoments(true);

    try {
      const request = await postMomentPay(userInfo.access_token, momentId);

      switch (request.status) {
        case 201:
          getMomentsByUserId(userIdShowing, momentIndexCurrent);
          setToastConfig({
            description: "Enjoy the moment!",
            title: "Unlocked with success!",
            type: "success",
          });

          setToastShow(true);
          setMomentAbleToShow(true);

          setTimeout(() => {
            setToastShow(false);
          }, 4000);

          break;
        default:
          setToastConfig({
            description: "We can't unlock",
            title: "We have a problem",
            type: "error",
          });

          setToastShow(true);

          setMomentAbleToShow(false);

          setTimeout(() => {
            setToastShow(false);
          }, 4000);

          break;
      }
    } catch (error) {
      console.error("error:", error);
      setPaymentMomentStatus("error");
    }
    setIsLoadingMoments(false);
  };

  const checkWalletHandler = () => {
    setOpenUseModal(false);
    setModalContent("");
    setPrincipalScreen("/user-settings-wallet");
  };

  const closeModalPaymentStatus = () => {
    if (paymentMomentStatus === "success") {
      setIsLoadingMoments(true);
      getMomentsByUserId(userIdShowing, momentIndexCurrent);
      setIsLoadingMoments(false);
    }
    setPaymentMomentStatus(undefined);
  };

  const onMouseDown = (e: React.MouseEvent) => {
    startX.current = e.clientX;
    const screenHeight = window.innerHeight;
    const footerHeight = 120;
    const centralAreaHeight = screenHeight - footerHeight;

    if (e.clientY > 120 && e.clientY < centralAreaHeight) {
      setClickTime(Math.round(e.timeStamp));
    }
  };

  const onMouseUp = (e: React.MouseEvent) => {
    const timeClickUp = Math.round(e.timeStamp);
    if (timeClickUp - clickTime < 500) {
      handleSectionClick(e);
    }

    startX.current = null;
  };

  const markTouchPosition = (e: React.TouchEvent) => {
    startX.current = e.touches[0].clientX;
  };

  const markClickDrag = (e: React.MouseEvent) => {
    if (startX.current === null) return;

    const diffX = e.clientX - (startX.current || 0);
    if (diffX > 100) {
      if (creatorIndexCurrent === 0) {
        setOpenUseModal(false);
      } else {
        setUserIdShowing(userIdList[creatorIndexCurrent]);
        setCreatorIndexCurrent((prev) => prev - 1);
      }
      startX.current = null;
    } else if (diffX < -100) {
      if (userIdList.length <= creatorIndexCurrent + 1) {
        setOpenUseModal(false);
      } else {
        setUserIdShowing(userIdList[creatorIndexCurrent + 1]);
        setCreatorIndexCurrent((prev) => prev + 1);
      }
      startX.current = null;
    }
  };

  const markTouchDrag = (e: React.TouchEvent) => {
    if (startX.current === null) return;

    const diffX = e.touches[0].clientX - (startX.current || 0);
    if (diffX > 100) {
      if (creatorIndexCurrent === 0) {
        setOpenUseModal(false);
      } else {
        setUserIdShowing(userIdList[creatorIndexCurrent]);
        setCreatorIndexCurrent((prev) => prev - 1);
      }
      startX.current = null;
    } else if (diffX < -100) {
      if (userIdList.length <= creatorIndexCurrent + 1) {
        setOpenUseModal(false);
      } else {
        setUserIdShowing(userIdList[creatorIndexCurrent + 1]);
        setCreatorIndexCurrent((prev) => prev + 1);
      }
      startX.current = null;
    }
  };

  const hasClickArea = () => {
    if (momentsByUserId[momentIndexCurrent]?.paidContent) {
      if (momentsByUserId[momentIndexCurrent]?.hasPaid) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const handleSectionClick = (e: React.MouseEvent) => {
    if (!momentsTrackRef.current) return;
    if (hasClickArea() && paymentMomentStatus === undefined) {
      const sectionWidth = momentsTrackRef.current.clientWidth;
      const clickPosition = e.clientX;
      const sectionLeftBoundary =
        momentsTrackRef.current.getBoundingClientRect().left;

      const relativeClickPosition = clickPosition - sectionLeftBoundary;

      if (relativeClickPosition < sectionWidth / 2) {
        if (momentIndexCurrent === 0) {
          const hasABeforeCreator = userIdList[creatorIndexCurrent - 1];
          if (!hasABeforeCreator) {
            closeModalHandler();
            return;
          }

          setCreatorIndexCurrent((prev) => {
            const newIndex = prev - 1;
            setUserIdShowing(userIdList[newIndex]);
            return newIndex;
          });
          setMomentIndexCurrent(0);
        } else {
          setMomentIndexCurrent((prev) => prev - 1);
        }
      } else {
        conveyorActionsHandler(true);
      }
    }
  };

  const handleSendMessage = async () => {
    const chatId = chats.find(
      (chat) =>
        chat.chatParticipants[0]?.user?.userId === creatorMomentInfo.userId
    )?.chatId;
    if (chatId) {
      try {
        await new Promise<void>((resolve, reject) => {
          sendMessage({ chatId, content: momentsMessage }, () => {
            resolve();
            setMomentsMessage("");
          });
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      setSecondaryScreen("");
      setTertiaryScreen("");
      // setIsChatPageOpen(true);
      setOpenUseModal(false);

      const chatContactService = CreateChatContact();
      const response = await chatContactService.postCreateChatContact(
        creatorMomentInfo.userId,
        userInfo.access_token
      );

      switch (response.status) {
        case 201:
          setActiveChatId(response.res.chatId);
          break;

        default:
          break;
      }
      setPrincipalScreen("/personal-chat", {
        chatId: response.res.chatId,
        userId: creatorMomentInfo.userId,
        isLocked: JSON.stringify(false),
      });
    }
  };

  const clickUserFeed = (creatorId: string) => {
    setTimeout(() => {
      setOpenUseModal(false);
      setFeedScreen("/feed", { userId: creatorId });
    }, 100);
  };

  if (isLoadingMoments) {
    return (
      <div className="moments-track__loading-page-wrapper">
        <Loading />
      </div>
    );
  }

  return (
    <div ref={momentsTrackRef} className={`moments-track-wrapper`}>
      <section
        // ref={momentsTrackRef}
        id="moments-track"
        className={className ?? ""}
        onMouseDown={onMouseDown}
        onMouseMove={markClickDrag}
        onTouchStart={markTouchPosition}
        onTouchMove={markTouchDrag}
        onMouseUp={onMouseUp}
      >
        <div className="moments-track__content-wrapper">
          <div className="content-wrapper__progressbar-wrapper">
            {Array.from({ length: momentsByUserId.length }, (_, index) => {
              return (
                <Progressbar
                  key={index}
                  sizeBar={index < momentIndexCurrent ? 100 : 0}
                  index={index}
                  currentIndex={momentIndexCurrent}
                  pause={isPausedMoment}
                  // time={Infinity}
                  time={
                    momentsByUserId[momentIndexCurrent]?.file.fileType ===
                    "PHOTO"
                      ? 10000
                      : Math.round(
                          momentsByUserId[momentIndexCurrent]?.file.duration *
                            1000
                        )
                  }
                  trigger={index === momentIndexCurrent && !isLoadingMoments}
                  triggerCallback={conveyorActionsHandler}
                />
              );
            })}
          </div>

          <MomentsCards
            clickInMomentCallBack={playPauseMoment}
            momentsMessage={momentsMessage}
            setMomentsMessage={setMomentsMessage}
            sendMessage={handleSendMessage}
            clickProfileHandlerCallback={clickUserFeed}
            className={`content-wrapper__moments-cards ${
              momentAbleToShow ? "" : "total-size"
            }`}
            clickLikeMomentCallback={() =>
              clickLikeDislikeMoment(
                momentsByUserId[momentIndexCurrent]?.momentId
              )
            }
            unlockMomentCallback={() =>
              unlockMomentHandler(momentsByUserId[momentIndexCurrent]?.momentId)
            }
            creatorMomentInfo={{
              creatorId: creatorMomentInfo.userId,
              creatorAvatar: profileAvatar,
              creatorIsVip: creatorMomentInfo.isVip,
              creatorName: creatorMomentInfo.displayname,
              isPaidContent: momentAbleToShow,
              likesAmount: momentsByUserId[momentIndexCurrent]?._count.likedBy,
              viewersAmount: momentsByUserId[momentIndexCurrent]?._count.seenBy,
              media: momentsByUserId[momentIndexCurrent]?.file.url as string,
              postTime: momentsByUserId[momentIndexCurrent]?.createdAt,
              type:
                momentsByUserId[momentIndexCurrent]?.file.fileType === "VIDEO"
                  ? "video"
                  : "photo",
              creatorAge: creatorMomentInfo?.profile?.age,
              isMomentLiked: momentsByUserId[momentIndexCurrent]?.hasLiked,
              unlockPrice: momentsByUserId[momentIndexCurrent]?.price,
              momentIsAbleToShow: momentAbleToShow,
              momentId: momentsByUserId[momentIndexCurrent]?.momentId,
            }}
          />
        </div>
      </section>
      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default MomentsTrack;
