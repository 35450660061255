import fetchApi from "../fetchApi";

const getBlockedLocations = async (token: string) => {
  return await fetchApi({
    service: "user",
    endPoint: `/blocked-locations`,
    method: "GET",
    token,
  });
};

export default getBlockedLocations;
