import React, { useEffect, useState } from "react";
import "./styles.scss";
import { IMoments, IMomentsUnseen } from "../../../types/moments";
import ImageCircleBorder from "../../ImageCircleBorder";
import { useUserInfo } from "hooks/userInfo";
import getUserInfo from "api/userInfo/getUserInfo";

interface MomentsCirclesProps {
  clickMoment: (moment: string, index: number) => void;
  addMomentClick?: (a: any) => void;
  className?: string;
  showAddMoment: boolean;
  creatorSelfMoments?: [] | IMoments[];
  momentsDetails: {
    momentsUnseen: IMomentsUnseen[];
    momentsSeen: IMomentsUnseen[];
  };
}

const MomentsCircles: React.FC<MomentsCirclesProps> = (props) => {
  const {
    clickMoment,
    className,
    momentsDetails,
    addMomentClick,
    showAddMoment,
    creatorSelfMoments,
  } = props;
  const { userInfo, setUserInfo } = useUserInfo();
  const [userAvatar, setUserAvatar] = useState("");

  useEffect(() => {
    getUserInfoRequest(userInfo.access_token);
  }, [userInfo.access_token]);

  const getUserInfoRequest = async (token: string) => {
    try {
      const response = await getUserInfo(token);
      switch (response.status) {
        case 200:
          if (response?.res?.message?.statusCode === 401) {
          } else {
            setUserAvatar(response.res?.photos[0]?.thumbnailUrl);
            setUserInfo(
              "user_profile_avatar",
              response.res?.photos[0]?.thumbnailUrl
            );
          }
          break;
        case 401:
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div id="moments-circles-component" className={`${className ?? ""}`}>
      {userInfo.account_type === "CREATOR" ? (
        <div className="moments-circles-component__self-moment margin-right-8">
          {creatorSelfMoments?.length ? (
            <ImageCircleBorder
              hasPinkBorder={!creatorSelfMoments[0].hasSeen}
              size={48}
              onClick={() => clickMoment(creatorSelfMoments[0].autorId, 0)}
              centerImageAlt="moment"
              centerImage={userAvatar}
              noSpaceBetweenBorder={!creatorSelfMoments?.length}
            />
          ) : (
            <ImageCircleBorder
              size={48}
              noBorder
              centerImageAlt="moment"
              centerImage={userAvatar}
              noSpaceBetweenBorder
            />
          )}

          {addMomentClick && showAddMoment ? (
            <div
              className="moments-circles-component__add-moments-container"
              onClick={addMomentClick}
            >
              <span className="icon icon-plus icon-white icon-sm"></span>
            </div>
          ) : (
            false
          )}
        </div>
      ) : (
        false
      )}

      {momentsDetails?.momentsUnseen.map((moment, index) => (
        <ImageCircleBorder
          hasPinkBorder
          key={index}
          onClick={() => clickMoment(moment.userId, index)}
          centerImageAlt="moment"
          centerImage={moment.profile.photos[0]?.thumbnailUrl}
        />
      ))}
      {momentsDetails?.momentsSeen.map((moment, index) => (
        <ImageCircleBorder
          key={index}
          onClick={() => clickMoment(moment.userId, index)}
          centerImageAlt="moment"
          centerImage={moment.profile.photos[0]?.thumbnailUrl}
        />
      ))}
    </div>
  );
};

export default MomentsCircles;
