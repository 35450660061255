import React, { useEffect, useRef } from "react";
import "./styles.scss";
import L from "leaflet";
import LocationIcon from "../../assets/icons/location-pink.svg";
interface MapRouteProps {
  className?: string;
  originCoordinates: ICoordinates;
}

export type ICoordinates = {
  city?: string;
  iataCode?: string;
  lat: number;
  long: number;
};

const MapRoute: React.FC<MapRouteProps> = ({
  className,
  originCoordinates,
}) => {
  const mapRef = useRef<L.Map | null>(null);
  const markerRef = useRef<L.Marker | null>(null);

  useEffect(() => {
    if (!mapRef.current) {
      mapRef.current = L.map("map").setView(
        [originCoordinates.lat, originCoordinates.long],
        12.5,
        {
          animate: false,
        }
      );

      L.tileLayer(
        "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
      ).addTo(mapRef.current);

      const originIcon = L.icon({
        iconUrl: LocationIcon,
        iconSize: [12, 12],
        iconAnchor: [6, 6],
        popupAnchor: [0, -6],
      });

      markerRef.current = L.marker(
        [originCoordinates.lat, originCoordinates.long],
        { icon: originIcon }
      ).addTo(mapRef.current);
    }
  }, []);

  useEffect(() => {
    if (mapRef.current && markerRef.current) {
      markerRef.current.setLatLng([
        originCoordinates.lat,
        originCoordinates.long,
      ]);

      mapRef.current.flyTo(
        [originCoordinates.lat, originCoordinates.long],
        12.5,
        {
          animate: true,
          duration: 3,
          easeLinearity: 2,
        }
      );
    }
  }, [originCoordinates]);

  return (
    <section id="map-route-component" className={className ?? ""}>
      <div id="map"></div>
    </section>
  );
};

export default MapRoute;
