import "./styles.scss";

import ChatMessagePhoto from "../ChatMessagePhoto";
import ChatMessageVideo from "../ChatMessageVideo";

import { File, MessageSettings } from "../../../../types/chatInfo";

interface IGrid {
  side: string;
  messageSettings: MessageSettings;
  files: File[];
  message?: string;
  className?: string;
  clickOnImage?: (a: any) => void;
  clickOnVideo?: (a: any) => void;
}

const ChatMessageGrid: React.FC<IGrid> = (props) => {
  const {
    files,
    messageSettings,
    side,
    message,
    className,
    clickOnImage,
    clickOnVideo,
  } = props;

  return (
    <div className={`chat-message-grid ${className ? className : ""}`}>
      <div className="chat-message-grid__grid">
        {files?.slice(0, 4).map((file, index) => (
          <div
            className={`
            chat-message-grid__file 
            chat-message-grid__file--${side}
          `}
            key={index}
          >
            {files.length > 4 && index + 1 === 4 ? (
              <div className={`chat-message-grid__files-remains ${side}`}>
                <p className="chat-message-grid__files-remains-text">
                  + {files.length - 3}
                </p>
              </div>
            ) : (
              <>
                {file.fileType === "PHOTO" ? (
                  <ChatMessagePhoto
                    side={side}
                    messageSettings={messageSettings}
                    file={file}
                    index={index}
                    clickOnImage={clickOnImage}
                  />
                ) : (
                  file.fileType === "VIDEO" && (
                    <ChatMessageVideo
                      side={side}
                      messageSettings={messageSettings}
                      file={file}
                      index={index}
                      clickOnVideo={clickOnVideo}
                    />
                  )
                )}
              </>
            )}
          </div>
        ))}
      </div>

      {message && (
        <p className="chat-message__text chat-message__text--file no-margin">
          {message}
        </p>
      )}
    </div>
  );
};

export default ChatMessageGrid;
