import fetchApi from "../fetchApi";

const DeleteBlockedLocations = async (
  token: string,
  blockedLocationId: string
) => {
  return await fetchApi({
    service: "user",
    endPoint: `/blocked-locations/${blockedLocationId}`,
    method: "DELETE",
    token,
  });
};

export default DeleteBlockedLocations;
