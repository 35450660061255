import { useState } from "react";
import Header from "../../components/Headers/Header";
import "./styles.scss";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import ShieldPinkIcon from "../../assets/icons/shieldIcons/shield-icon-pink.svg";
import { useUserInfo } from "../../hooks/userInfo";
import { IToast } from "../../types/toast";
import Toast from "../../components/Toast";
import PageTitle from "../../components/PageTitles";

const CreateLegalNamePage: React.FC = () => {
  const [userLegalName, setUserLegalName] = useState("");
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [inputLegalNameWarning, setInputLegalNameWarning] = useState(false);
  const { userInfo, setUserInfo } = useUserInfo();
  const [isLoading, setIsLoading] = useState(false);

  const { setPrincipalScreen } = useDeskNavigation();

  const addLegalNameSubmit = async () => {
    if (isLoading) return;
    setIsLoading(true);

    const stringCleaner = userLegalName.replace(/[^a-zA-Z]/g, "");

    if (stringCleaner.length < 3) {
      setToastConfig({
        type: "warning",
        title: "Invalid Name",
        description: "The name must have at least three letters",
      });

      setToastShow(true);
      setInputLegalNameWarning(true);

      setTimeout(() => {
        setToastShow(false);
        setInputLegalNameWarning(false);
      }, 4000);
    } else {
      setUserInfo("legal_name", userLegalName.trim());
      setPrincipalScreen("/upload-document");
    }

    // const request = await PatchUserSelfDetailApi(userInfo.access_token, {
    //   username: userLegalName,
    // });

    // switch (request.status) {
    // 	case 200:
    // 		setPrincipalScreen("/upload-document")
    // 		break
    //   default:
    //     setToastConfig({
    //       type: "error",
    //       title: "Error",
    //       description:
    //         "Something went wrong on user creation. Please try again.",
    //     });

    //     setToastShow(true);
    //     setInputLegalNameWarning(true);

    //     setTimeout(() => {
    //       setToastShow(false);
    //       setInputLegalNameWarning(false);
    //     }, 4000);
    //     break;
    // }
    // setPrincipalScreen("/create-password");
    setIsLoading(false);
  };

  return (
    <div className="create-legal-name margin-hor-16">
      <PageTitle
        title={"Settings"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        // className="margin-hor-16"
        onBackClick={() => setPrincipalScreen("/user-settings")}
      />
      <main className="create-legal-name__main">
        <Header
          title="Legal name"
          subTitle={`We safeguard our community by ensuring the authenticity 
						of every member on our platform, upholding a standard of trust
						and transparency.`}
          marginTop={0}
        />
        <InputText
          value={userLegalName}
          placeholder="Legal Name (as shown on document)"
          onChangeInput={setUserLegalName}
          isWarning={inputLegalNameWarning}
          inputType="text"
          className="margin-vert-16"
        />
        <SubText
          icon={ShieldPinkIcon}
          altIcon="shield Roses icon"
          className="margin-bottom-16"
        >
          We’ll never share your legal name
        </SubText>
      </main>

      <Button
        className="margin-bottom-40"
        buttonStyle="primary"
        onClick={addLegalNameSubmit}
        disabled={isLoading}
      >
        Continue
      </Button>

      <Toast
        type={toastConfig.type}
        description={toastConfig.description}
        setIsVisible={setToastShow}
        isVisible={toastShow}
        title={toastConfig.title}
      />
    </div>
  );
};

export default CreateLegalNamePage;
