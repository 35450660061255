import fetchApi from "../fetchApi";

const deleteCreatorSettingsGoalDeactivate = async (token: string, goalId: string) => {
  return await fetchApi({
    service: "creatorSettings",
    endPoint: `/goal/deactivate/${goalId}`,
    method: "DELETE",
    token,
  });
};

export default deleteCreatorSettingsGoalDeactivate;
