import fetchApi from "../fetchApi";

const DeleteProfileLocation = async (token: string, LocationId: string) => {
  return await fetchApi({
    service: "user",
    endPoint: `/location-history/${LocationId}`,
    method: "DELETE",
    token,
  });
};

export default DeleteProfileLocation;
