import { PhotosAlbumType } from "../../types/files";
import { servicesApi } from "../fetchApi/services-api";

const patchProfilePhoto = async (
  token: string,
  fileId: string,
  position: number
) => {
  const apiRoute = `${servicesApi(
    "user"
  )}/profile-photo/${fileId}/to-position/${position}`;

  try {
    const response = await fetch(apiRoute, {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const res = await response.json();
    return { res, status: response.status };
  } catch (error) {
    console.error("There was a problem with the fetch operation:", error);
  }
};

export default patchProfilePhoto;
