import "./styles.scss";
import Loading from "../../../Loading";
import { File, MessageSettings } from "../../../../types/chatInfo";
import { useRef, useState } from "react";

interface IChatMessagePhoto {
  side: string;
  messageSettings: MessageSettings;
  file: File;
  index?: number;
  hasText?: boolean;
  className?: string;
  clickOnImage?: (a: any) => void;
}

const ChatMessagePhoto: React.FC<IChatMessagePhoto> = (props) => {
  const {
    side,
    messageSettings,
    file,
    index,
    hasText,
    className,
    clickOnImage,
  } = props;
  const { messageSettingsId, paidContent, isPaid } = messageSettings;

  const isLoadedRef = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
    isLoadedRef.current = true;
  };

  const handleClick = () => {
    if (!clickOnImage) return;

    if (paidContent && !isPaid && side !== "right") return;

    clickOnImage(file.url);
  };

  return (
    <div className={`chat-message-photo ${className ? className : ""}`}>
      {!isLoadedRef && !isLoaded && <Loading className="chat-loading" />}
      <img
        key={`${messageSettingsId}`}
        src={file.url}
        alt={file.url}
        className={`
          chat-message-photo__file
          chat-message-photo__file--${side}
          ${hasText ? "chat-message-photo__file-text" : ""}
          ${index || index === 0 ? `file-${side}-${index + 1}` : ""}
          ${isLoaded ? "chat-file-show" : ""}
        `}
        onLoad={handleLoad}
        onClick={handleClick}
      />
    </div>
  );
};

export default ChatMessagePhoto;
