import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./styles.scss";
import InputText from "../../Inputs/InputText";
import ChevronDown from "../../../assets/icons/bx-chevron-down.svg";
import ChevronUp from "../../../assets/icons/bx-chevron-up.svg";
import Button from "../../Buttons/Button";
import InputRadio from "../../Inputs/InputRadio";
import InputCheckbox from "../../Inputs/InputCheckbox";
import InputRadioSub from "../../Inputs/InputRadioSub";
import ListItemLink from "../ListItemLink";
import InputListSelect from "../../Inputs/InputListSelect";

interface DropDownProps {
  className?: string;
  options: DropDownRadioElement[];
  setValueCallBack: (option: string[]) => void;
  placeholder?: string;
  iconLeft?: string;
  label?: string;
  textButton?: string;
  dropType: "radio" | "checkbox" | "click-select";
  buttonCallBack?: () => void;
  inputValueSet?: string | string[];
  defaultValueProp?: string | string[] | undefined;
  elementNode?: ReactNode;
  iconRight?: boolean;
  actionIconRight?: (index?: number) => void;
}

export interface DropDownRadioElement {
  inputLabel: string;
  inputValue: string;
  groupName: string;
  icon?: string;
  node?: ReactNode;
  subText?: string;
}

const DropDown: React.FC<DropDownProps> = (props) => {
  const {
    className,
    options,
    setValueCallBack,
    placeholder,
    iconLeft,
    label,
    textButton,
    dropType,
    buttonCallBack,
    inputValueSet,
    defaultValueProp,
    elementNode,
    iconRight,
    actionIconRight,
  } = props;

  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [inputValue, setInputValue] = useState<string[]>([]);
  const dropDownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropDownRef.current &&
      !dropDownRef.current.contains(event.target as Node)
    ) {
      setIsOpenDropDown(false);
    }
  };

  const toggleOpenDropDown = () => {
    setIsOpenDropDown((prev) => !prev);
  };

  const selectOption = (option: string[]) => {
    setInputValue(option);
    setValueCallBack(option);

    if (dropType === "click-select") {
      setIsOpenDropDown(false);
    }
  };

  const getDisplayValue = () => {
    console.log();

    if (dropType === "checkbox" && Array.isArray(inputValue)) {
      return inputValue.join(", ");
    }

    const selectedValue =
      typeof inputValue === "string" ? inputValue : inputValue[0];

    const label = options.find(
      (opt) => opt.inputValue === selectedValue
    )?.inputLabel;

    return label;
  };

  const [isTransitionComplete, setIsTransitionComplete] = useState(false);

  useEffect(() => {
    const element = inputRadioRef.current;
    let transitionsCompleted = 0;

    if (inputValueSet) {
      setInputValue(inputValueSet as string[]);
    }

    if (!element) return;

    const handleTransitionEnd = () => {
      transitionsCompleted += 1;

      const transitionProperties =
        getComputedStyle(element).transitionProperty.split(", ").length;
      if (transitionsCompleted >= transitionProperties) {
        setIsTransitionComplete(true);
        transitionsCompleted = 0;
      } else {
        setIsTransitionComplete(false);
      }
    };

    element.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      element.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, []);

  const inputRadioRef = useRef<HTMLDivElement>(null);
  const inputCheckboxRef = useRef<HTMLDivElement>(null);

  return (
    <div
      id="drop-down-component"
      className={className ?? ""}
      data-active={isOpenDropDown}
      ref={dropDownRef}
    >
      <InputText
        label={label}
        value={inputValueSet}
        onClick={toggleOpenDropDown}
        readOnly
        inputType="text"
        className={`drop-down-component__input`}
        placeholder={placeholder}
        iconRight={isOpenDropDown ? ChevronUp : ChevronDown}
        iconLeft={iconLeft}
        textTransform={"capitalize"}
        elementNode={elementNode}
      />
      <div
        ref={inputRadioRef}
        className={`drop-down-component__input-container ${
          isOpenDropDown
            ? "drop-down-component__input-container-open margin-top-24"
            : "drop-down-component__input-container-close"
        }`}
      >
        {dropType === "radio" ? (
          <InputRadio
            elements={options}
            onChangeValueCallback={selectOption}
            defaultValueProp={defaultValueProp}
            className={`${
              isTransitionComplete ? "active" : ""
            } input-container__radio`}
            iconRight={iconRight}
            actionIconRight={actionIconRight}
          />
        ) : dropType === "checkbox" ? (
          <InputCheckbox
            inputValues={inputValue}
            setInputsValues={(e) => {
              setInputValue(e);
            }}
            elements={options}
            defaultValueProp={defaultValueProp as string[]}
            className="input-container__checkbox"
          />
        ) : dropType === "click-select" ? (
          <InputListSelect
            elements={options}
            onChangeValueCallback={selectOption}
            defaultValueProp={defaultValueProp as string[]}
            className={`${isTransitionComplete ? "active" : ""}`}
          />
        ) : null}

        {textButton && buttonCallBack ? (
          <div className="drop-down__container-button">
            <Button buttonStyle="tertiary" onClick={buttonCallBack}>
              {textButton}
            </Button>
          </div>
        ) : (
          false
        )}
      </div>
    </div>
  );
};

export default DropDown;
