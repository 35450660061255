import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import Button from "../../components/Buttons/Button";
import { useEffect, useState } from "react";
import chevronRightIcon from "../../assets/icons/air-plane-gray.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import InputRadio from "../../components/Inputs/InputRadio";
import getUserLocationHistory from "../../api/userInfo/getUserLocationHistory";
import { useUserInfo } from "../../hooks/userInfo";
import patchUserLocation from "../../api/userInfo/patchUserLocation";
import MapRoute from "../../components/MapRoute";
import { SearchBox } from "@mapbox/search-js-react";

import deleteProfileLocation from "../../api/userInfo/deleteProfileLocation";
import getBlockedLocations from "api/user/getBlockedLocations";
import postBlockedLocations from "api/user/postBlockedLocations";
import deleteBlockedLocations from "api/user/deleteBlockedLocations";
const SearchBoxComponent = SearchBox as React.ComponentType<any>;
interface ListDetails {
  place?: string;
  region?: string;
  country?: string;
  country_code?: string;
  country_code_alpha_3?: string;
  locationId?: string;

  latitude?: number;
  longitude?: number;
  locationHistoryId?: string;
}

const UserSettingsBlockedLocationPage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const [residence, setResidence] = useState<ListDetails>();
  const [locationList, setLocationList] = useState<ListDetails[]>([]);
  const [location, setLocation] = useState<string>();
  const { userInfo } = useUserInfo();
  const mapboxToken = process.env.REACT_APP_MAPBOX_API_KEY;

  const removeLocation = async (e: any) => {
    locationList?.map(async (item, index) => {
      if (index === e) {
        if (item.locationHistoryId) {
          const response = await deleteBlockedLocations(
            userInfo.access_token,
            item.locationHistoryId
          );
          requestLocationHistory();
        }
      }
    });
  };

  const updateLocation = async () => {
    if (residence?.place && residence.country) {
      const response = await postBlockedLocations(
        userInfo.access_token,
        residence
      );
      requestLocationHistory();
    }
  };

  const requestLocationHistory = async () => {
    const response = await getBlockedLocations(userInfo.access_token);
    if (response.res.length > 0) {
      const index = response.res.length - 1;

      const currentLocation = `${response.res[index].location.place}, ${response.res[index].location.country_code}`;
      setLocation(currentLocation);
      setResidence(response.res[index].location);

      const history = response.res.map((item: any) => ({
        ...item.location,
        locationHistoryId: item.blockedLocationId,
      }));

      setLocationList(
        history
          .filter(
            (item: any, index: any, array: any) =>
              index ===
              array.findIndex((i: any) => i.locationId === item.locationId)
          )
          .map((item: ListDetails) => ({
            ...item,
          }))
      );
    }
  };
  useEffect(() => {
    requestLocationHistory();
  }, [userInfo]);
  return (
    <div className="user-settings-location">
      <div className="header">
        <PageTitle
          title={"Block Location"}
          subtitle={"Account Privacy"}
          className="margin-hor-16"
          onBackClick={() =>
            setPrincipalScreen("/user-settings-privacy", { btn: "button3" })
          }
        />
      </div>
      <div className="body">
        {residence?.latitude && residence?.longitude && (
          <MapRoute
            originCoordinates={{
              long: residence?.longitude,
              lat: residence?.latitude,
            }}
          />
        )}
        <div className="div__img"></div>
        {mapboxToken && (
          <div className="class-search margin-top-16 margin-hor-16">
            <SearchBoxComponent
              accessToken={mapboxToken}
              options={{
                language: "pt",
                types: "place",
              }}
              placeholder="Select Location"
              theme={{
                variables: {
                  colorBackground: "var(--dawn)",
                  borderRadius: "30px",
                  colorText: "white",
                  colorBackgroundActive: "var(--charcoal)",
                  colorBackgroundHover: "var(--charcoal)",
                },
              }}
              value={location}
              onChange={async (e: any) => {
                if (e === "") {
                }
                //setLocation(e);
                // const filteredSuggestions = await fetchFilteredSuggestions(e);
                //console.log("Filtered Suggestions:", filteredSuggestions);
              }}
              onRetrieve={(sug: any) => {
                let dataMock: ListDetails = {
                  place: sug.features[0].properties.context.place?.name,
                  region: sug.features[0].properties.context.region?.name,
                  country: sug.features[0].properties.context.country?.name,
                  country_code:
                    sug.features[0].properties.context.country?.country_code,
                  country_code_alpha_3:
                    sug.features[0].properties.context.country
                      ?.country_code_alpha_3,

                  latitude: sug.features[0].properties.coordinates.latitude,
                  longitude: sug.features[0].properties.coordinates.longitude,
                };
                if (
                  dataMock.place &&
                  dataMock.country_code &&
                  dataMock.region &&
                  dataMock.latitude &&
                  dataMock.longitude
                ) {
                  setLocation(`${dataMock.place}, ${dataMock.country_code}`);
                  setResidence(dataMock);
                } else {
                  setLocation(``);
                  //setResidence(``);
                }
              }}
            />
          </div>
        )}
        <div className="div__content_scroll margin-hor-24">
          <InputRadio
            elements={
              locationList
                ? locationList.map((item: any) => {
                    return {
                      groupName: "country",
                      inputLabel: `${item.place}, ${item.country_code}`,
                      inputValue: `${item.place}, ${item.country_code}`,
                    };
                  })
                : []
            }
            onChangeValueCallback={(e) => {
              locationList?.map((item: ListDetails | any, index: number) => {
                const beforeComma = e[0].split(",")[0].trim();
                if (item.place === beforeComma) {
                  setResidence(item);
                }
              });

              setLocation(e[0]);
            }}
            defaultValueProp={location}
            iconRight={true}
            actionIconRight={removeLocation}
          />
        </div>
      </div>
      <div className="footer">
        <div className="container-button">
          <Button
            children={"BLOCK LOCATION"}
            buttonStyle={"primary"}
            onClick={updateLocation}
          />
        </div>
      </div>
    </div>
  );
};

export default UserSettingsBlockedLocationPage;
