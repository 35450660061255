import fetchApi from "../fetchApi";

const getCreatorSettingsGoalDonors = async (token: string, goalId: string) => {
  return await fetchApi({
    service: "creatorSettings",
    endPoint: `/goal/donors/${goalId}`,
    method: "GET",
    token,
  });
};

export default getCreatorSettingsGoalDonors;
