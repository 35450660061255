import { useState } from "react";
import Header from "../../components/Headers/Header";
import "./styles.scss";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import Toast from "../../components/Toast";
import { IToast } from "../../types/toast";
import PageTitle from "../../components/PageTitles";

const RecoveryEmailPage: React.FC = () => {
  const [userRecoveryEmail, setUserRecoveryEmail] = useState("");
  const [isSignNewsLetter, setIsSignNewsLetter] = useState(true);
  const [emailInputWarning, setInputWaning] = useState(false);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const { setPrincipalScreen } = useDeskNavigation();
  const { setUserInfo } = useUserInfo();

  const validateEmail = (email: string): boolean => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const recoveryEmailSubmit = () => {
    if (validateEmail(userRecoveryEmail)) {
      setUserInfo("user_email", userRecoveryEmail);
      setUserInfo("news_letter", JSON.stringify(isSignNewsLetter));
      setPrincipalScreen("/create-email-verify");
    } else {
      setToastConfig({
        type: "error",
        title: "invalid email",
        description: "Add a valid email",
      });

      setToastShow(true);
      setInputWaning(true);

      setTimeout(() => {
        setToastShow(false);
        setInputWaning(false);
      }, 3000);
    }
  };

  return (
    <div className="recovery-email margin-hor-16">
      <PageTitle
        title={"Settings"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/upload-document")}
      />

      <main className="recovery-email__main">
        <Header
          title={
            <>
              Set a <br />
              recovery email
            </>
          }
          subTitle="Your e-mail is used to recover your account."
          marginTop={0}
        />
        <InputText
          value={userRecoveryEmail.toLowerCase()}
          inputType="email"
          placeholder="Email"
          textTransform="none"
          onChangeInput={(e) => setUserRecoveryEmail(e)}
          isWarning={emailInputWarning}
        />
        <SubText
          checkAnswer
          checkValueCallback={setIsSignNewsLetter}
          isChecked={isSignNewsLetter}
          className="margin-vert-16"
        >
          I want to receive news, updates, and offers from Roses
        </SubText>
      </main>
      <Button
        className="margin-bottom-40"
        buttonStyle={"primary"}
        onClick={recoveryEmailSubmit}
      >
        Continue
      </Button>

      <Toast
        type={toastConfig.type}
        title={toastConfig.title}
        description={toastConfig.description}
        isVisible={toastShow}
        setIsVisible={setToastShow}
      />
    </div>
  );
};

export default RecoveryEmailPage;
