import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import ButtonIcon from "../Buttons/ButtonIcon";

interface SendAudioChatProps {
  startRecordingCallback: React.Dispatch<React.SetStateAction<boolean>>;
  sendAudioCallback: (audioBlob: Blob | null) => void;
  className?: string;
}

const SendAudioChat: React.FC<SendAudioChatProps> = (props) => {
  const { startRecordingCallback, sendAudioCallback, className } = props;
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const [timeRecording, setTimeRecording] = useState("0:00");

  useEffect(() => {
    sendAudioCallback(null);
  }, [sendAudioCallback]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;
    let elapsedTime = 0;

    if (isRecording) {
      intervalId = setInterval(() => {
        elapsedTime += 0.1;
        setTimeRecording(formatTime(elapsedTime));
      }, 100);
    } else {
      setTimeRecording("0:00");
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isRecording]);

  const startRecordingAudio = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;

      let mimeType = "audio/webm";
      if (!MediaRecorder.isTypeSupported(mimeType)) {
        mimeType = "audio/ogg";
        if (!MediaRecorder.isTypeSupported(mimeType)) {
          console.warn(
            "Nenhum formato suportado encontrado. Usando o formato padrão."
          );
          mimeType = "";
        }
      }

      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });

      mediaRecorderRef.current.ondataavailable = (event: BlobEvent) => {
        if (isCancelled) return;
        const newBlob = event.data;
        sendAudioCallback(newBlob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setIsCancelled(false);
      startRecordingCallback(true);
    } catch (error) {
      console.error(error);
    }
  };

  const cancelRecordingAudio = () => {
    try {
      setIsCancelled(true);
      setIsRecording(false);
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.ondataavailable = null;
        if (mediaRecorderRef.current.state !== "inactive") {
          mediaRecorderRef.current.stop();
        }
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      sendAudioCallback(null);
      startRecordingCallback(false);
    } catch (error) {
      console.error(error);
    } finally {
      mediaRecorderRef.current = null;
      streamRef.current = null;
    }
  };

  const sendAudioHandler = () => {
    try {
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.stop();
      }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
      setIsRecording(false);
      startRecordingCallback(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={`send-audio-chat-component ${className}`}>
      <div className="audio-recorder">
        <div className="grid-colum-1-2">
          <button className="audio-recorder__btn" onClick={startRecordingAudio}>
            <span className="icon icon-mic audio-recorder__icon-mic"></span>
          </button>

          <p className="audio-record__time">{timeRecording}</p>
        </div>

        <div className="grid-colum-3-4">
          <ButtonIcon
            icon="icon-close"
            iconClass="send-audio-chat__icon-close"
            buttonStyle="tertiary"
            onClick={cancelRecordingAudio}
          />
          <ButtonIcon
            icon="icon-send-1"
            iconClass="send-audio-chat__icon-send"
            buttonStyle="primary"
            onClick={sendAudioHandler}
          />
        </div>
      </div>
    </div>
  );
};

export default SendAudioChat;
