import React, { useEffect } from "react";
import "./styles.scss";

interface InputCheckboxProps {
  elements: InputCheckboxElement[];
  className?: string;
  defaultValueProp?: string[];
  setInputsValues: React.Dispatch<React.SetStateAction<string[]>>;
  inputValues: string[];
}
export interface InputCheckboxElement {
  inputLabel: string;
  inputValue: string;
  groupName: string;
}

const InputCheckbox: React.FC<InputCheckboxProps> = (props) => {
  const {
    elements,
    className,
    defaultValueProp,
    setInputsValues,
    inputValues,
  } = props;

  useEffect(() => {
    setInputsValues(defaultValueProp || []);
  }, [defaultValueProp]);

  const checkValueCallback = (
    event: React.ChangeEvent<HTMLInputElement>,
    element: string
  ) => {
    setInputsValues((prevValues) => {
      let updatedValues = [...prevValues];

      if (event.target.checked) {
        if (!updatedValues.includes(element)) {
          updatedValues.push(element);
        }
      } else {
        updatedValues = updatedValues.filter((value) => value !== element);
      }
      return updatedValues;
    });
  };

  return (
    <div id="input-checkbox-default" className={className ?? ""}>
      {elements.map((element, index) => (
        <div className="label-input-container padding-hor-16" key={index}>
          <label
            htmlFor={`${element.inputValue}-${index}`}
            className="container-checkbox padding-vert-16 no-margin"
          >
            {element.inputLabel}
            <input
              id={`${element.inputValue}-${index}`}
              name={element.groupName}
              value={element.inputValue}
              type="checkbox"
              onChange={(event) => {
                checkValueCallback(event, element.inputValue);
              }}
              checked={inputValues?.includes(element.inputValue)}
            />
            <span className="container-checkbox__checkmark"></span>
          </label>

          {index + 1 < elements.length && <div className="separator" />}
        </div>
      ))}
    </div>
  );
};

export default InputCheckbox;
