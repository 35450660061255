import React, { createContext, ReactNode, useState } from "react";

interface FavoritesListTriggerProps {
  favoritesListTrigger: boolean;
  setFavoritesListTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FavoritesTriggerProviderProps {
  children: ReactNode;
}

export const FavoritesListContext = createContext(
  {} as FavoritesListTriggerProps
);

export const FavoritesListTriggerProvider: React.FC<
  FavoritesTriggerProviderProps
> = ({ children }) => {
  const [favoritesListTrigger, setFavoritesListTrigger] = useState(false);

  return (
    <FavoritesListContext.Provider
      value={{ favoritesListTrigger, setFavoritesListTrigger }}
    >
      {children}
    </FavoritesListContext.Provider>
  );
};
