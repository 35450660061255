import React, { createContext, ReactNode, useState } from "react";

interface MomentTriggerProps {
  momentTrigger: boolean;
  setMomentTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

interface MomentsTriggerProviderProps {
  children: ReactNode;
}

export const MomentsTriggerContext = createContext({} as MomentTriggerProps);

export const MomentsTriggerProvider: React.FC<MomentsTriggerProviderProps> = ({
  children,
}) => {
  const [momentTrigger, setMomentTrigger] = useState(false);

  return (
    <MomentsTriggerContext.Provider value={{ momentTrigger, setMomentTrigger }}>
      {children}
    </MomentsTriggerContext.Provider>
  );
};
