import fetchApi from "../fetchApi";

const getCreatorSettingsGoalUser = async (token: string, userId: string) => {
  return await fetchApi({
    service: "creatorSettings",
    endPoint: `/goal/user/${userId}`,
    method: "GET",
    token,
  });
};

export default getCreatorSettingsGoalUser;
