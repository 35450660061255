import Image1 from "../assets/images/feed-mock-images/feed-1.jpg";
import Image2 from "../assets/images/feed-mock-images/feed-2.png";
import Image3 from "../assets/images/feed-mock-images/feed-3.jpg";
import Image4 from "../assets/images/feed-mock-images/feed-4.jpg";
import Image5 from "../assets/images/feed-mock-images/feed-5.jpg";

export const feedMock = [
  {
    image: Image1,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
    isLockedContent: false,
    price: 0,
  },
  {
    image: Image2,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
    isLockedContent: false,
    price: 0,
  },
  {
    image: Image3,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
    isLockedContent: true,
    price: 12,
  },
  {
    image: Image4,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
    isLockedContent: true,
    price: 32,
  },
  {
    image: Image5,
    postTime: "2h",
    name: "Scarlet",
    age: "24",
    rating: 4,
    isVerify: false,
    qnt_videos: 2,
    qnt_photos: 7,
    price_pack: "7.99",
    views_post: "22.876",
    isLockedContent: false,
    price: 0,
  },
];
