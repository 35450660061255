import fetchApi from "../fetchApi";

const ChangePassword = async (
  token: string,
  data: {
    oldPassword: string;
    newPassword: string;
  }
) => {
  return await fetchApi({
    service: "user",
    endPoint: "/change-password",
    method: "POST",
    data,
    token,
  });
};

export default ChangePassword;
