import AudioWaveform from "../../../AudioWave";
import "./styles.scss";

interface IChatMessageAudio {
  src: string;
  duration?: number;
  className?: string;
}

const ChatMessageAudio: React.FC<IChatMessageAudio> = (props) => {
  const { src, duration, className } = props;
  return (
    <div className={`chat-message-audio ${className ? className : ""}`}>
      <AudioWaveform audioSrc={src} duration={duration} />
    </div>
  );
};

export default ChatMessageAudio;
