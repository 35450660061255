import React, { ReactNode, useRef, useState } from "react";
import { useModal } from "../../../../hooks/useModal";

import "./styles.scss";
import "../chat-modal-global-style.scss";

import rosesLogoPink from "../../../../assets/icons/logos/logo-fill-pink.svg";
import rosesLogoGold from "../../../../assets/icons/logos/logo-fill-gold.svg";

import Button from "../../../Buttons/Button";
import ButtonIcon from "../../../Buttons/ButtonIcon";
import InputMessage from "../../../TextAreas/Textarea";
import DropDown from "../../../Lists/DropDown";
import RosesCredit from "../../../RosesCredit";
import SubText from "../../../Texts/SubText";

import cardsMock from "../../../../mocks/cards";
import RangeSlider from "../../../Ranges/RangeSlider";

interface SendRosesModalProps {
  showLogo?: boolean;
  showRosesOptions?: boolean;
  showInfoGold?: boolean;
  showSendGold?: boolean;
  showSendMessage?: boolean;
  showCards?: boolean;
  showSubText?: boolean;
  tableData?: TableRow[];
  title: string;
  description: string | ReactNode;
  typeModal?: "send" | "chat" | "buy";
  buttonText?: string;
  maxValue?: number;
  className?: string;
  onClose?: () => void;
  onClick?: () => void;
  rosesMessageCallback?: (roses: number, message: string) => void;
}

interface TableRow {
  type: "gold" | "data" | "total";
  label: string;
  values: (string | number)[];
}

const SendRosesModal: React.FC<SendRosesModalProps> = (props) => {
  const {
    showLogo = false,
    showRosesOptions = false,
    showInfoGold = false,
    showSendGold = false,
    showSendMessage = false,
    showCards = false,
    tableData = null,
    title = "Title text",
    description = "Description text",
    typeModal = "send",
    buttonText = "Send Roses",
    maxValue = 10000,
    className,
    onClose,
    onClick,
    rosesMessageCallback,
  } = props;
  const { setOpenUseModal } = useModal();
  const [rosesToSend, setRosesToSend] = useState<string | number>(0);
  const [messageToSend, setMessageToSend] = useState("");
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const startIncrement = () => {
    if (intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        setRosesToSend((prevCount) => {
          const nextValue = Number(prevCount) + 1;
          return nextValue > maxValue ? maxValue : nextValue;
        });
      }, 100);
    }
  };

  const startDecrement = () => {
    if (intervalRef.current === null) {
      intervalRef.current = setInterval(() => {
        setRosesToSend((prevCount) => {
          const nextValue = Number(prevCount) - 1;
          return nextValue < 0 ? 0 : nextValue;
        });
      }, 100);
    }
  };

  const stopAction = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const buttonValues = [50, 150, 250, 500];

  return (
    <div
      className={`send-roses-modal ${className ?? ""}`}
      data-type={typeModal}
    >
      <div className="send-roses-modal__wrapper" data-type={typeModal}>
        {showLogo && (
          <img
            className="margin-bottom-16"
            src={rosesLogoPink}
            alt="roses logo icon"
          />
        )}
        <h3 className="send-roses-modal__title no-margin">{title}</h3>
        <p className="send-roses-modal__description">{description}</p>

        <section className="range-roses-to-send">
          <ButtonIcon
            icon="icon-minus"
            buttonStyle="tertiary"
            onMouseDown={startDecrement}
            onMouseUp={stopAction}
            onMouseLeave={stopAction}
            onClick={() =>
              setRosesToSend((prev) => Math.max(Number(prev) - 1, 0))
            }
          />
          <div className="range-roses-to-send__value">
            <p className="value-to-send no-margin margin-bottom-8">
              {rosesToSend}
            </p>
            <p className="available-value no-margin">
              {typeModal === "send" && `${maxValue} available`}
              {typeModal === "buy" && (
                <>
                  <span className="range-rose__value--quotation">$0.97</span>{" "}
                  per Roses
                </>
              )}
            </p>
          </div>
          <ButtonIcon
            icon="icon-plus"
            buttonStyle="tertiary"
            onMouseDown={startIncrement}
            onMouseUp={stopAction}
            onMouseLeave={stopAction}
            onClick={() =>
              setRosesToSend((prev) => Math.min(Number(prev) + 1, maxValue))
            }
          />
          <RangeSlider
            min={1}
            max={Number(maxValue)}
            initialMin={1}
            onChange={(e) => {
              setRosesToSend(e.min);
            }}
            className="range-roses-to-send__range margin-vert-24"
          />
        </section>

        <section className="send-roses-modal__main">
          {showSendGold && (
            <>
              <RosesCredit text={"Add Golden Rose"} buttonStyle="gold" />
            </>
          )}

          {showRosesOptions && (
            <div className="send-roses-modal__grid">
              {buttonValues.map((value, index) => {
                return (
                  <RosesCredit
                    key={index}
                    text={String(value)}
                    onClick={() =>
                      setRosesToSend((prev) =>
                        Math.min(Number(prev) + value, maxValue)
                      )
                    }
                  />
                );
              })}
            </div>
          )}

          {tableData && (
            <table className="send-roses-modal__table">
              <tbody>
                {tableData.map((row: TableRow, index: number) => (
                  <tr
                    key={index}
                    className={`send-roses-modal__table--${row.type}`}
                  >
                    <th>{row.label}</th>
                    {row.values.map((value, i) => (
                      <td key={i}>{value}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {showInfoGold && (
            <>
              <SubText
                icon={rosesLogoGold}
                className={"send-roses-modal__info padding-vert-16"}
              >
                <h3 className="send-roses-modal__info-title roboto-regular no-margin">
                  Win{" "}
                  <span className="send-roses-modal__gold-value">
                    {/* {Math.floor(Number(rosesToSend) / 50)} */}6
                  </span>{" "}
                  Golden Roses
                  <span className="icon icon-info send-roses-modal__info-icon"></span>
                </h3>
                <p className="send-roses-modal__info-text no-margin">
                  When you buy 50 more Roses
                </p>
              </SubText>
            </>
          )}

          {showSendMessage && (
            <InputMessage
              value={messageToSend}
              placeholder="Add a message..."
              onChangeInput={(e) => setMessageToSend(e)}
            />
          )}

          {showCards && (
            <DropDown
              placeholder="Select card"
              textButton={"Manage Payment Methods"}
              className="send-roses-modal__dropDown"
              dropType={"radio"}
              options={
                cardsMock
                  ? cardsMock.map((card) => {
                      return {
                        groupName: "cards",
                        inputLabel: `${card.cardType} ${card.number.slice(-4)}`,
                        inputValue: `${
                          card.cardType
                        } Card ****${card.number.slice(-4)}`,
                      };
                    })
                  : []
              }
              setValueCallBack={function (option: string[]): void {}}
            />
          )}
        </section>

        <div className="buttons-container">
          <Button
            buttonStyle="primary"
            onClick={() => {
              onClick?.();
              rosesMessageCallback?.(Number(rosesToSend), messageToSend);
            }}
          >
            {buttonText}
          </Button>
          <Button
            buttonStyle="quaternary"
            onClick={() => {
              onClose?.();
              setOpenUseModal(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SendRosesModal;
