import React, { ChangeEvent, useRef, useState } from "react";
import "./styles.scss";
import ButtonPill from "../../../components/Buttons/ButtonPill";
import Toast from "components/Toast";
import { IToast } from "types/toast";

interface ButtonMediaLocationProps {
  setShowUploadButtons: React.Dispatch<React.SetStateAction<boolean>>;
  showUploadButtons: boolean;
  showBtnCamera: boolean;
  showBtnStorage: boolean;
  setScreenShowing: React.Dispatch<
    React.SetStateAction<"library" | "preview-captured" | "camera" | "">
  >;
  setMediaToShow: React.Dispatch<React.SetStateAction<ICapturedMedia | null>>;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  setCapturedMedia: React.Dispatch<React.SetStateAction<[] | ICapturedMedia[]>>;
  setShowLocationMediaUploadPills: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  buttonMediaAction: () => void;
  buttonCameraAction: () => void;
  bottomPosition?: number;
}

type ICapturedMedia = {
  type: string;
  media: string;
  thumbnail?: string;
};

const ButtonMediaLocation: React.FC<ButtonMediaLocationProps> = (props) => {
  const {
    setMediaToShow,
    setFile,
    setCapturedMedia,
    setShowLocationMediaUploadPills,
    showUploadButtons,
    showBtnCamera,
    showBtnStorage,
    setScreenShowing,
    setShowUploadButtons,
    buttonMediaAction,
    buttonCameraAction,
    bottomPosition = 130,
  } = props;
  const uploadMediaFileRef = useRef<HTMLInputElement | null>(null);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const fileType = e.target.files[0].type.startsWith("video")
      ? "video"
      : e.target.files[0].type.startsWith("image")
      ? "photo"
      : null;

    if (fileType) {
      const file = e.target.files[0];
      const mediaURL = URL.createObjectURL(file);

      setFile(file);
      setMediaToShow({
        media: mediaURL,
        type: fileType,
      });

      setCapturedMedia((prev) => [
        ...prev,
        {
          media: mediaURL,
          type: fileType,
        },
      ]);
      setScreenShowing("preview-captured");

      return () => {
        URL.revokeObjectURL(mediaURL);
      };
    } else {
      setToastConfig({
        description: "Accepted file types are images and videos",
        title: "Unsupported file type",
        type: "error",
      });

      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
      }, 4000);
    }
  };

  const mediaStorageHandler = () => {
    setScreenShowing("library");
    setShowLocationMediaUploadPills(false);
    setShowUploadButtons(false);
    buttonMediaAction();
  };

  const uploadMediaHandler = () => {
    setShowUploadButtons(false);
    uploadMediaFileRef.current?.click();
  };

  const openCameraHandler = () => {
    setShowLocationMediaUploadPills(false);
    setScreenShowing("camera");
    setShowUploadButtons(false);
    buttonCameraAction();
  };

  return (
    <>
      <div
        style={{ bottom: bottomPosition }}
        className={`image-container__media-localization-container ${
          showUploadButtons ? "show" : "hide"
        }`}
      >
        <input
          type="file"
          ref={uploadMediaFileRef}
          name="upload-photo-device"
          className="upload-photo-device"
          onChange={handleFileChange}
          accept="image/*,video/*"
        />
        {showBtnStorage ? (
          <ButtonPill
            clickPill={mediaStorageHandler}
            iconName="icon-cloud"
            isPillSelected={false}
            textPill="Media Storage"
            className="button-pill-animation"
          />
        ) : (
          false
        )}

        {showBtnCamera ? (
          <ButtonPill
            clickPill={openCameraHandler}
            iconName="icon-camera"
            isPillSelected={false}
            textPill="Use Camera"
            className="button-pill-animation"
          />
        ) : (
          false
        )}
        <ButtonPill
          clickPill={uploadMediaHandler}
          iconName="icon-bulk-images"
          isPillSelected={false}
          textPill="Upload Media"
          className="button-pill-animation"
        />
      </div>
      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </>
  );
};

export default ButtonMediaLocation;
