import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./styles.scss";
import FeedCards from "../../components/Cards/FeedCards";

import CoverPhoto from "../../assets/images/0542b1d078ce808d00317ebe68b3d139-full 2.png";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "../../components/Buttons/ButtonNavigation";
import CoverImage from "../../components/CoverImage";
import PageTitle from "../../components/PageTitles";
import ButtonIcon from "../../components/Buttons/ButtonIcon";
import Button from "../../components/Buttons/Button";
import ProfileIcon from "../../assets/icons/profile-icon-gray.svg";
import { Params, useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import CreateChatContact from "../../api/postCreateChatContact";
import { Chat } from "../../types/chatInfo";
import { useWebSocket } from "../../contexts/webSocketContext";
import getPublicDetails from "../../api/publicDetails/getProfileDetails";
import { IUserPublicInfo } from "../../types/userInfo";
import postAddToFavorite from "../../api/favorite/postAddToFavorite";
import { IToast } from "../../types/toast";
import deleteRemoveFromFavorites from "../../api/favorite/deleteRemoveFromFavorites";
import ImageCircleBorder from "../../components/ImageCircleBorder";
import { MomentsTriggerContext } from "../../contexts/momentTrigger";
import { feedMock } from "../../mocks/feedMock";
import { useChat } from "../../contexts/openedChatContext";
import { FavoritesListContext } from "../../contexts/favoritesListTrigger";

type CreatorDetails = {
  creatorName: string;
  creatorAge: number | string;
  isVip: boolean;
};

const FeedPage: React.FC = () => {
  const {
    setSecondaryScreen,
    componentFeed,
    params,
    setFeedScreen,
    componentPrincipal,
    componentSecondary,
  } = useDeskNavigation();
  const { userId } = params;
  const { getChats } = useWebSocket();
  const { userInfo } = useUserInfo();
  const divRef = useRef<HTMLDivElement | null>(null);
  const { setMomentTrigger } = useContext(MomentsTriggerContext);
  const { setFavoritesListTrigger } = useContext(FavoritesListContext);

  const [postsTotal, setPostsTotal] = useState("100");
  const [photosTotal, setPhotosTotal] = useState("200");
  const [videosTotal, setVideosTotal] = useState("300");
  const [requestsTotal, setRequestsTotal] = useState("400");
  const [creatorInfo, setCreatorInfo] = useState<CreatorDetails>(
    {} as CreatorDetails
  );
  const [isProfileFavorite, setIsProfileFavorite] = useState(false);
  const [openedChatUserIds, setOpenedChatUserIds] = useState<string[]>([]);
  const [toastShow, setToastShow] = useState(false);
  const [pageParams, setPageParams] = useState({} as Params);
  const [windowWidthSize, setWindowWidthSize] = useState(0);
  const [chats, setChats] = useState<Chat[]>([]);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [classWidth, setClassWidth] = useState(0);

  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const { activeChatId, setActiveChatId } = useChat();

  const [profileAvatar, setProfileAvatar] = useState("");

  useEffect(() => {
    // favoriteCreators();

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === divRef.current) {
          const { width } = entry.contentRect;

          let size = Math.floor(width + 48);
          setClassWidth(size);
        }
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  useEffect(() => {
    publicDetails();
    setPageParams(params);
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // const getListChats = useCallback(async () => {
  //   getChats({}, (res: Chat[]) => {
  //     const openedChatsIds: string[] | [] = res?.map(
  //       (chat: Chat) => chat?.chatParticipants[0]?.user?.userId
  //     );
  //     setOpenedChatUserIds(openedChatsIds);
  //     setChats(res);
  //   });
  // }, [getChats, userId]);

  // useEffect(() => {
  //   getListChats();
  // }, [getChats, getListChats]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target === divRef.current) {
          const { width } = entry.contentRect;

          setWindowWidthSize(Math.floor(width + 48));
        }
      }
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, []);

  const publicDetails = async () => {
    try {
      const request = await getPublicDetails(userInfo.access_token, userId);
      const creator: IUserPublicInfo = request.res;
      setIsProfileFavorite(creator.isFavorite);
      setProfileAvatar(creator.profile.photos[0]?.thumbnailUrl ?? ProfileIcon);

      setCreatorInfo({
        creatorAge: creator.profile.age,

        creatorName: creator.displayname,
        isVip: creator.isVip,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const clickLikeHandler = async () => {
    if (!isProfileFavorite) {
      setIsProfileFavorite(true);
      const response = await postAddToFavorite(userInfo.access_token, userId);
      switch (response.status) {
        case 201:
        case 204:
          setFavoritesListTrigger((prev) => !prev);
          break;
        case 409:
          break;

        default:
          setToastConfig({
            title: "Error",
            description:
              "We were unable to process the request. Please, Try again",
            type: "error",
          });
          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
          }, 3000);
          break;
      }
    } else {
      setIsProfileFavorite(false);
      const response = await deleteRemoveFromFavorites(
        userInfo.access_token,
        userId
      );

      switch (response.status) {
        case 204:
        case null:
          setFavoritesListTrigger((prev) => !prev);

          break;
        case 409:
          break;

        default:
          setToastConfig({
            title: "Error",
            description:
              "We were unable to process the request. Please, Try again 0000",
            type: "error",
          });
          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
          }, 3000);
          break;
      }
    }
    setMomentTrigger((prev) => !prev);
  };

  const buttonsGroup: ButtonNavigationInfo[] = [
    {
      text: "Posts",
      textRight: postsTotal,
      iconLeft: "star-outline",
      clickAction: () => {},
      id: 1,
    },
    {
      text: "Photos",
      textRight: photosTotal,
      iconLeft: "photo",
      clickAction: () => {},
      id: 2,
    },
    {
      text: "Videos",
      textRight: videosTotal,
      iconLeft: "video",
      clickAction: () => {},
      id: 3,
    },
    {
      text: "Requests",
      textRight: requestsTotal,
      iconLeft: "gifts",
      clickAction: () => {},
      id: 4,
    },
  ];

  const closeFeed = () => {
    setFeedScreen("");

    if (!componentPrincipal && componentSecondary) {
      setSecondaryScreen("");
    }
  };

  const subscribeHandler = () => {};

  const sendMessageClick = async () => {
    const creatorId = userId && userId !== "0" ? userId : pageParams.userId;

    if (creatorId) {
      const isOpenedChat = openedChatUserIds?.includes(creatorId);
      const chatContactService = CreateChatContact();
      const response = await chatContactService.postCreateChatContact(
        creatorId,
        userInfo.access_token
      );

      switch (response.status) {
        case 201:
          setActiveChatId(response.res.chatId);
          break;

        default:
          break;
      }
      setSecondaryScreen("/personal-chat", {
        chatId: response.res.chatId,
        userId: creatorId,
        isLocked: JSON.stringify(isOpenedChat),
      });
    }
  };

  return (
    <div
      className={`feed-page ${
        componentPrincipal && componentSecondary
          ? "vertical-separator-left"
          : ""
      }`}
      ref={divRef}
    >
      <PageTitle
        onBackClick={closeFeed}
        className="feed-page__page-title margin-hor-16"
        hasCloseIcon={windowSize >= 768}
      />
      <CoverImage
        creatorInfo={{
          creatorCoverImage: CoverPhoto,
          creatorName: creatorInfo.creatorName,
        }}
      />

      <main className={`feed-page-main padding-hor-16`}>
        <div
          className={`feed-page__interaction-nav ${
            windowWidthSize <= 560 ? "mobile-size" : ""
          }`}
        >
          <div
            className={`interaction-nav__profile-info ${
              windowWidthSize <= 560 ? "info-column" : ""
            }`}
          >
            <ImageCircleBorder
              centerImage={profileAvatar}
              centerImageAlt="user profile"
              size={56}
              noSpaceBetweenBorder
            />

            <div className="profile-info__name">
              <p>
                {creatorInfo.creatorName}
                {creatorInfo.isVip && (
                  <span className="icon icon-burn icon-md margin-left-4" />
                )}
              </p>
              <p>{creatorInfo.creatorAge} years</p>
            </div>
          </div>
          <div className="interaction-nav__interaction">
            <ButtonIcon
              key={0}
              onClick={clickLikeHandler}
              buttonStyle={
                isProfileFavorite ? "transparentActive" : "transparent"
              }
              icon={
                isProfileFavorite
                  ? "icon-heart-solid"
                  : "icon-heart-outline icon-gray-light"
              }
            />
            {[
              <ButtonIcon
                key={1}
                onClick={sendMessageClick}
                icon={"icon-messages icon-gray-light"}
                buttonStyle="transparent"
              />,
              <Button buttonStyle="primary" key={2} onClick={subscribeHandler}>
                Subscribe
              </Button>,
            ][windowWidthSize <= 560 ? "reverse" : "slice"]()}
          </div>
        </div>
        <div className="buttons-navigation-feed-container">
          <ButtonNavigation buttons={buttonsGroup} className="margin-vert-24" />
        </div>

        {feedMock.map((creator, index) => (
          <FeedCards
            key={index}
            image={creator.image}
            age={creator.age}
            isVerify={creator.isVerify}
            name={creator.name}
            postTime={creator.postTime}
            rating={creator.rating}
            qnt_videos={creator.qnt_videos}
            qnt_photos={creator.qnt_photos}
            price_pack={creator.price_pack}
            views_post={creator.views_post}
            isLockedContent={creator.isLockedContent}
            price={creator.price}
            className="margin-bottom-40"
          />
        ))}
      </main>
    </div>
  );
};

export default FeedPage;
