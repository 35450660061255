import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./styles.scss";

interface SlidUpProps {
  isOpen: boolean;
  children: ReactNode;
  classNameChildren?: string;
  title?: string;
  classNameContainer?: string;
  closeArea?: () => void;
}

const SlidUp: React.FC<SlidUpProps> = ({
  children,
  isOpen,
  classNameChildren,
  title,
  classNameContainer,
  closeArea,
}) => {
  const slidUpRef = useRef<HTMLDivElement>(null);
  const slidUpContentRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const startYRef = useRef<number | null>(null);
  const translateYRef = useRef(0);

  const handleTouchStart = (e: React.TouchEvent) => {
    if (
      e.touches.length !== 1 ||
      (childrenRef.current && childrenRef.current.contains(e.target as Node))
    )
      return;
    startYRef.current = e.touches[0].clientY;
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (
      e.touches.length !== 1 ||
      startYRef.current === null ||
      childrenRef.current?.contains(e.target as Node)
    )
      return;
    const currentY = e.touches[0].clientY;
    const translateY = Math.max(0, currentY - startYRef.current);
    translateYRef.current = translateY;
    const target = e.currentTarget as HTMLElement;
    target.style.transform = `translateY(${translateY}px)`;
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (childrenRef.current?.contains(e.target as Node)) return;
    const target = e.currentTarget as HTMLElement;
    if (translateYRef.current > 100) {
      if (closeArea) closeArea();
    } else {
      target.style.transform = "translateY(0)";
    }
    startYRef.current = null;
    translateYRef.current = 0;
  };

  useEffect(() => {
    const slidUp = slidUpRef.current;

    if (slidUp) {
      if (isOpen) {
        slidUp.style.display = "flex";
        requestAnimationFrame(() => {
          slidUp.classList.add("open");
          slidUp.classList.remove("close");
        });
      } else {
        slidUp.classList.add("close");
        slidUp.classList.remove("open");

        setTimeout(() => {
          if (slidUp.classList.contains("close")) {
            slidUp.style.display = "none";
            if (slidUpContentRef.current) {
              slidUpContentRef.current.style.transform = "translateY(0)";
            }
          }
        }, 350);
      }
    }
  }, [isOpen]);

  return (
    <div
      ref={slidUpRef}
      className={classNameContainer ?? ""}
      onClick={() => {
        if (closeArea) closeArea();
      }}
      id="slid-up-container"
    >
      <div
        ref={slidUpContentRef}
        className="slid-up-content"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="handler-bar" />
        {title && <h4 className="slid-up-title">{title}</h4>}

        <div
          ref={childrenRef}
          onTouchStart={handleTouchStart}
          className={classNameChildren}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default SlidUp;
