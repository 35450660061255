import React, { ReactNode } from "react";
import { AuthProvider } from "./hooks/auth";
import { PwaProvider } from "./hooks/pwa";
import { DeskNavigationProvider } from "./hooks/useDeskNavigation";
import { LoginSignUpProvider } from "./hooks/useLoginSignUpNavigation";
import { UserInfoProvider } from "./hooks/userInfo";
import { ToggleChatPageProvider } from "./contexts/toggleChatOpen";
import { FilesProvider } from "./hooks/documentContext";
import { ModalProvider } from "./hooks/useModal";
import { ChatProvider } from "./contexts/openedChatContext";
import { UseWindowWidthSizeProvider } from "./hooks/useWindowWidthSize";
import { PublishContentProvider } from "./hooks/usePublishContent";
import { WebSocketProvider } from "./contexts/webSocketContext";
import { ProducerDetailsProvider } from "./contexts/producerDetailsContext";
import { UserAvailableRosesProvider } from "./contexts/availableRosesContext";
import { IconProvider } from "./contexts/iconNavContext";
import { MomentsTriggerProvider } from "./contexts/momentTrigger";
import { LanguageProvider } from "./hooks/languageContext";
import { FavoritesCatalogTriggerProvider } from "./contexts/favoritesCatalogTrigger";
import { MediaProvider } from "./contexts/mediaContext";
import { FavoritesListTriggerProvider } from "./contexts/favoritesListTrigger";
import { SearchCreatorCatalogProvider } from "hooks/useSearchCatalog";
import { ModalSecondaryProvider } from "hooks/useModalSecondary";

interface AppProviderProps {
  children: ReactNode;
}

const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <PwaProvider>
      <AuthProvider>
        <UserInfoProvider>
          <WebSocketProvider>
            <LanguageProvider>
              <SearchCreatorCatalogProvider>
                <DeskNavigationProvider>
                  <MomentsTriggerProvider>
                    <LoginSignUpProvider>
                      <PublishContentProvider>
                        <FilesProvider>
                          <UseWindowWidthSizeProvider>
                            <ChatProvider>
                              <ModalSecondaryProvider>
                                <ModalProvider>
                                  <ToggleChatPageProvider>
                                    <ProducerDetailsProvider>
                                      <UserAvailableRosesProvider>
                                        <FavoritesCatalogTriggerProvider>
                                          <FavoritesListTriggerProvider>
                                            <IconProvider>
                                              <MediaProvider>
                                                {children}
                                              </MediaProvider>
                                            </IconProvider>
                                          </FavoritesListTriggerProvider>
                                        </FavoritesCatalogTriggerProvider>
                                      </UserAvailableRosesProvider>
                                    </ProducerDetailsProvider>
                                  </ToggleChatPageProvider>
                                </ModalProvider>
                              </ModalSecondaryProvider>
                            </ChatProvider>
                          </UseWindowWidthSizeProvider>
                        </FilesProvider>
                      </PublishContentProvider>
                    </LoginSignUpProvider>
                  </MomentsTriggerProvider>
                </DeskNavigationProvider>
              </SearchCreatorCatalogProvider>
            </LanguageProvider>
          </WebSocketProvider>
        </UserInfoProvider>
      </AuthProvider>
    </PwaProvider>
  );
};

export default AppProvider;
