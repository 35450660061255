import {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useUserInfo } from "../hooks/userInfo";
import getUserSelfDetail from "../api/getUserSelfDetail";

interface IAvaliablesRosesContext {
  availableRoses: number;
  updateAddAvailableRoses: (value: number) => void;
  updateSubtractAvailableRoses: (value: number) => void;
  updateSetAvailableRoses: (value: number) => void;
}

const UserAvailableRosesContext = createContext<
  IAvaliablesRosesContext | undefined
>(undefined);

const UserAvailableRosesProvider = ({ children }: { children: ReactNode }) => {
  const { userInfo } = useUserInfo();
  const { access_token } = userInfo || {};
  const [availableRoses, setAvailableRoses] = useState(0);

  useEffect(() => {
    const getUserRoses = async () => {
      if (!access_token) return;
      const { res, status } = await getUserSelfDetail(access_token);
      if (status === 200 || status === 201)
        setAvailableRoses(res.quantityRoses);
    };
    getUserRoses();
  }, [access_token]);

  const updateSubtractAvailableRoses = (value: number) => {
    setAvailableRoses((prev: number) => prev - value);
  };

  const updateAddAvailableRoses = (value: number) => {
    setAvailableRoses((prev: number) => prev + value);
  };

  const updateSetAvailableRoses = (value: number) => {
    setAvailableRoses(value);
  };

  return (
    <UserAvailableRosesContext.Provider
      value={{
        availableRoses,
        updateAddAvailableRoses,
        updateSubtractAvailableRoses,
        updateSetAvailableRoses,
      }}
    >
      {children}
    </UserAvailableRosesContext.Provider>
  );
};

const useAvailableRoses = () => {
  const context = useContext(UserAvailableRosesContext);
  if (!context) {
    throw new Error(
      "useAvailableRoses must be used within a UserAvailableRosesProvider"
    );
  }
  return context;
};

export { UserAvailableRosesProvider, useAvailableRoses };
