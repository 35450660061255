import React, { createContext, ReactNode, useContext, useState } from "react";

interface Media {
  messageId: string;
}

interface MediaContextProps {
  unlockedMedias: Media[];
  unlockMedia: (messageId: string) => void;
}

const MediaContext = createContext<MediaContextProps | undefined>(undefined);

export const useMediaContext = () => {
  const context = useContext(MediaContext);
  if (!context) {
    throw new Error("useMediaContext must be used within a MediaProvider");
  }
  return context;
};

export const MediaProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [unlockedMedias, setUnlockedMedias] = useState<Media[]>([]);

  const unlockMedia = (messageId: string) => {
    setUnlockedMedias((prevMedias) => [...prevMedias, { messageId }]);
  };

  return (
    <MediaContext.Provider value={{ unlockedMedias, unlockMedia }}>
      {children}
    </MediaContext.Provider>
  );
};
