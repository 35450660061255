import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import InputText from "../../components/Inputs/InputText";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useEffect, useState } from "react";
import Button from "../../components/Buttons/Button";
import mapMock from "../../assets/icons/image-mock/map-mock.png";
import InputCheckbox from "../../components/Inputs/InputCheckbox";
import getUserInfo from "../../api/userInfo/getUserInfo";
import { useUserInfo } from "../../hooks/userInfo";
import getProfileDetails from "../../api/profileDetails/getProfileDetails";
import patchUserInfo from "../../api/userInfo/patchUserInfo";
import languageIcon from "../../assets/icons/language-icon-gray.svg";

interface ListDetails {
  name: string;
  profileDetailId: number;
  type: string;
  none: string;
}

const UserSettingsLanguageProfilePage: React.FC = () => {
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo } = useUserInfo();

  const [residence, setResidence] = useState<string[]>([]);

  const [languagesList, setLanguagesList] = useState<[]>();

  const updateLanguage = async () => {
    const response = await patchUserInfo(userInfo.access_token, {
      profileLanguages: residence,
    });

    /* update */
  };

  useEffect(() => {
    requestProfileLanguages();
    requestListLanguages();
  }, [userInfo]);

  const requestListLanguages = async () => {
    const response = await getProfileDetails(userInfo.access_token);
    setLanguagesList(
      response?.res?.filter((detail: ListDetails) => detail.type === "language")
    );
  };

  const requestProfileLanguages = async () => {
    const response = await getUserInfo(userInfo.access_token);
    const namesArray = response.res.languages.map(
      (language: ListDetails) => language.name
    );
    // languagesMock[1].selected = namesArray;
    setResidence(namesArray);
  };

  const selectOption = (option: string[]) => {
    setResidence(option);
  };

  return (
    <div className="user-settings-language">
      <div>
        <PageTitle
          title={"Change Language"}
          subtitle={"Configuration"}
          onBackClick={() =>
            setPrincipalScreen("/user-settings-profile", { btn: "button3" })
          }
          className="margin-hor-16"
        />

        <div className="div__content margin-hor-24">
          <InputText
            iconLeft={languageIcon}
            onChangeInput={() => {}}
            inputType="text"
            readOnly={true}
            placeholder="Select Language"
            value={residence?.join(", ")}
            textTransform={"capitalize"}
          />

          <div className="separator-margin" />

          <InputCheckbox
            inputValues={residence}
            setInputsValues={setResidence}
            elements={
              languagesList
                ? languagesList.map((item: ListDetails) => {
                    return {
                      groupName: "language",
                      inputLabel: `${item.name}`,
                      inputValue: `${item.name}`,
                    };
                  })
                : []
            }
            defaultValueProp={residence}
          />

          <div className="separator-margin" />

          <Button
            children={"Upgrade to change language"}
            buttonStyle={"primary"}
            onClick={updateLanguage}
          />
        </div>
      </div>
    </div>
  );
};

export default UserSettingsLanguageProfilePage;
