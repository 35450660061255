import React, { createContext, useContext, useState } from "react";
import { ICatalogResponse } from "../types/catalog";

const ProducerDetailsContext = createContext<any>(null);

export const ProducerDetailsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [producerDetails, setProducerDetails] = useState<ICatalogResponse>(
    {} as ICatalogResponse
  );

  const updateProducerDetails = (updatedDetails: Partial<ICatalogResponse>) => {
    setProducerDetails((prevDetails) => ({
      ...prevDetails,
      ...updatedDetails,
    }));
  };

  return (
    <ProducerDetailsContext.Provider
      value={{ producerDetails, updateProducerDetails }}
    >
      {children}
    </ProducerDetailsContext.Provider>
  );
};

export const useProducerDetails = () => {
  const context = useContext(ProducerDetailsContext);
  if (!context) {
    throw new Error(
      "useProducerDetails must be used within a ProducerDetailsProvider"
    );
  }
  return context;
};
