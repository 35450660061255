import fetchApi from "../fetchApi";

const getCreatorSettingsGoalHistory = async (token: string) => {
  return await fetchApi({
    service: "creatorSettings",
    endPoint: "/goal/history",
    method: "GET",
    token,
  });
};

export default getCreatorSettingsGoalHistory;
