import React, { useEffect, useState } from "react";
import "./styles.scss";

interface ButtonPillProps {
  className?: string;
  clickPill: (a: any) => void;
  textPill: string;
  iconName: string;
  isPillSelected: boolean;
}

const ButtonPill: React.FC<ButtonPillProps> = (props) => {
  const { className, clickPill, iconName, textPill, isPillSelected } = props;

  const [selectedPill, setSelectedPill] = useState(isPillSelected);

  useEffect(() => {
    setSelectedPill(isPillSelected);
  }, [isPillSelected]);

  return (
    <div id="button-pill" className={className}>
      <div
        onClick={clickPill}
        className={`image-container__pill ${
          selectedPill ? "background-white" : ""
        }`}
      >
        <span
          className={`icon ${iconName} ${
            selectedPill ? "icon-pink" : "icon-white"
          }`}
        />
        <p className={`button__text ${selectedPill ? "color-charcoal" : ""}`}>
          {textPill}
        </p>
      </div>
    </div>
  );
};

export default ButtonPill;
