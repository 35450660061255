import React, { useState } from "react";

import "./styles.scss";

import InputText from "../../Inputs/InputText";
import ButtonIcon from "../../Buttons/ButtonIcon";
import ImageCircleBorder from "../../ImageCircleBorder";

import { useIconContext } from "../../../contexts/iconNavContext";
import { useAvailableRoses } from "../../../contexts/availableRosesContext";

import FilterIcon from "../../../assets/icons/iconfilter.svg";
import ProfileIcon from "../../../assets/icons/profile-icon-gray.svg";

import { thousandsFormat } from "../../../utils/thousandsFormat";
import { useSearchCatalog } from "hooks/useSearchCatalog";
import { useDeskNavigation } from "hooks/useDeskNavigation";

interface HeaderHomePageProps {
  hasANewMessage: boolean;
  profileAvatar: string;
  className?: string;
  backButtonClick?: React.MouseEventHandler<HTMLImageElement>;
  clickProfileAvatar?: (a: any) => void;
  clickChatList?: (a?: any) => void;
  favoritesClick?: (a?: any) => void;
  logoClick?: (a?: any) => void;
  clickOnGrid?: () => void;
}

const HeaderHomePage: React.FC<HeaderHomePageProps> = ({
  hasANewMessage,
  profileAvatar,
  className,
  backButtonClick,

  clickProfileAvatar,
  clickChatList,
  favoritesClick,
  logoClick,
  clickOnGrid,
}) => {
  const { handleButtonClick, getIconClass } = useIconContext();
  const { availableRoses } = useAvailableRoses();
  const availableRosesFormatted = thousandsFormat(availableRoses);
  const { searchValue, setSearchValue } = useSearchCatalog();
  const {
    setFeedScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    setMainScreen,
  } = useDeskNavigation();

  const clickOnSearch = () => {
    setFeedScreen("");
    setPrincipalScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setMainScreen("/catalog");
  };

  return (
    <header id="header-home-page" className={className}>
      {/* MOBILE */}
      <div className="header-mobile-container ">
        <div className="icon-container">
          <div
            className={`logo-text-header ${logoClick ? "cursor" : ""}`}
            onClick={logoClick}
          >
            <span className="icon icon-roses-default icon-md" />
            <h1>
              Roses<sup>™</sup>
              <span className="beta">beta</span>
            </h1>
          </div>

          <div className="actions-container">
            {/* <div
              className={`profile-wrapper ${
                profileAvatar ? "user-logged" : ""
              }`}
              onClick={clickProfileAvatar}
            >
              <div>
                <span>
                  1,133 <span className="separator-profile"> | </span>
                  <span className="number">5</span>
                </span>
              </div>
              <div>
                <img
                  className="storyImage"
                  src={profileAvatar ? profileAvatar : ProfileIcon}
                  alt="stories"
                />
              </div>
            </div> */}
            <div className="roses-and-golden-roses">
              <p className="roses-text">{availableRosesFormatted}</p>
              <span className="vertical-separator"> | </span>

              <p className="golden-roses-text">5</p>
            </div>
            <ImageCircleBorder
              onClick={clickProfileAvatar}
              centerImageAlt=""
              centerImage={profileAvatar ? profileAvatar : ProfileIcon}
              noSpaceBetweenBorder
              size={40}
            />
          </div>
        </div>
      </div>

      {/* DESKTOP */}
      <div className="header-wide-container">
        <div
          className={`logo-container ${logoClick ? "cursor" : ""}`}
          onClick={logoClick}
        >
          <span className="icon icon-roses-default icon-lg" />
          <h1>
            Roses<sup>™</sup>
            <span className="beta">beta</span>
          </h1>
        </div>
        <div className="div-container">
          <div className="center-content">
            <div className="left-buttons-container">
              <ButtonIcon
                icon="icon-grid"
                buttonStyle="tertiary"
                iconClass={getIconClass("grid")}
                className="header-navigate-btn"
                onClick={() => {
                  handleButtonClick("grid");
                  clickOnGrid?.();
                }}
              />
              <ButtonIcon
                icon="icon-heart-outline"
                buttonStyle="tertiary"
                iconClass={getIconClass("heart-outline")}
                className="header-navigate-btn"
                onClick={() => {
                  handleButtonClick("heart-outline");
                  favoritesClick?.();
                }}
              />
            </div>
            <InputText
              value={searchValue}
              searchInput
              onClick={clickOnSearch}
              onChangeInput={setSearchValue}
              placeholder="Search"
              inputStyle="input-text-search"
              iconRight={FilterIcon}
              inputType="text"
            />
            <div className="right-buttons-container">
              <div className="new-message-container">
                <ButtonIcon
                  icon="icon-messages"
                  buttonStyle="tertiary"
                  iconClass={getIconClass("messages")}
                  className="header-navigate-btn"
                  onClick={() => {
                    handleButtonClick("messages");
                    clickChatList?.();
                  }}
                />
                {hasANewMessage && <span className="sphere-icon"></span>}
              </div>
            </div>
          </div>

          <div className="right-buttons-container-profile">
            {/* <div className={`profile-wrapper`} onClick={clickProfileAvatar}>
              <div style={{ width: 60 }}>
                <span className="profile-wrapper__roses_amount">
                  1,133 <span className="separator-profile"> | </span>
                  <span className="number">5</span>
                </span>
              </div>

              <div>
                <img
                  className="storyImage"
                  src={profileAvatar ? profileAvatar : ProfileIcon}
                  alt="stories"
                />
              </div>
            </div> */}
            <div className="roses-and-golden-roses">
              <p className="roses-text">{availableRosesFormatted}</p>
              <span className="vertical-separator"> | </span>
              <p className="golden-roses-text">5</p>
            </div>
            <ImageCircleBorder
              onClick={clickProfileAvatar}
              centerImageAlt=""
              centerImage={profileAvatar ? profileAvatar : ProfileIcon}
              // noBorder
              noSpaceBetweenBorder
              noBorder
              hasPadding
              size={40}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderHomePage;
