import React from "react";
import "./styles.scss";
import ListItem from "../../Lists/ListItem";
import checkPink from "../../../assets/icons/check-simple-pink.svg";

interface RadioListOptions {
  title: string;
  price: string;
  description: string;
  value: string;
  icon: string;
  groupName: string;
  checked?: boolean;
  onChange: (value: string) => void;
  benefits?: any[];
}

const InputRadioSub: React.FC<RadioListOptions> = (props) => {
  const {
    title,
    price,
    description,
    value,
    icon,
    groupName,
    checked,
    onChange,
    benefits,
  } = props;

  return (
    <label id="option">
      <div className="option-title">
        <h1 className="title">
          {" "}
          <b>{title.split(" ")[0]}</b> {title.split(" ")[1]}
        </h1>
        <img src={icon} alt="" />
        <div className="input-select">
          {value === "bloom" && <div className="popular">Popular</div>}

          <input
            type="radio"
            value={value}
            name={groupName}
            defaultChecked={checked}
            onChange={() => onChange(value)}
            className="input-radio"
          />
        </div>
      </div>

      <div className="option-price">
        <span className="price">{price}</span>
        /mo
      </div>

      <div className="option-text">{description}</div>

      {checked &&
        benefits?.map((option, index) => (
          <div className="option-benefits" key={index}>
            <ListItem
              infoContent={option.benefit}
              showInfo
              showSeparator={benefits.length > index + 1}
              showIconRight
              iconRightContent={checkPink}
            />
          </div>
        ))}
    </label>
  );
};

export default InputRadioSub;
