import React, { createContext, ReactNode, useContext, useState } from "react";

interface SearchCreatorCatalogProps {
  searchValue: string;
  setSearchValue: (search: string) => void;
}

const SearchCreatorCatalogContext = createContext<
  SearchCreatorCatalogProps | undefined
>(undefined);

const SearchCreatorCatalogProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [searchValue, setSearchValue] = useState("");

  return (
    <SearchCreatorCatalogContext.Provider
      value={{ searchValue, setSearchValue }}
    >
      {children}
    </SearchCreatorCatalogContext.Provider>
  );
};

const useSearchCatalog = () => {
  const context = useContext(SearchCreatorCatalogContext);

  if (context === undefined) {
    throw new Error(
      "useSearchCatalog must be used within a SearchCreatorCatalogProvider"
    );
  }

  return context;
};

export { SearchCreatorCatalogProvider, useSearchCatalog };
