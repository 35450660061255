export const thumbnailGenerator = (videoURL: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.src = videoURL;
    video.crossOrigin = "anonymous";
    video.currentTime = 0.1;
    video.onloadeddata = () => {
      try {
        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext("2d");

        if (context) {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          const thumbnail = canvas.toDataURL("image/png");
          resolve(thumbnail);
        } else {
          reject(new Error("Não foi possível obter o contexto do canvas."));
        }
      } catch (error) {
        reject(error);
      }
    };

    video.onerror = () => {
      reject(
        new Error(
          "Falha ao carregar o vídeo. Pode ser um problema de CORS ou URL inválida."
        )
      );
    };
  });
};
