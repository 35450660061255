import React, { useState } from "react";
import "./styles.scss";

interface PreviewMediaCardsListProps {
  className?: string;
  capturedMedia: ICapturedMedia[];
  removeMediaFromSharing: (media: ICapturedMedia) => void;
  addMediaClick: (a: any) => void;
  setMediaToShowCallBack: (media: ICapturedMedia | null) => void;
  bottomPosition?: number;
}

type ICapturedMedia = {
  type: string;
  media: string;
  thumbnail?: string;
};

const PreviewMediaCardsList: React.FC<PreviewMediaCardsListProps> = (props) => {
  const {
    className,
    capturedMedia,
    removeMediaFromSharing,
    addMediaClick,
    setMediaToShowCallBack,
    bottomPosition = 24,
  } = props;
  const [mediaToShow, setMediaToShow] = useState<ICapturedMedia | null>(null);
  const [mediaToLock, setMediaToLock] = useState<ICapturedMedia[]>([]);

  const lockImage = (media: ICapturedMedia) => {
    if (mediaToLock.includes(media)) {
      const mediaFiltered = mediaToLock.filter((m) => media !== m);
      setMediaToLock(mediaFiltered);
    } else {
      setMediaToLock((prev) => [...prev, media]);
    }
  };
  const mediaClickHandler = (media: ICapturedMedia) => {
    if (mediaToShow !== media) {
      setMediaToShow(media);
      setMediaToShowCallBack(media);
    } else {
      setMediaToShow(null);
    }
  };

  const previewMediaClasses = [
    className ?? "",
    capturedMedia.length <= 6 ? "justify-center" : "",
  ]
    .join(" ")
    .trim();

  return (
    <div
      id="preview-media"
      style={{ bottom: bottomPosition }}
      className={previewMediaClasses}
    >
      {capturedMedia?.map((media, index) => (
        <div key={index} className={`preview-media__image-icons-container`}>
          <div
            className={`preview-media__sup-icon ${
              mediaToLock.includes(media) ? "locked-media-icon" : ""
            }`}
            onClick={() => {
              if (media === mediaToShow) {
                removeMediaFromSharing(media);
              } else {
                lockImage(media);
              }
            }}
          >
            {media === mediaToShow ? (
              <span className="icon icon-close icon-white icon-md" />
            ) : (
              <span className={`icon icon-lock icon-white icon-md`} />
            )}
          </div>
          <div
            className={`preview-media-container ${
              media === mediaToShow ? "pink-coating" : ""
            } ${mediaToLock.includes(media) ? "locked-media" : ""}`}
          >
            {media.type === "photo" ||
            (media.type === "video" && media.thumbnail) ? (
              <img
                src={media.type === "photo" ? media.media : media.thumbnail}
                alt={`capture ${index}`}
                className={`preview-media__media`}
                onClick={() => mediaClickHandler(media)}
              />
            ) : (
              <video
                src={media.media}
                className={`preview-media__media`}
                onClick={() => mediaClickHandler(media)}
              >
                <source src={media.media}></source>
              </video>
            )}
          </div>
        </div>
      ))}

      <button
        className={`preview-media__plus-button ${
          capturedMedia.length > 6 ? "margin-right-24" : ""
        }`}
        onClick={addMediaClick}
      >
        <span className="icon icon-plus icon-white icon-md padding-16" />
      </button>
    </div>
  );
};

export default PreviewMediaCardsList;
