import React, { useState } from "react";
import "./styles.scss";
import PageTitle from "../../../components/PageTitles";
import DropDown, {
  DropDownRadioElement,
} from "../../../components/Lists/DropDown";
import InputText from "../../../components/Inputs/InputText";
import InputMessage from "../../../components/TextAreas/Textarea";
import ListItemLink from "../../../components/Lists/ListItemLink";
import Button from "../../../components/Buttons/Button";
import { useWindowWidthSize } from "../../../hooks/useWindowWidthSize";
import UnionGray from "../../../assets/icons/union-gray.svg";
import RosesLogoIconPink from "../../../assets/icons/logos/logo-rose-icon-pink.svg";
import PlayIconWhite from "../../../assets/icons/play-circle-white.svg";
import PhotoIconGray from "../../../assets/icons/photo-gray.svg";
import ProfileIconGray from "../../../assets/icons/profile-simple-icon-gray.svg";
import LockIconGray from "../../../assets/icons/lock-icon-gray.svg";
import ProfileSimpleIconGray from "../../../assets/icons/profile-simple-icon-gray.svg";
import BalloonIconGray from "../../../assets/icons/ballon-icon-gray.svg";
import { useModal } from "../../../hooks/useModal";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "../../../components/Buttons/ButtonNavigation";
import InputCheckbox from "../../../components/Inputs/InputCheckbox";
import ListItemCheckBox from "../../../components/Lists/ListItemCheckBox";

interface CreatorContentProps {
  setSecondaryScreenShowing: React.Dispatch<
    React.SetStateAction<"" | "config" | "exclusive-list">
  >;
  shareContentBackButtonHandler: () => void;
  uploadLocation: string;
  uploadFilesMode: (location: string[]) => void;
  rosesAmount: number;
  addRosesInputHandler: (e: string) => void;
  setCreatedTags: (value: React.SetStateAction<string[] | []>) => void;
  createdTags: string[];
  setDescriptionContent: React.Dispatch<React.SetStateAction<string>>;
  descriptionContent: string;
  createdTag: string;
  setCreatedTag: React.Dispatch<React.SetStateAction<string>>;
  previousPage: string;
  shareContentHandler: () => Promise<void>;

  setUploadType: React.Dispatch<
    React.SetStateAction<"post" | "moment" | "feed">
  >;

  saveForLaterHandler: (a: any) => void;
}

const CreatorContent: React.FC<CreatorContentProps> = (props) => {
  const {
    shareContentBackButtonHandler,
    uploadLocation,
    uploadFilesMode,
    rosesAmount,
    addRosesInputHandler,
    setCreatedTags,
    createdTags,
    descriptionContent,
    setDescriptionContent,
    createdTag,
    setCreatedTag,
    previousPage,
    shareContentHandler,
    setUploadType,
    setSecondaryScreenShowing,
    saveForLaterHandler,
  } = props;

  const { windowSize } = useWindowWidthSize();
  const { setOpenUseModal } = useModal();
  const [uploadTypeState, setUploadTypeState] = useState("post");
  const [listButtonsSecondaries, setListButtonsSecondaries] = useState(false);
  const [shareInMoments, setShareInMoments] = useState(false);

  const uploadFilesOptions: DropDownRadioElement[] = [
    {
      groupName: "upload-files",
      subText: "Everyone on the internet",
      inputLabel: "Teaser",
      inputValue: "Teaser",
      icon: "icon-photo",
      node: <span className="sfw-pill">SFW</span>,
    },
    {
      groupName: "upload-files",
      subText: "Only members will see your photo",
      inputLabel: "Members Only",
      inputValue: "Members Only",
      icon: "icon-lock",
    },
  ];

  const cancelShareHandler = () => {
    setOpenUseModal(false);
  };

  const buttonsGroup: ButtonNavigationInfo[] = [
    {
      text: "Post",
      clickAction: () => {
        setUploadType("post");
        setUploadTypeState("post");
      },
      id: 1,
    },
    {
      text: "Moment",
      clickAction: () => {
        setUploadType("moment");
        setUploadTypeState("moment");
      },
      id: 2,
    },
  ];

  const continueHandler = () => {
    setListButtonsSecondaries(true);
  };

  const backButton = () => {
    if (listButtonsSecondaries) {
      setListButtonsSecondaries(false);
    } else {
      shareContentBackButtonHandler();
    }
  };

  return (
    <section className="creator-content-settings padding-24">
      <PageTitle
        title={"Create Content"}
        subtitle={"Increase visibility"}
        onBackClick={backButton}
        hasButtonNavigation={listButtonsSecondaries || windowSize < 700}
        hasCloseIcon={false}
      />
      {listButtonsSecondaries ? (
        <div className="creator-content-settings__list-items part-two">
          <ListItemCheckBox
            textPrincipal="Share to Moments"
            textSecondary="Temporary Post"
            groupName="share-moments"
            iconLeft="icon-clock"
            onChange={setShareInMoments}
            hasSeparator
          />

          <ListItemLink
            highlightText="Add Collaborator"
            simpleText="Increase visibility"
            hasSeparator
            onClick={() => {}}
            hasArrowRight
            imageLeft={ProfileSimpleIconGray}
            isImageCircle
          />
          <ListItemLink
            highlightText="Message"
            simpleText="Share privately to people"
            onClick={() => {
              setSecondaryScreenShowing("exclusive-list");
            }}
            hasArrowRight
            imageLeft={BalloonIconGray}
            isImageCircle
            // hasSeparator
          />
        </div>
      ) : (
        <>
          <ButtonNavigation
            horizontalMargin={false}
            buttons={buttonsGroup}
            className="margin-bottom-16"
            buttonsColorNonSelected="dawn"
          />
          <div className="creator-content-settings__list-items">
            <DropDown
              dropType="click-select"
              defaultValueProp={"Members Only"}
              iconLeft={
                uploadLocation === "Teaser"
                  ? PhotoIconGray
                  : uploadLocation === "Members Only"
                  ? LockIconGray
                  : undefined
              }
              inputValueSet={uploadLocation}
              options={uploadFilesOptions}
              setValueCallBack={uploadFilesMode}
              elementNode={
                uploadLocation === "Teaser" ? (
                  <span className="sfw-pill">SFW</span>
                ) : uploadLocation === "secret" ? (
                  <span className="nsfw-pill">NSFW</span>
                ) : (
                  false
                )
              }
            />
            {uploadLocation === "secret" ||
            uploadLocation === "Members Only" ? (
              <InputText
                inputType="text"
                value={`Roses: ${rosesAmount}`}
                iconLeft={RosesLogoIconPink}
                onChangeInput={addRosesInputHandler}
                className="margin-top-8"
              />
            ) : (
              false
            )}
            {windowSize > 700 ? (
              <InputMessage
                value={descriptionContent}
                onChangeInput={setDescriptionContent}
                placeholder={"Description"}
                maxLen={250}
                className="margin-top-8"
              />
            ) : (
              false
            )}
            <InputText
              onKeyDown={(e) => {
                if (e.key === "Enter" && createdTag) {
                  setCreatedTags((prev) => [...prev, createdTag]);
                  setCreatedTag("");
                }
              }}
              inputType="text"
              iconRight={UnionGray}
              placeholder="Add tag"
              value={createdTag.trim()}
              onChangeInput={setCreatedTag}
              className="margin-top-8"
            />

            {createdTags.length > 0 ? (
              <div className="tags-container margin-top-8">
                {createdTags.map((tag, index) => (
                  <span className="created-tag" key={index}>
                    {tag}
                    <span
                      className="icon icon-close icon-gray-light icon-sm"
                      onClick={() => {
                        setCreatedTags(createdTags.filter((t) => t !== tag));
                      }}
                    />
                  </span>
                ))}
              </div>
            ) : (
              false
            )}
            <>
              {uploadTypeState === "post" ? (
                <ListItemLink
                  highlightText="Add Collaborator"
                  simpleText="Increase visibility"
                  hasSeparator
                  className="margin-top-16"
                  onClick={() => {}}
                  hasArrowRight
                  imageLeft={ProfileSimpleIconGray}
                  isImageCircle
                />
              ) : (
                false
              )}

              {previousPage !== "home" ? (
                <>
                  <ListItemLink
                    highlightText="Message"
                    simpleText="Share privately to people"
                    onClick={() => {
                      setSecondaryScreenShowing("exclusive-list");
                    }}
                    hasArrowRight
                    imageLeft={BalloonIconGray}
                    isImageCircle
                    // hasSeparator
                  />
                </>
              ) : (
                false
              )}
            </>
          </div>
        </>
      )}
      <div className="add-media-page__buttons-container">
        {uploadTypeState === "post" && !listButtonsSecondaries ? (
          <>
            <Button
              buttonStyle="quaternary"
              onClick={cancelShareHandler}
              className="margin-bottom-16"
            >
              Cancel
            </Button>
            <Button buttonStyle="secondary" onClick={continueHandler}>
              Continue
            </Button>
          </>
        ) : uploadTypeState === "moment" && !listButtonsSecondaries ? (
          <>
            <Button
              buttonStyle="quaternary"
              onClick={cancelShareHandler}
              className="margin-bottom-16"
            >
              Cancel
            </Button>
            <Button buttonStyle="primary" onClick={shareContentHandler}>
              Share
            </Button>
          </>
        ) : (
          <>
            <Button
              buttonStyle="quaternary"
              onClick={saveForLaterHandler}
              className="margin-bottom-16"
            >
              Save For later
            </Button>
            <Button buttonStyle="primary" onClick={shareContentHandler}>
              Share
            </Button>
          </>
        )}
      </div>
    </section>
  );
};

export default CreatorContent;
