import SendRosesModal from "../components/Chat/ChatModals/SendRosesModal";
import UnlockContentModal from "../components/Chat/ChatModals/UnlockContentModal";
import Loading from "../components/Loading";

import { useMediaContext } from "../contexts/mediaContext";
import { useCanPurchasedMedia } from "./useCanPurchasedMedia";
import { useModal } from "./useModal";
import { useBuyRoses } from "./useBuyRoses";

const useUnlockContent = () => {
  const { canPurchase, purchaseMedia } = useCanPurchasedMedia();
  const { setOpenUseModal, setModalContent } = useModal();
  const { unlockMedia } = useMediaContext();
  const { buyRoses } = useBuyRoses();

  const unlockContentSubmit = async (
    messageId: string,
    price: number,
    callback: () => void
  ) => {
    const canUnlock = await canPurchase(price);

    if (!canUnlock) {
      setModalContent(
        <SendRosesModal
          title="Buy Roses"
          description="Select Roses below"
          showRosesOptions
          showInfoGold
          showCards
          tableData={[
            {
              type: "gold",
              label: "Golden Roses",
              values: [4],
            },
            {
              type: "data",
              label: "Roses",
              values: [150, "$485.00"],
            },
            {
              type: "total",
              label: "Total",
              values: [154, "$485.00"],
            },
          ]}
          typeModal="buy"
          buttonText="Buy Roses"
          className="padding-24"
          onClick={() => {
            buyRoses();
            setOpenUseModal(false);
          }}
        />
      );
    } else {
      setModalContent(
        <UnlockContentModal
          messageId={messageId}
          unlockContent={() => {
            unlockContent(messageId, price, callback);
          }}
        />
      );
    }
    setOpenUseModal(true);
  };

  const unlockContent = async (
    messageId: string,
    price: number,
    callback: () => void
  ) => {
    setModalContent(
      <div className="loading-send-file">
        <Loading />
      </div>
    );

    await purchaseMedia(messageId, price);
    setOpenUseModal(false);
    setModalContent(null);

    callback?.();
    unlockMedia(messageId);
  };

  return { unlockContentSubmit };
};

export { useUnlockContent };
