import React from "react";
import "./styles.scss";

interface ListItemCheckBoxProps {
  className?: string;
  iconLeft: string;
  textPrincipal: string;
  textSecondary?: string;
  groupName: string;
  isChecked?: boolean;
  onChange?: (a: any) => void;
  hasSeparator?: boolean;
}

const ListItemCheckBox: React.FC<ListItemCheckBoxProps> = (props) => {
  const {
    className,
    iconLeft,
    textPrincipal,
    textSecondary,
    groupName,
    isChecked,
    onChange,
    hasSeparator,
  } = props;

  return (
    <div id="list-item-checkbox" className={className ?? ""}>
      <label
        className="list-item-checkbox__label"
        htmlFor={`${textPrincipal.split(" ").join("-").toLowerCase()}`}
      >
        {iconLeft ? (
          <div className="list-item-checkbox__icon-wrapper">
            <span className={`icon icon-md icon-gray ${iconLeft}`} />
          </div>
        ) : (
          false
        )}
        <div className="list-item-checkbox__text-wrapper">
          <p className="text-wrapper__principal">{textPrincipal}</p>
          {textSecondary ? (
            <p className="text-wrapper__secondary">{textSecondary}</p>
          ) : (
            false
          )}
        </div>
        <div className="list-item-checkbox__checkbox-wrapper">
          <input
            className="checkbox-wrapper__input"
            type="checkbox"
            name={groupName}
            id={`${textPrincipal.split(" ").join("-").toLowerCase()}`}
            onChange={(e) => {
              onChange && onChange(e.target.checked);
            }}
            checked={isChecked}
          />
          <span className="container-checkbox__checkmark"></span>
        </div>
      </label>
      {hasSeparator ? <div className="separator" /> : false}
    </div>
  );
};

export default ListItemCheckBox;
