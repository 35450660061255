import React, { ReactNode, useContext, useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "../../components/Buttons/ButtonNavigation";
import InputText from "../../components/Inputs/InputText";
import SubText from "../../components/Texts/SubText";
import PhotosPlaceholder from "../../components/PhotosPlaceholder";
import chevronRightIcon from "../../assets/icons/navigationIcons/bx-chevron-right.svg";
import disclaimerIcon from "../../assets/icons/union-gray.svg";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { UserInfoRequest } from "../../types/userInfoRequest";
import SlidUp from "../../components/Modals/SlidUp";
import getUserInfo from "../../api/userInfo/getUserInfo";
import getProfileDetails from "../../api/profileDetails/getProfileDetails";
import getUserPreferences from "../../api/userPreferences/getUserPreferences";
import Button from "../../components/Buttons/Button";
import patchProfileDetails from "../../api/profileDetails/patchProfileDetails";
import { textCapitalize } from "../../utils/textCapitalize";
import postUploadProfilePhotos from "../../api/profileDetails/postUploadProfilePhotos";
import deleteProfilePhotos from "../../api/profileDetails/deleteprofilePhotos";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import { ageCalculate } from "../../utils/dateFormat";
import DropDown from "../../components/Lists/DropDown";
import ProfileIcon from "../../assets/icons/profile-icon-gray.svg";
import AirPlaneIconGray from "../../assets/icons/air-plane-gray.svg";
import LanguageIconGray from "../../assets/icons/language-icon-gray.svg";
import LogoRosesGray from "../../assets/icons/logos/logo-rose-icon-gray.svg";
import patchUserInfo from "../../api/userInfo/patchUserInfo";
import patchUserLocation from "../../api/userInfo/patchUserLocation";
import getUserLocationHistory from "../../api/userInfo/getUserLocationHistory";
import ListItemToggle from "../../components/Lists/ListItemToggle";
import ListItemLink from "../../components/Lists/ListItemLink";
import ListItemRadio from "../../components/Lists/ListItemRadio";
import InputRadio from "../../components/Inputs/InputRadio";
import InputCheckbox from "../../components/Inputs/InputCheckbox";
import patchUserConfidentiality from "../../api/userPhotos/patchUserConfidentiality";
import getUserSelfDetail from "../../api/getUserSelfDetail";
import patchRosesRequest from "../../api/userPreferences/patchRosesRequest";
import postUploadProfileVideo from "../../api/profileDetails/postUploadProfileVideo";
import deleteProfileVideo from "../../api/profileDetails/deleteProfileVideo";
import ImageCircleBorder from "../../components/ImageCircleBorder";
import verifyIcon from "../../assets/icons/verify.svg";
import verifyIconGray from "../../assets/icons/verify-icon-gray.svg";
import { param } from "jquery";
import patchProfilePhoto from "../../api/profileDetails/patchProfilePhoto";
import deleteProfileLocation from "api/userInfo/deleteProfileLocation";

interface ListDetails {
  name: string;
  profileDetailId: number;
  type: DetailsType;
}

interface BodyDataRequest {
  place?: string;
  region?: string;
  country?: string;
  country_code?: string;
  country_code_alpha_3?: string;
  locationId?: string;
  latitude?: number;
  longitude?: number;
  locationHistoryId?: string;
}

type DetailsType =
  | "eye_color"
  | "hair_color"
  | "nationality"
  | "ethnicity"
  | "language"
  | "gender"
  | "age"
  | "height"
  | "weight"
  | "language"
  | "occupation"
  | "marital_status"
  | "net_worth"
  | "zodiac"
  | "race"
  | "body_style"
  | "ocupation"
  | "interests"
  | "genre";

const UserSettingsProfilePage: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>("button1");
  const [photosAlbum, setPhotosAlbum] = useState<(File | null)[] | []>([]);
  const [coverVideo, setCoverVideo] = useState<string>();

  const [isSlidUpVisible, setIsSlidUpVisible] = useState(false);
  const [isSlidUpWriteVisible, setIsSlidUpWriteVisible] = useState(false);

  const [userInfoRequest, setUserInfoRequest] =
    useState<UserInfoRequest | null>(null);
  const [eyeColorList, setEyeColorList] = useState<ListDetails[] | []>([]);
  const [hairColorList, setHairColorList] = useState<ListDetails[] | []>([]);
  const [nationalityList, setNationalityList] = useState<ListDetails[] | []>(
    []
  );
  const [zodiacList, setZodiacList] = useState<string[] | []>([]);
  const [raceList, setRaceList] = useState<string[] | []>([]);
  const [bodySyleList, setBodyStyleList] = useState<string[] | []>([]);
  const [occupationList, setOccupationList] = useState<string[] | []>([]);
  const [interestsList, setInterestsList] = useState<string[] | []>([]);
  const [genreList, setGenreList] = useState<string[] | []>([]);

  const [mockZodiac, setMockZodiac] = useState("cancer");
  const [mockRace, setMockRace] = useState("caucasian");
  const [mockBodyStyle, setMockBodyStyle] = useState(
    textCapitalize("Slim and Elegant")
  );

  const [ethnicityList, setEthnicityList] = useState<ListDetails[] | []>([]);
  const [languageList, setLanguageList] = useState<ListDetails[] | []>([]);
  const [genderList, setGenderList] = useState<string[] | []>([]);
  const [titleSlidUp, setTitleSlidUp] = useState("");
  const [userProfilePicture, setUserProfilePicture] = useState("");
  const [profileBirthday, setProfileBirthday] = useState("");
  const [profileHeight, setProfileHeight] = useState("");
  const [profileWeight, setProfileWeight] = useState("");
  const [openWriteInput, setOpenWriteInput] = useState("");
  const [selectedLocation, setSelectedLocation] = useState<string[]>();
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
  const [modalSlidUpContent, setModalSlidUpContent] =
    useState<ReactNode | null>(null);
  const { setPrincipalScreen, params } = useDeskNavigation();
  const { userInfo } = useUserInfo();

  const [locationList, setLocationList] = useState<BodyDataRequest[]>();
  const [valueSelectedState, setValueSelectedState] = useState<string>("");
  const [toogleConfidentiality, setToogleConfidentiality] = useState();
  const [toogleRosesRequest, setToogleRosesRequest] = useState();
  const [isVerify, setIsVerify] = useState(false);
  const [imageProfileFile, setImageProfileFile] = useState<File>();

  const mockOccupation = ["Model", "Fashion", "Traveler"];
  const mockInterests = ["Photography", "Cooking", "Travel"];
  const mockGenre = ["Fashion", "Travel", "Lifestyle"];

  const removeLocation = async (e: any) => {
    if (locationList) console.log(locationList[e].locationHistoryId);
    locationList?.map(async (item, index) => {
      if (index === e) {
        if (item.locationHistoryId) {
          const response = await deleteProfileLocation(
            userInfo.access_token,
            item.locationHistoryId
          );
          requestLocationHistory();
        }
      }
    });
  };
  const handleSectionChange = (section: string) => {
    setActiveSection(section);
  };

  const updateLanguage = async (languages: string[]) => {
    if (languages) {
      const response = await patchUserInfo(userInfo.access_token, {
        profileLanguages: languages,
      });
    }
  };

  const updateLocation = async (location: string[]) => {
    //TODO - #01 refatorar update
    /*     location[0].location?.map(async (data: any) => {
      if (array[0] === data.place) {
        const response = await patchUserLocation(userInfo.access_token, data);
      }
    }); */

    let data;
    /* update */

    locationList?.map(async (item: any) => {
      const place = [`${item.place}, ${item.country_code}`];
      if (place[0] === location[0]) {
        data = item;
        const response = await patchUserLocation(userInfo.access_token, data);
        requestLocationHistory();
      }
    });
  };

  const textLimit = (text: any) => {
    if (text) {
      const content = text;
      return content.length > 35 ? `${content.substring(0, 25)}...` : content;
    }
  };

  const getUserSelfDetailInit = async () => {
    try {
      const res = await getUserSelfDetail(userInfo.access_token);
      setToogleConfidentiality(res.res.confidentiality);
      setToogleRosesRequest(res.res.blockRequest);
      setIsVerify(res.res.isVerified);
    } catch (error) {
      console.error("Erro ao buscar detalhes do usuário:", error);
    }
  };

  const patchDetails = async (
    key: string,
    value: string | string[] | number
  ) => {
    return await patchProfileDetails(userInfo.access_token, key, value);
  };

  const requestUserInfo = async () => {
    const response = await getUserInfo(userInfo.access_token);

    setUserProfilePicture(response.res?.photos[0]?.url || ProfileIcon);
    setCoverVideo(response.res?.cover?.url);

    setUserInfoRequest(response.res);
    const namesArray = response.res.languages.map(
      (language: ListDetails) => language.name
    );
    setSelectedLanguages(namesArray);
  };

  const applyChangeDetailRequestListed = async (
    detail: string,
    valueSelected: string
  ) => {
    let type =
      detail === "gender"
        ? "gender"
        : detail === "ethnicity"
        ? "profileEthnicity"
        : detail === "eye_color"
        ? "profileEyeColor"
        : detail === "hair_color"
        ? "profileHairColor"
        : detail === "nationality"
        ? "profileNationality"
        : false;

    let value =
      detail === "gender"
        ? valueSelected?.toUpperCase().replaceAll("-", "")
        : valueSelected?.toLowerCase().replaceAll(" ", "_");

    if (type) {
      await patchDetails(type, value);
    }

    setIsSlidUpVisible(false);
  };

  const changeListItemHandler = (toChange: DetailsType, selected: string) => {
    let value = selected;

    const detailsSlide =
      toChange === "gender"
        ? { list: genderList, title: "Gender" }
        : toChange === "ethnicity"
        ? { list: ethnicityList, title: "Ethnicity" }
        : toChange === "eye_color"
        ? { list: eyeColorList, title: "Eye Color" }
        : toChange === "hair_color"
        ? { list: hairColorList, title: "Hair Color" }
        : toChange === "nationality"
        ? { list: nationalityList, title: "Nationality" }
        : toChange === "zodiac"
        ? { list: zodiacList, title: "Zodiac" }
        : toChange === "race"
        ? { list: raceList, title: "Race" }
        : toChange === "body_style"
        ? { list: bodySyleList, title: "Body Style" }
        : { list: [], title: "" };

    setTitleSlidUp(detailsSlide?.title);

    setIsSlidUpVisible(true);
    const selectedValueHandler = (value: any) => {
      setValueSelectedState(value);
    };

    setModalSlidUpContent(
      <>
        <div className="container-radio_radioList">
          <InputRadio
            elements={
              detailsSlide
                ? detailsSlide.list?.map((item: any) => {
                    return {
                      groupName: toChange,
                      inputLabel: `${textCapitalize(item)}`,
                      inputValue: `${textCapitalize(item)}`,
                    };
                  })
                : []
            }
            onChangeValueCallback={(e) => {
              value = e[0];
              selectedValueHandler(e[0]);
            }}
            defaultValueProp={value}
          />
        </div>
        <div className="container-button_radioList">
          <Button
            onClick={() => {
              // todo refatorar - mock temporario
              if (toChange === "zodiac") {
                setMockZodiac(value);
              }

              if (toChange === "race") {
                setMockRace(value);
              }

              if (toChange === "body_style") {
                setMockBodyStyle(value);
              }
              applyChangeDetailRequestListed(toChange, value);
            }}
            buttonStyle="primary"
          >
            Apply
          </Button>
          <Button
            buttonStyle="secondary"
            onClick={() => setIsSlidUpVisible(false)}
          >
            Cancel
          </Button>
        </div>
      </>
    );
  };

  const addListLanguage = async (lang: string[]) => {
    const response = await patchDetails("profileLanguages", lang);

    switch (response.status) {
      case 200:
        setIsSlidUpVisible(false);
        break;
    }
  };

  const addSpecification = async () => {
    let key = openWriteInput === "birthday" ? "birthDate" : openWriteInput;
    let value =
      openWriteInput === "weight"
        ? Number(profileWeight)
        : openWriteInput === "height"
        ? Number(profileHeight)
        : profileBirthday;

    const response = await patchDetails(key, value);
    switch (response.status) {
      case 200:
        setIsSlidUpWriteVisible(false);
        break;
    }
  };

  const addPhotoHandler = async (files: (File | null)[]): Promise<void> => {
    setPhotosAlbum(files);
    const imageToUpload = files.filter(
      (file) => file !== null && !file.name.endsWith(".mp4")
    );

    const videoToUpload = files.find(
      (file) => file !== null && file.name.endsWith(".mp4")
    );

    const photo = imageToUpload[imageToUpload.length - 1];
    if (photo) {
      await postUploadProfilePhotos(userInfo.access_token, photo);
    }

    if (videoToUpload) {
      await postUploadProfileVideo(userInfo.access_token, videoToUpload);
    }

    requestUserInfo();
  };

  const removePhotoHandler = async (id: string) => {
    await deleteProfilePhotos(userInfo.access_token, id);
    requestUserInfo();
  };

  const removeVideoHandler = async (id: string) => {
    await deleteProfileVideo(userInfo.access_token, id);
    requestUserInfo();
  };

  const formatHeight = () => {
    if (userInfoRequest?.height) {
      const feetConverter = Number(userInfoRequest?.height) * 0.0328084;
      const meters = `${
        userInfoRequest?.height.toString()[0]
      },${userInfoRequest?.height.toString().slice(1)}`;

      const feet = `${feetConverter.toString()[0]}’${feetConverter
        .toString()
        .slice(1)
        .substring(1, 3)}”`;

      return `${feet} / ${meters}`;
    } else {
      return "Add";
    }
  };

  const formatWeight = () => {
    if (userInfoRequest?.weight) {
      const poundConverter = Number(userInfoRequest?.weight) * 2.205;

      const kilo = `${userInfoRequest?.weight}kg`;
      const pounds = `${poundConverter.toFixed(2).replace(".", ",")}lb`;
      return `${kilo} / ${pounds}`;
    } else {
      return "Add";
    }
  };

  const selectLanguageHandler = () => {
    let selectedLanguageList: string[] = [];
    const handleCheckboxRightChange = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const value = event.target.value;

      if (event.target.checked) {
        if (!selectedLanguageList.includes(value)) {
          selectedLanguageList = [...selectedLanguageList, value];
        }
      } else {
        selectedLanguageList = selectedLanguageList.filter(
          (lang) => lang !== value
        );
      }
    };

    setTitleSlidUp("Language");
    setModalSlidUpContent(
      <>
        <div className="container-radio_radioList">
          <InputCheckbox
            elements={
              languageList
                ? languageList?.map((item: any) => {
                    return {
                      groupName: item.type,
                      inputLabel: `${textCapitalize(item.name)}`,
                      inputValue: `${item.name}`,
                    };
                  })
                : []
            }
            setInputsValues={setSelectedLanguages}
            inputValues={selectedLanguages}
            defaultValueProp={selectedLanguages}
          />
        </div>

        <Button
          onClick={async () => {
            await updateLanguage(selectedLanguages);
            setIsSlidUpVisible(false);
          }}
          buttonStyle="primary"
        >
          Apply
        </Button>
        <Button
          buttonStyle="secondary"
          onClick={() => setIsSlidUpVisible(false)}
        >
          Cancel
        </Button>
      </>
    );
    setIsSlidUpVisible(true);
  };

  const selectProfileActionHandler = (
    title: string,
    valuesArr: string[],
    valueState: string[],
    funcState: React.Dispatch<React.SetStateAction<[] | string[]>>,
    valueDefault?: string[]
  ) => {
    setTitleSlidUp(title);
    setModalSlidUpContent(
      <>
        <div className="container-radio_radioList">
          <InputCheckbox
            elements={
              valuesArr
                ? valuesArr?.map((item: any) => {
                    return {
                      groupName: item,
                      inputLabel: `${textCapitalize(item)}`,
                      inputValue: `${item}`,
                    };
                  })
                : []
            }
            inputValues={valueState}
            setInputsValues={funcState}
            defaultValueProp={valueDefault}
          />
        </div>

        <Button
          onClick={async () => {
            // await updateLanguage(selectedLanguages);
            setIsSlidUpVisible(false);
          }}
          buttonStyle="primary"
        >
          Apply
        </Button>
        <Button
          buttonStyle="secondary"
          onClick={() => setIsSlidUpVisible(false)}
        >
          Cancel
        </Button>
      </>
    );
    setIsSlidUpVisible(true);
  };

  const personalInfoData = [
    {
      labelContent: "Profile",
      valueContent: textCapitalize(userInfo.display_name) || "Add",
      clickAction: () => setPrincipalScreen("/user-settings-edit-profile"),
    },
    {
      labelContent: "Bio",
      valueContent: textCapitalize(userInfoRequest?.bio) || "Add",
      clickAction: () => setPrincipalScreen("/user-settings-edit-bio"),
    },
    {
      labelContent: "Gender",
      valueContent: textCapitalize(userInfoRequest?.gender) || "Add",
      clickAction: () => {
        setValueSelectedState(textCapitalize(userInfoRequest?.gender));
        changeListItemHandler(
          "gender",
          textCapitalize(userInfoRequest?.gender)
        );
      },
    },
    {
      labelContent: "Age",
      valueContent: ageCalculate(userInfoRequest?.birthDate) || "Add",
      clickAction: () => {
        setIsSlidUpWriteVisible(true);
        setOpenWriteInput("birthday");
      },
    },
    {
      labelContent: "Ethnicity",
      valueContent: textCapitalize(userInfoRequest?.ethnicity?.name) || "Add",
      clickAction: () => {
        setValueSelectedState(textCapitalize(userInfoRequest?.ethnicity?.name));
        changeListItemHandler(
          "ethnicity",
          textCapitalize(userInfoRequest?.ethnicity?.name)
        );
      },
    },
    {
      labelContent: "Height",
      valueContent: formatHeight() || "Add",
      clickAction: () => {
        setIsSlidUpWriteVisible(true);
        setOpenWriteInput("height");
      },
    },
    {
      labelContent: "Weight",
      valueContent: formatWeight() || "Add",
      clickAction: () => {
        setIsSlidUpWriteVisible(true);
        setOpenWriteInput("weight");
      },
    },
    {
      labelContent: "Language",
      valueContent: userInfoRequest?.languages?.length
        ? userInfoRequest.languages
            .map((lang) => textCapitalize(lang.name))
            .join(", ")
        : "Add",
      clickAction: selectLanguageHandler,
    },
    {
      labelContent: "Eye color",
      valueContent: textCapitalize(userInfoRequest?.eyeColor?.name) || "Add",
      clickAction: () => {
        setValueSelectedState(textCapitalize(userInfoRequest?.eyeColor?.name));
        changeListItemHandler(
          "eye_color",
          textCapitalize(userInfoRequest?.eyeColor?.name)
        );
      },
    },
    {
      labelContent: "Hair color",
      valueContent: textCapitalize(userInfoRequest?.hairColor?.name) || "Add",
      clickAction: () => {
        setValueSelectedState(textCapitalize(userInfoRequest?.hairColor?.name));
        changeListItemHandler(
          "hair_color",
          textCapitalize(userInfoRequest?.hairColor?.name)
        );
      },
    },

    {
      labelContent: "Nationality",
      valueContent: textCapitalize(userInfoRequest?.nationality?.name) || "Add",
      clickAction: () => {
        setValueSelectedState(
          textCapitalize(userInfoRequest?.nationality?.name)
        );
        changeListItemHandler(
          "nationality",
          textCapitalize(userInfoRequest?.nationality?.name)
        );
      },
    },
    {
      labelContent: "Occupation",
      valueContent: mockOccupation.join(", ") || "Add",
      clickAction: () => {
        selectProfileActionHandler(
          "Occupation",
          mockOccupation,
          occupationList,
          setOccupationList
        );
      },
    },
    {
      labelContent: "Interests",
      valueContent: mockInterests.join(", ") || "Add",
      clickAction: () => {
        selectProfileActionHandler(
          "Interests",
          mockInterests,
          interestsList,
          setInterestsList
        );
      },
    },
    {
      labelContent: "Zodiac",
      valueContent: textCapitalize(mockZodiac) || "Add",
      clickAction: () => {
        setValueSelectedState(mockZodiac);
        changeListItemHandler("zodiac", textCapitalize(mockZodiac));
      },
    },
    {
      labelContent: "Race",
      valueContent: textCapitalize(mockRace) || "Add",
      clickAction: () => {
        setValueSelectedState(mockRace);
        changeListItemHandler("race", textCapitalize(mockRace));
      },
    },
    {
      labelContent: "Body Style",
      valueContent: textCapitalize(mockBodyStyle) || "Add",
      clickAction: () => {
        setMockBodyStyle(textCapitalize(mockBodyStyle));
        setValueSelectedState(textCapitalize(mockBodyStyle));
        changeListItemHandler("body_style", textCapitalize(mockBodyStyle));
      },
    },
    {
      labelContent: "Genre",
      valueContent: mockGenre.join(", ") || "Add",
      clickAction: () => {
        selectProfileActionHandler("Genre", mockGenre, genreList, setGenreList);
      },
    },
  ];

  const preferenceListData = [
    {
      showIconLeft: false,
      iconLeftContent: "",
      showIconBorderLeft: false,
      showLabel: true,
      labelContent: "Connect & Chat",
      showDescription: false,
      descriptionContent: "",
      showToggle: false,
      showIconRight: true,
      iconRightContent: chevronRightIcon,
      showValue: true,
      valueContent: "Free",
    },
    {
      showIconLeft: false,
      iconLeftContent: "",
      showIconBorderLeft: false,
      showLabel: true,
      labelContent: "Photos",
      showDescription: false,
      descriptionContent: "",
      showToggle: false,
      showIconRight: true,
      iconRightContent: chevronRightIcon,
      showValue: true,
      valueContent: "50",
    },
    {
      showIconLeft: false,
      iconLeftContent: "",
      showIconBorderLeft: false,
      showLabel: true,
      labelContent: "Packs",
      showDescription: false,
      descriptionContent: "",
      showToggle: false,
      showIconRight: true,
      iconRightContent: chevronRightIcon,
      showValue: true,
      valueContent: "100",
    },
  ];

  const profilePictureChangeHandler = (file: File) => {
    setImageProfileFile(file);
  };

  const preserveConfidentiallyHandler = async (event: boolean) => {
    const res = await patchUserConfidentiality(userInfo.access_token, {
      confidentiality: event,
    });

    requestUserInfo();
  };

  const smartPhotoHandler = (event: boolean) => {};

  const acceptRosesFromEveryoneHandler = async (event: boolean) => {
    const res = await patchRosesRequest(userInfo.access_token, {
      blockRequest: event,
    });
  };

  const triggerFileInput = () => {
    document.getElementById("fileInput")?.click();
  };

  const buttonsGroup: ButtonNavigationInfo[] = [
    { text: "Photos", clickAction: () => setActiveSection("button1"), id: 1 },
    { text: "Info", clickAction: () => setActiveSection("button2"), id: 2 },
    {
      text: "Preference",
      clickAction: () => setActiveSection("button3"),
      id: 3,
    },
  ];

  const updatePhotoHandler = async (file: {
    fileId: string;
    position: number;
  }) => {
    const res = await patchProfilePhoto(
      userInfo.access_token,
      file.fileId,
      file.position
    );

    if (res?.status === 200) {
      requestUserInfo();
    }
  };

  const requestLocationHistory = async () => {
    const response = await getUserLocationHistory(userInfo.access_token);

    if (response.res.currentLocation) {
      const currentLocation = [
        `${response.res.currentLocation.place}, ${response.res.currentLocation.country_code}`,
      ];
      setSelectedLocation(currentLocation);
    }

    if (response.res.locationHistory) {
      const history = response.res.locationHistory.map((item: any) => ({
        ...item.location,
        locationHistoryId: item.locationHistoryId,
      }));
      setLocationList(
        history
          .filter(
            (item: any, index: any, array: any) =>
              index ===
              array.findIndex((i: any) => i.locationId === item.locationId)
          )
          .map((item: ListDetails) => item)
      );
    }
  };

  useEffect(() => {
    const requestProfileDetails = async () => {
      const response = await getProfileDetails(userInfo.access_token);

      setEyeColorList(
        response.res
          .filter((detail: ListDetails) => detail.type === "eye_color")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setHairColorList(
        response.res
          .filter((detail: ListDetails) => detail.type === "hair_color")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setNationalityList(
        response.res
          .filter((detail: ListDetails) => detail.type === "nationality")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setEthnicityList(
        response.res
          .filter((detail: ListDetails) => detail.type === "ethnicity")
          .map((item: ListDetails) => textCapitalize(item.name))
      );

      setLanguageList(
        response?.res?.filter(
          (detail: ListDetails) => detail.type === "language"
        )
      );

      setGenderList(["Man", "Woman", "Non-binary", "Trans"]);

      setZodiacList([
        "Aries",
        "Taurus",
        "Gemini",
        "Cancer",
        "Leo",
        "Virgo",
        "Libra",
        "Scorpio",
        "Sagittarius",
        "Capricorn",
        "Aquarius",
        "Pisces",
      ]);

      setRaceList([
        "Caucasian",
        "Black",
        "Asian",
        "Latino",
        "Indigenous",
        "Multiracial",
      ]);

      setBodyStyleList([
        "Slim And Elegant",
        "Athletic and Toned",
        "Curvy and Voluptuous",
        "Petite and Compact",
        "Tall and Lean",
        "Full and Balanced",
        "Stocky and Sturdy",
        "Broad and Muscular",
        "Slender and Graceful",
        "Plus-Size and Confident",
        "Average and Proportional",
        "Hourglass-Shaped",
        "Rectangle-Shaped",
        "Pear-Shaped",
        "Apple-Shaped",
      ]);

      setInterestsList([
        "Fashion",
        "Travel",
        "Photography",
        "Cooking",
        "Luxury Living",
      ]);

      setOccupationList(["Model", "Fashion", "Influencer", "Traveler"]);

      setGenreList(["Fashion", "Travel", "Lifestyle"]);
    };

    const requestPreferences = async () => {
      const response = await getUserPreferences(userInfo.access_token);
    };

    requestLocationHistory();
    requestPreferences();
    requestProfileDetails();
    getUserSelfDetailInit();
  }, [userInfo]);

  useEffect(() => {
    requestUserInfo();
  }, [isSlidUpVisible, isSlidUpWriteVisible, valueSelectedState]);

  useEffect(() => {
    if (!params.btn) {
      setActiveSection("button1");
    } else {
      setActiveSection(params.btn);
    }
  }, [params.btn]);

  return (
    <div className="user-settings-profile">
      <PageTitle
        title={"Profile"}
        subtitle={"Configuration"}
        onBackClick={() => setPrincipalScreen("/user-settings", { btn: "" })}
        hasCloseIcon={false}
        className="margin-hor-16"
      />
      <ButtonNavigation
        buttons={buttonsGroup}
        activatedButton={Number(activeSection?.replace("button", ""))}
        className="margin-bottom-24"
      />

      {activeSection === "button1" && (
        <section
          className="album-photos-quantity-list-container margin-hor-24
           margin-bottom-24"
        >
          {activeSection === "button1" && (
            <>
              <div className="profile-picture-container">
                <ImageCircleBorder
                  centerImage={
                    imageProfileFile
                      ? URL.createObjectURL(imageProfileFile as File)
                      : userProfilePicture
                  }
                  centerImageAlt="user profile"
                  size={56}
                />

                <Button buttonStyle="quintenary" onClick={triggerFileInput}>
                  Profile Picture
                </Button>
                <input
                  id="fileInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    if (e.target.files) {
                      let file = e.target.files[0];
                      profilePictureChangeHandler(file);
                    }
                  }}
                />
              </div>
            </>
          )}
          <div className="user-settings-photo-container ">
            <PhotosPlaceholder
              updatePhotoCallback={updatePhotoHandler}
              quantityFiles={9}
              photoAlbumCallback={addPhotoHandler}
              images={userInfoRequest?.photos}
              video={coverVideo}
              removeFileCallback={removePhotoHandler}
              removeVideo={() =>
                removeVideoHandler(coverVideo ? coverVideo : "")
              }
            />
            <p className="album-photos-quantity no-margin">
              {userInfoRequest?.cover
                ? 1 + userInfoRequest?.photos?.length
                : userInfoRequest?.photos?.length}{" "}
              / 9 photos
            </p>
          </div>

          <ListItemToggle
            toggleCallBack={preserveConfidentiallyHandler}
            hasSeparator
            highlightText="Preserve my Confidentiality"
            imageLeft={disclaimerIcon}
            isImageCircle
            simpleText="Conceal my facial identity"
            toggleInitialState={toogleConfidentiality}
          />

          <ListItemToggle
            toggleCallBack={smartPhotoHandler}
            hasSeparator
            highlightText="Smart Photo"
            imageLeft={disclaimerIcon}
            isImageCircle
            simpleText="Chooses best photo for you"
          />
          <SubText
            children={`We welcome sensual expression that's artistic and 
               sophisticated. Please avoid explicit content to maintain the 
               respectful and tasteful spirit of our community.`}
            icon={disclaimerIcon}
          />
        </section>
      )}

      {activeSection === "button2" && (
        <>
          <div className="list-container-personal-info ">
            {personalInfoData.map((item, index) => (
              <ListItemLink
                key={index}
                hasSeparator={personalInfoData.length > index + 1}
                hasArrowRight
                highlightText={item.labelContent}
                onClick={item.clickAction}
                rightNodeContent={
                  <>
                    <p className="text-detail">
                      {textLimit(item.valueContent)}
                      {index === 0 && isVerify && <img src={verifyIcon}></img>}
                      {index === 0 && !isVerify && (
                        <img src={verifyIconGray}></img>
                      )}
                    </p>
                  </>
                }
              />
            ))}
          </div>
        </>
      )}

      {activeSection === "button3" && (
        <section className="user-settings-preferences margin-hor-24">
          <DropDown
            inputValueSet={selectedLocation}
            className="margin-bottom-24"
            setValueCallBack={(e) => {
              setSelectedLocation(e);
              // todo - #02 refatorar
              updateLocation(e);
            }}
            defaultValueProp={selectedLocation}
            placeholder="Select country"
            iconLeft={AirPlaneIconGray}
            options={
              locationList
                ? locationList.map((item: any) => {
                    return {
                      groupName: "country",
                      inputLabel: `${item.place}, ${item.country_code}`,
                      inputValue: `${item.place}, ${item.country_code}`,
                    };
                  })
                : []
            }
            textButton="Add location"
            label="Location"
            dropType="radio"
            buttonCallBack={() => setPrincipalScreen("/user-settings-location")}
            iconRight={true}
            actionIconRight={removeLocation}
          />

          <DropDown
            inputValueSet={selectedLanguages ? selectedLanguages : []}
            textButton="Add language"
            dropType="checkbox"
            label="Language"
            setValueCallBack={(e) => {
              setSelectedLanguages(e);
              updateLanguage(e);
            }}
            defaultValueProp={selectedLanguages}
            placeholder="Select language"
            iconLeft={LanguageIconGray}
            options={
              languageList
                ? languageList.map((language) => {
                    return {
                      groupName: "language",
                      inputLabel: `${language.name}`,
                      inputValue: `${language.name}`,
                    };
                  })
                : []
            }
            className="margin-vert-24"
            buttonCallBack={() =>
              setPrincipalScreen("/user-settings-language-profile")
            }
          />

          <div className="list-container margin-vert-24">
            <div className="list-container-settings">
              {preferenceListData.map((item, index, arr) => (
                <ListItemLink
                  key={index}
                  onClick={() => {}}
                  isImageCircle
                  hasArrowRight
                  hasSeparator={arr.length > index + 1}
                  highlightText={item.labelContent}
                  rightNodeContent={
                    <p className="text-detail">{item.valueContent}</p>
                  }
                />
              ))}
            </div>
          </div>
          <Button buttonStyle="tertiary">Add Content</Button>

          <ListItemToggle
            toggleCallBack={acceptRosesFromEveryoneHandler}
            highlightText="Accept roses requests from everyone"
            imageLeft={LogoRosesGray}
            isImageCircle
            simpleText="Allow Requests"
            className="margin-vert-16"
            toggleInitialState={toogleRosesRequest}
          />
        </section>
      )}

      <SlidUp
        classNameContainer="user-settings-slid-up"
        classNameChildren="list-item-slid-up "
        isOpen={isSlidUpVisible}
        title={titleSlidUp}
        closeArea={() => {
          setIsSlidUpVisible(false);
        }}
      >
        {modalSlidUpContent}
      </SlidUp>

      <SlidUp
        classNameContainer="user-settings-slid-up"
        classNameChildren="list-item-slid-up"
        isOpen={isSlidUpWriteVisible}
        title={openWriteInput}
        closeArea={() => {
          setIsSlidUpWriteVisible(false);
        }}
      >
        {openWriteInput === "birthday" ? (
          <InputText
            inputType="text"
            value={profileBirthday}
            onChangeInput={(e) => setProfileBirthday(e)}
            placeholder="YYYY/MM/DD"
            mask="9999/99/99"
          />
        ) : openWriteInput === "weight" ? (
          <InputText
            inputType="text"
            value={profileWeight}
            onChangeInput={(e) => setProfileWeight(e)}
            placeholder="Weight"
            mask="99.99"
          />
        ) : openWriteInput === "height" ? (
          <InputText
            value={profileHeight}
            inputType="text"
            onChangeInput={(e) => setProfileHeight(e)}
            placeholder="Height"
            mask="999.9"
          />
        ) : (
          ""
        )}
        <Button onClick={() => addSpecification()} buttonStyle="primary">
          Apply
        </Button>
        <Button
          buttonStyle="secondary"
          onClick={() => setIsSlidUpWriteVisible(false)}
        >
          Cancel
        </Button>
      </SlidUp>
    </div>
  );
};

export default UserSettingsProfilePage;
