import React, { forwardRef, ReactNode, useState } from "react";
import "./styles.scss";
import Toggle from "../../Toggle/SimpleToggle";
import Category from "../../Category";
import Range from "../../Ranges/Range";
import InputText from "../../Inputs/InputText";
import sphereIconGray from "../../../assets/icons/sphere-item-icon-gray.svg";

interface ListItemSecondaryProps {
  showSphereIcon?: boolean;
  showIconLeft?: boolean;
  showIconBorderLeft?: boolean;
  showCheckboxLeft?: boolean;
  showRadioLeft?: boolean;
  showIconMain?: boolean;
  showLabel?: boolean;
  showDescription?: boolean;
  showSlider?: boolean;
  showValue?: boolean;
  showInfo?: boolean;
  showCategory?: boolean;
  showRadioRight?: boolean;
  showToggle?: boolean;
  showCheckboxRight?: boolean;
  showCounter?: boolean;
  showIconRight?: boolean;
  showSeparator?: boolean;
  iconLeftContent?: string;
  iconMainContent?: string;
  iconRightContent?: string;
  labelContent?: string;
  descriptionContent?: string;
  valueContent?: string | number;
  infoContent?: string;
  categoryContent?: string;
  showInput?: boolean;
  onChangeInput?: (a: string) => void;
  valueInput?: string;
  contactList?: boolean;
  initialsContact?: string;
  checkBoxLeftHandler?: (a: string) => void;
  checkBoxRightHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  radioLeftValue?: string;
  radioLeftGroupName?: string;
  radioRightValue?: string;
  radioRightGroupName?: string;
  onChangeRightRadioCallback?: (a: string) => void;
  onChangeLeftRadioCallback?: (a: string) => void;
  onToggleChange?: (isToggled: boolean) => void;
  clickAction?: () => void;
  className?: string;
  minSliderValue?: number;
  maxSliderValue?: number;
  callBackSlider?: (value: string) => void;
  sliderStep?: number;
  checkboxRightValue?: string;
  radioRightChecked?: boolean;
  setCheckRightSelect?: (a: string[]) => void;
  nodeSectionEndTextContainer?: ReactNode;
  rightRadioChecked?: boolean;
  actionIconRight?: () => void;
}
const ListItemSecondary = forwardRef<HTMLDivElement, ListItemSecondaryProps>(
  (props, ref) => {
    const {
      showSphereIcon = false,
      showIconLeft = false,
      showIconBorderLeft = false,
      showCheckboxLeft = false,
      showRadioLeft = false,
      showIconMain = false,
      showLabel = false,
      showDescription = false,
      showSlider = false,
      showValue = false,
      showInfo = false,
      showCategory = false,
      showRadioRight = false,
      showToggle = false,
      showCheckboxRight = false,
      showCounter = false,
      showIconRight = false,
      showSeparator = false,
      iconLeftContent,
      iconMainContent,
      iconRightContent,
      labelContent = "Label",
      descriptionContent = "Description",
      valueContent = "Value",
      infoContent = "Info",
      categoryContent = "Category",
      showInput = false,
      onChangeInput,
      valueInput,
      contactList,
      initialsContact,
      checkBoxLeftHandler,
      checkBoxRightHandler,
      radioLeftValue,
      radioLeftGroupName,
      radioRightValue,
      radioRightGroupName,
      onChangeRightRadioCallback,
      onChangeLeftRadioCallback,
      onToggleChange,
      clickAction,
      className,
      minSliderValue,
      maxSliderValue,
      callBackSlider,
      sliderStep,
      checkboxRightValue,
      radioRightChecked,
      setCheckRightSelect,
      nodeSectionEndTextContainer,
      rightRadioChecked,
      actionIconRight,
    } = props;

    const [toggleState, setToggleState] = useState(false);

    const handleToggleChange = (newState: boolean) => {
      setToggleState(newState);
      if (onToggleChange) {
        onToggleChange(newState);
      }
    };

    return (
      <div
        ref={ref}
        className={`list-item-secondary-container ${
          className ? className : ""
        }`}
        onClick={clickAction}
      >
        {showSphereIcon && (
          <img
            className="sphere-icon margin-right-16 padding-8"
            src={sphereIconGray}
            alt=""
          />
        )}
        {showIconLeft && (
          <div
            className={`icon-left margin-right-16 ${
              showIconBorderLeft ? "icon-left-border" : ""
            }`}
          >
            <img src={iconLeftContent} alt="" />
          </div>
        )}
        {showCheckboxLeft && checkBoxLeftHandler && (
          <label className="checkbox-left">
            <input
              type="checkbox"
              onChange={(e) => checkBoxLeftHandler(e.target.value)}
            />
            <span className="checkmark"></span>
          </label>
        )}
        {showRadioLeft && onChangeLeftRadioCallback && (
          <div className="radio-container">
            <input
              type="radio"
              className="list-radio"
              value={radioLeftValue}
              name={radioLeftGroupName}
              onChange={(e) => onChangeLeftRadioCallback(e.target.value)}
            />
          </div>
        )}
        {contactList && (
          <div className="initials-contact margin-right-16">
            <p>{initialsContact}</p>
          </div>
        )}

        {showIconMain && (
          <div className="icon-main">
            <img src={iconMainContent} alt="" />
          </div>
        )}

        <div className="text-container">
          {showLabel && <label>{labelContent}</label>}
          {showDescription && (
            <p className="description">{descriptionContent} </p>
          )}
          {nodeSectionEndTextContainer && (
            <div className="node-section-end-text-container">
              {nodeSectionEndTextContainer}
            </div>
          )}
        </div>

        {showSlider && minSliderValue && maxSliderValue && callBackSlider && (
          <Range
            min={minSliderValue}
            max={maxSliderValue}
            rangeCallback={callBackSlider}
            step={sliderStep}
          />
        )}
        {showValue && (
          <div className="value margin-left-16">{valueContent}</div>
        )}
        {showInfo && <div className="info">{infoContent}</div>}
        {showCategory && <Category children={categoryContent} />}

        {showRadioRight && onChangeRightRadioCallback && (
          <label className="radio-container" htmlFor="radio-container__input">
            <input
              id="radio-container__input"
              type="radio"
              className="list-radio"
              value={radioRightValue}
              name={radioRightGroupName}
              checked={rightRadioChecked}
              onChange={(e) => onChangeRightRadioCallback(e.target.value)}
            />
          </label>
        )}
        {showToggle && <Toggle onChange={handleToggleChange} />}
        {showCheckboxRight && checkBoxRightHandler && (
          <label className="checkbox-right">
            <input
              type="checkbox"
              onChange={checkBoxRightHandler}
              value={checkboxRightValue}
            />
            <span className="checkmark"></span>
          </label>
        )}

        {showInput && onChangeInput && (
          <InputText
            textAlign="right"
            onChangeInput={onChangeInput}
            value={valueInput || ""}
            inputType="text"
          />
        )}
        {showCounter && <div className="counter">Counter</div>}
        {showIconRight && !actionIconRight && (
          <div className="icon-right margin-left-16">
            <img src={iconRightContent} alt="" />
          </div>
        )}

        {showIconRight && actionIconRight && (
          <div className="icon-right margin-left-16" onClick={actionIconRight}>
            <img src={iconRightContent} alt="" />
          </div>
        )}
        {showSeparator && <div className="separator"></div>}
      </div>
    );
  }
);

export default ListItemSecondary;
