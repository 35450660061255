import React, { useEffect, useState } from "react";
import "./styles.scss";

import rosesLogoPink from "../../assets/icons/logos/logo-fill-pink.svg";

import PageTitle from "../../components/PageTitles";
import InputText from "../../components/Inputs/InputText";
import Button from "../../components/Buttons/Button";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useModal } from "../../hooks/useModal";

import deleteCreatorSettingsGoalDeactivate from "../../api/creatorSettings/deleteCreatorSettingsGoalDeactivate";

import getCreatorSettingsGoalDonors from "../../api/creatorSettings/getCreatorSettingsGoalDonors";
import patchCreatorSettingsGoal from "../../api/creatorSettings/patchCreatorSettingsGoal";

import { dateFormat } from "../../utils/dateFormat";

import { IDonor } from "../../types/goal";

const UserSettingsEditGoal: React.FC = ({
  className,
}: {
  className?: string;
}) => {
  const { setPrincipalScreen, params } = useDeskNavigation();
  const { setOpenUseModal, setModalContent } = useModal();
  const { userInfo } = useUserInfo();
  const token = userInfo.access_token;
  const { goalId, isActive, target, title } = JSON.parse(params.goal);

  const [titleGoal, setTitleGoal] = useState(title);
  const [targetGoal, setTargetGoal] = useState<string | number>(target);
  const [listDonors, setListDonors] = useState<IDonor[]>([]);

  useEffect(() => {
    getListDonors(goalId);
  }, []);

  const patchGoal = async () => {
    try {
      if (targetGoal < target)
        throw new Error("Your goal cannot be lower than the previous one");

      const res = await patchCreatorSettingsGoal({
        token,
        title: titleGoal,
        target: Number(targetGoal),
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getListDonors = async (goalId: string) => {
    try {
      const { res } = await getCreatorSettingsGoalDonors(token, goalId);
      setListDonors(res);
    } catch (error) {
      console.error("Erro ao criar meta:", error);
    }
  };

  const deleteGoal = async (goalId: string) => {
    try {
      const res = await deleteCreatorSettingsGoalDeactivate(token, goalId);
    } catch (error) {
      console.error("Erro ao criar meta:", error);
    }
  };

  const disableGoal = async (goalId: string) => {
    setModalContent(
      <div className="goals__modal padding-16">
        <div className="goals__modal-top">
          <img
            className="goals__modal-logo"
            src={rosesLogoPink}
            alt="roses logo icon"
          />
          <h2 className="goals__modal-title">
            Do would like disable this Goal
          </h2>
        </div>
        <Button
          buttonStyle={"primary"}
          onClick={() => {
            try {
              const res = deleteGoal(goalId);
              setOpenUseModal(false);
            } catch (error) {
              console.log("Erroe", error);
            }
          }}
        >
          Yes
        </Button>
        <Button
          buttonStyle={"secondary"}
          onClick={() => setOpenUseModal(false)}
        >
          No
        </Button>
      </div>
    );
  };

  return (
    <div className={`user-settings-edit-goals ${className ?? ""}`}>
      <PageTitle
        title={"Edit Goals"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        onBackClick={() => {
          setPrincipalScreen("/user-settings-goals");
        }}
        className="margin-hor-16"
      />

      <div
        className={`goals margin-hor-24 ${!isActive ? "goals--inactive" : ""}`}
      >
        <InputText
          label="Title: "
          value={titleGoal}
          inputType="text"
          placeholder="Buy a house"
          textTransform="none"
          className="no-margin"
          onChangeInput={(e) => setTitleGoal(e)}
        />

        <InputText
          label="Target: "
          value={String(targetGoal)}
          inputType="text"
          placeholder="Buy a house"
          textTransform="none"
          className="no-margin"
          onChangeInput={(e) => setTargetGoal(parseFloat(e))}
        />

        <Button
          buttonStyle={"primary"}
          className="margin-top-8"
          onClick={isActive && patchGoal}
          disabled={!isActive}
        >
          Edit Goal
        </Button>

        <Button
          buttonStyle={"secondary"}
          className="margin-top-8"
          onClick={() => {
            if (!isActive) return;

            setOpenUseModal(true);
            disableGoal(goalId);
          }}
          disabled={!isActive}
        >
          Disable Goal
        </Button>
      </div>

      <div className="margin-hor-24 margin-top-24">
        <h2 className="goals__history-title margin-bottom-16 no-margin">
          Donor History:
        </h2>
        {listDonors.length !== 0 ? (
          listDonors.map((donor) => {
            const {
              donationId,
              amount,
              createdAt,
              userSender: { displayname },
            } = donor;
            return (
              <div key={donationId} className="goals__history padding-top-8">
                <div className="goals__top">
                  <h3 className="goals__history-title no-margin">
                    {displayname}
                  </h3>
                </div>
                <div className="goals__bottom">
                  <p className="goals__amount">Amount: {amount}</p>
                  <p className="goals__objective">{dateFormat(createdAt)}</p>
                </div>
              </div>
            );
          })
        ) : (
          <p className="goals__donation">You don't have donations yet!</p>
        )}
      </div>
    </div>
  );
};

export default UserSettingsEditGoal;
