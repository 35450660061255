import React, { createContext, useState, ReactNode, useContext } from "react";

interface ModalContextSecondaryProps {
  openUseModalSecondary: boolean;
  setOpenUseModalSecondary: (open: boolean) => void;
  setModalSecondaryContent: (content: ReactNode) => void;
}

const ModalSecondaryContext = createContext<
  ModalContextSecondaryProps | undefined
>(undefined);

export const useModalSecondary = () => {
  const context = useContext(ModalSecondaryContext);
  if (!context) {
    throw new Error(
      "useModalSecondary must be used within a ModalSecondaryProvider"
    );
  }
  return context;
};

export const ModalSecondaryProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [openUseModalSecondary, setOpenUseModalSecondary] = useState(false);
  const [modalSecondaryContent, setModalSecondaryContent] =
    useState<ReactNode>(null);

  return (
    <ModalSecondaryContext.Provider
      value={{
        openUseModalSecondary,
        setOpenUseModalSecondary,
        setModalSecondaryContent,
      }}
    >
      {children}
      {openUseModalSecondary && (
        <div className="modal-overlay">
          <div className="modal-content">{modalSecondaryContent}</div>
        </div>
      )}
    </ModalSecondaryContext.Provider>
  );
};
