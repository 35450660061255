import "./styles.scss";
import { useEffect, useRef, useState } from "react";
import ChatMessageNew from "../../components/Chat/ChatMessage/ChatMessageNew";
import { useUserInfo } from "../../hooks/userInfo";
import { useWebSocket } from "../../contexts/webSocketContext";
import { IReadMessage, Message } from "../../types/chatInfo";

const params = {
  chatId: "199e3364-9af8-4723-b1ff-b6e111d2f2b1",
  userId: "958c0687-ed4e-4af2-9cd4-f4b055bad55e",
  isLocked: "false",
};

function updateStateIfChanged<T>(
  setState: React.Dispatch<React.SetStateAction<T>>,
  newState: T
) {
  setState((prevState) => {
    if (JSON.stringify(prevState) !== JSON.stringify(newState)) {
      return newState;
    }
    return prevState;
  });
}

const ObserverTest2: React.FC = () => {
  const { userInfo } = useUserInfo();
  const { addChatListener, getMessages } = useWebSocket();
  const [messages, setMessages] = useState<Message[]>([]);

  useEffect(() => {
    const initialize = async () => {
      if (!params.chatId) return;

      const messageParams = {
        chatId: params.chatId,
        take: 15,
      };
      getMessages(messageParams, (messages: Message[]) => {
        const newMessages = messages.filter(
          (msg) => msg.chatId === params.chatId
        );

        updateStateIfChanged(setMessages, newMessages);
      });
    };
    initialize();
  }, [params.chatId, getMessages]);

  const messagesContainer = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<(HTMLDivElement | null)[]>([]);

  const options = {
    root: messagesContainer.current,
    rootMargin: "0px",
    threshold: 1.0,
  };

  useEffect(() => {
    if (itemsRef.current.length === 0) return;

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const beholded = entry.target as HTMLDivElement;
          const messageId = beholded.id;
          const messageIndex = messages.findIndex(
            (msg) => msg.messageId === messageId
          );

          if (messageIndex !== -1 && !messages[messageIndex].isRead) {
            const updatedMessages = [...messages];
            updatedMessages[messageIndex].isRead = true;

            // Atualiza o estado apenas se houver alteração
            updateStateIfChanged(setMessages, updatedMessages);
          }
          observer.unobserve(entry.target);
        }
      });
    }, options);

    itemsRef.current.forEach((item) => {
      if (item) {
        observer.observe(item);

        const rect = item.getBoundingClientRect();
        if (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (messagesContainer.current?.offsetHeight ?? window.innerHeight) &&
          rect.right <=
            (messagesContainer.current?.offsetWidth ?? window.innerWidth)
        ) {
          const messageId = item.id;
          const messageIndex = messages.findIndex(
            (msg) => msg.messageId === messageId
          );

          if (messageIndex !== -1 && !messages[messageIndex].isRead) {
            const updatedMessages = [...messages];
            updatedMessages[messageIndex].isRead = true;

            // Atualiza o estado apenas se houver alteração
            updateStateIfChanged(setMessages, updatedMessages);
          }
        }
      }
    });

    return () => observer.disconnect();
  }, [messages]);

  const [readMessagesIds, setReadMessagesIds] = useState<string[]>([]);

  useEffect(() => {
    addChatListener(readMessageListener);
  }, []);

  const readMessageListener = (event: IReadMessage) => {
    if (params.userId) {
      if (event.eventType === "MESSAGE_READ") {
        setReadMessagesIds((prev) => [...prev, event.payload.messageId]);
      }
    }
  };

  return (
    <div className="observer-test">
      <div className="observer-test__wrapper" ref={messagesContainer}>
        {messages.map((message, index) => {
          return (
            <ChatMessageNew
              key={message.messageId}
              id={message.messageId}
              ref={(el) => {
                if (el) {
                  itemsRef.current[index] = el;
                }
              }}
              side={userInfo.user_id === message.userId ? "right" : "left"}
              isMessageRead={message.isRead}
              message={message.content}
              messageSettings={
                message.messageSettings || {
                  isPaid: false,
                  messageId: message.messageId,
                }
              }
              fileSended={message.files}
              unlockFileCallback={(id) =>
                console.log(`Desbloqueando arquivo ${id}`)
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default ObserverTest2;
