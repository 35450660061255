import React, { useCallback, useEffect, useRef, useState } from "react";
import "./styles.scss";
import { useModal } from "../../hooks/useModal";
import { useWindowWidthSize } from "../../hooks/useWindowWidthSize";
import { usePublishContent } from "../../hooks/usePublishContent";
import { useWebSocket } from "../../contexts/webSocketContext";
import { Chat } from "../../types/chatInfo";
import { useUserInfo } from "../../hooks/userInfo";
import LoadingPage from "../../components/LoadingPage";
import Toast from "../../components/Toast";
import { IToast } from "../../types/toast";
import PreviewMediaCardsList from "../../components/Cards/PreviewMediaCardsList";
import MediaStorageGallery from "./MediaStorageGallery";
import CreatorContent from "./CreatorContent";
import {
  uploadFileInFeed,
  uploadFileInMoment,
  uploadFilesToChat,
} from "./utils";
import ExclusiveList from "./ExclusiveList";
import ButtonMediaLocation from "./ButtonMediaLocation";
import OpenedCamera from "./OpenedCamera";
import PreviewCapturedMedia from "./PreviewCapturedMedia";
import ButtonPill from "../../components/Buttons/ButtonPill";

interface AddMediaPageProps {
  previousPage?: "none" | "chat" | "home";
  chatIdToSendContent?: string;
  onUploadComplete?: () => void;
}

type ICapturedMedia = {
  type: string;
  media: string;
  thumbnail?: string;
};

type Consumer = {
  id: string;
  image: string;
  name: string;
};

const AddMediaPage: React.FC<AddMediaPageProps> = (props) => {
  const {
    previousPage = "none",
    onUploadComplete,

    chatIdToSendContent,
  } = props;

  const videoRef = useRef<HTMLVideoElement>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  //HOOKS
  const { setCreatorContent } = usePublishContent();
  const { setOpenUseModal, setModalContent } = useModal();
  const { windowSize } = useWindowWidthSize();
  const { getChats } = useWebSocket();
  const { userInfo } = useUserInfo();

  //STATE
  const [files, setFile] = useState<File | null>(null);
  const [showLocationMediaUploadPills, setShowLocationMediaUploadPills] =
    useState(false);
  const [showButtonGallery, setShowButtonGallery] = useState(false);

  const [mediaToShow, setMediaToShow] = useState<ICapturedMedia | null>(null);
  const [isRecording, setIsRecording] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [capturedMedia, setCapturedMedia] = useState<ICapturedMedia[] | []>([]);
  const [descriptionContent, setDescriptionContent] = useState("");
  const [rosesAmount, setRosesAmount] = useState(0);
  const [shareContentLocation, setShareContentLocation] = useState("");
  const [consumerList, setConsumerList] = useState<Consumer[]>([]);
  const [filteredConsumerList, setFilteredConsumerList] = useState<Consumer[]>(
    []
  );
  const [selectedIdentityPill, setSelectedIdentityPill] = useState(false);

  const [chatIdsForSendContent, setChatIdsForSendContent] = useState<string[]>(
    []
  );
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [showShieldPills, setShowShieldPills] = useState(false);
  const [inputSearchConsumer, setInputSearchConsumer] = useState<string>("");
  const [showUploadButtons, setShowUploadButtons] = useState(false);
  const [uploadLocation, setUploadLocation] = useState("Teaser");
  const [createdTags, setCreatedTags] = useState<string[] | []>([]);
  const [createdTag, setCreatedTag] = useState<string>("");
  const [uploadType, setUploadType] = useState<"post" | "moment" | "feed">(
    "post"
  );

  const [stream, setStream] = useState<MediaStream | null>(null);
  const [screenShowing, setScreenShowing] = useState<
    "library" | "preview-captured" | "camera" | ""
  >("");

  const [secondaryScreenShowing, setSecondaryScreenShowing] = useState<
    "config" | "exclusive-list" | ""
  >("");

  useEffect(() => {
    getListChats();
    setScreenShowing("library");
  }, []);

  useEffect(() => {
    if (screenShowing !== "camera") {
      stopStream();
      stopRecording();
    }
  }, [screenShowing]);

  useEffect(() => {
    if (windowSize > 700) {
      if (secondaryScreenShowing !== "exclusive-list") {
        setSecondaryScreenShowing("config");
      } else {
        setSecondaryScreenShowing("");
      }
    } else {
      setSecondaryScreenShowing("");
    }
  }, [windowSize]);

  const getListChats = useCallback(() => {
    getChats({}, (res: Chat[]) => {
      const prepareList = res.map((chat: Chat) => {
        return {
          id: chat.chatId,
          image: chat.chatParticipants[0]?.user?.profile.photos[0]?.url,
          name: chat.chatParticipants[0]?.user?.displayname,
        };
      });

      setConsumerList(prepareList);
      setFilteredConsumerList(prepareList);
    });
  }, [getChats]);

  const closeModal = () => {
    let count = 0;
    setShowUploadButtons(false);

    const streamStopped = stopStream();
    if (streamStopped) {
      stopRecording();

      setSecondaryScreenShowing("");
      setOpenUseModal(false);
      return;
    } else {
      setTimeout(() => {
        if (count >= 10) {
          setOpenUseModal(false);
          return;
        }
        closeModal();
        count++;
      }, 100);
    }
  };

  const searchInConsumerList = (e: string) => {
    const newList =
      consumerList?.filter((consumer) =>
        consumer.name.toLowerCase().includes(e.toLowerCase())
      ) || [];
    setInputSearchConsumer(e);

    setFilteredConsumerList(newList);
  };

  const shareContentBackButtonHandler = () => {
    if (windowSize < 700) {
      setScreenShowing("library");

      setSecondaryScreenShowing("");
    } else {
      setSecondaryScreenShowing("config");
    }
  };

  const removeMediaFromSharing = (media: ICapturedMedia) => {
    const newMediaList = capturedMedia.filter((file) => file !== media);
    setCapturedMedia(newMediaList);

    if (newMediaList.length === 0) {
      if (screenShowing === "preview-captured") {
        startCamera();
        setScreenShowing("camera");
      }
    }
  };

  const addRosesInputHandler = (e: string) => {
    const inputValue = e.replace("Roses: ", "");
    const numericValue = inputValue.replace(/\D/g, "");
    setRosesAmount(Number(numericValue));
  };

  const shareContentHandler = async () => {
    if (capturedMedia.length === 0) {
      setToastConfig({
        title: "Oops...",
        description: "There is no content to send",
        type: "info",
      });
      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
      }, 4000);

      return;
    }

    setModalContent(<LoadingPage />);

    const price =
      uploadLocation === "secret" || uploadLocation === "Members Only"
        ? rosesAmount
        : 0;

    const token = userInfo.access_token;

    const isPaidContent =
      uploadLocation === "secret" || uploadLocation === "Members Only";

    if (uploadType === "feed") {
      await uploadFileInFeed(
        capturedMedia,
        price,
        token,
        userInfo.display_name
      );
    }

    if (uploadType === "moment") {
      await uploadFileInMoment(capturedMedia, price, token);
    }

    if (chatIdsForSendContent.length > 0) {
      await uploadFilesToChat(
        chatIdsForSendContent,
        capturedMedia,
        token,
        descriptionContent,
        isPaidContent,
        price,
        onUploadComplete
      );
    }

    setCreatorContent({
      collaborators: [],
      isExclusiveContent:
        uploadLocation === "secret" || uploadLocation === "Members Only",
      listConsumer: chatIdsForSendContent,
      medias: [],
      message: descriptionContent,
      rosesContentPrice: rosesAmount,
      shareLocation: shareContentLocation,
    });

    closeModal();
  };

  const addConsumerInListToSend = (id: string) => {
    if (id && chatIdsForSendContent.includes(id)) {
      const newList = chatIdsForSendContent.filter((list) => list !== id);
      setChatIdsForSendContent(newList);
    } else {
      setChatIdsForSendContent((prev) => [...prev, id]);
    }
  };

  const selectedMedias = (media: {
    media: string;
    type: string;
    id: string;
  }) => {
    setCapturedMedia((prev) => {
      if (prev.some((item) => item.media === media.media)) {
        return prev.filter((item) => item.media !== media.media);
      }
      return [
        ...prev,
        {
          media: media.media,
          type: media.type.toLowerCase(),
        },
      ];
    });
  };

  const uploadFilesMode = (location: string[]) => {
    setUploadLocation(location[0]);
  };

  const shareWihAllHandler = () => {
    setChatIdsForSendContent(consumerList.map((consumer) => consumer.id));
  };

  const finishSelectionHandler = () => {
    setSecondaryScreenShowing("config");
  };

  const privateListBackButton = () => {
    setSecondaryScreenShowing("config");
  };

  const addMediaClick = () => {
    setShowUploadButtons((prev) => !prev);
  };

  const stopStream = () => {
    if (stream) {
      console.log("Stopping stream...");
      stream.getTracks().forEach((track) => {
        track.stop();
      });
      setStream(null);

      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
      setScreenShowing("preview-captured");
      return true;
    } else {
      // setScreenShowing("library");

      return true;
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      videoRef.current?.pause();
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };
  const mediaButtonHandler = () => {
    stopStream();
    stopRecording();
    setScreenShowing("library");
  };

  const saveForLaterHandler = () => {};

  const startCamera = async () => {
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        });
        setStream(stream);

        if (videoRef.current) {
          videoRef.current.srcObject = stream;

          videoRef.current.muted = true;

          setTimeout(() => {
            videoRef.current?.play();
          }, 100);
        }
      } else {
        console.error("Error");
      }
    } catch (err) {
      console.error((err as Error).message);
    }
  };

  const openConfigHandler = () => {
    setScreenShowing("");
    setSecondaryScreenShowing("config");
  };

  return (
    <div className="add-media-page">
      <nav className="add-media-page__navbar">
        <p className="no-margin">Share Content</p>
        <span
          className="icon icon-close icon-white icon-md
					 image-container__close-icon"
          onClick={closeModal}
        />
      </nav>

      {screenShowing ? (
        <section className="media-storage-wrapper">
          {screenShowing === "camera" ||
          screenShowing === "preview-captured" ? (
            <>
              <button
                className="image-container__pixel-shield-button"
                onClick={() => setShowShieldPills((prev) => !prev)}
              >
                <span
                  className={`icon icon-filter icon-${
                    showShieldPills ? "pink" : "gray"
                  } icon-md`}
                />
              </button>
              {showShieldPills ? (
                <div className="shield-pills">
                  <ButtonPill
                    clickPill={() => setSelectedIdentityPill((prev) => !prev)}
                    iconName="icon-digitalprint"
                    isPillSelected={selectedIdentityPill}
                    textPill="Identity Shield"
                    className=""
                  />
                </div>
              ) : (
                false
              )}
            </>
          ) : (
            false
          )}

          {screenShowing === "camera" ? (
            <OpenedCamera
              stream={stream}
              canvasRef={canvasRef}
              mediaRecorderRef={mediaRecorderRef}
              videoRef={videoRef}
              setCapturedMedia={setCapturedMedia}
              setFile={setFile}
              setMediaToShow={setMediaToShow}
              setScreenShowing={setScreenShowing}
              showButtonGallery={showButtonGallery}
            />
          ) : (
            false
          )}

          {screenShowing === "library" ? (
            <MediaStorageGallery
              selectedMedias={selectedMedias}
              capturedMedia={capturedMedia}
              setScreenShowing={setScreenShowing}
              setSecondaryScreenShowing={setSecondaryScreenShowing}
            />
          ) : (
            false
          )}

          {screenShowing === "preview-captured" ? (
            <PreviewCapturedMedia
              mediaToShow={mediaToShow}
              descriptionContent={descriptionContent}
              setDescriptionContent={setDescriptionContent}
              setScreenShowing={setScreenShowing}
              setSecondaryScreenShowing={setSecondaryScreenShowing}
            />
          ) : (
            false
          )}

          {screenShowing === "library" ||
          screenShowing === "preview-captured" ? (
            <>
              <ButtonMediaLocation
                buttonCameraAction={startCamera}
                setScreenShowing={setScreenShowing}
                setShowUploadButtons={setShowUploadButtons}
                showUploadButtons={showUploadButtons}
                showBtnCamera
                showBtnStorage={screenShowing === "preview-captured"}
                setCapturedMedia={setCapturedMedia}
                setFile={setFile}
                setMediaToShow={setMediaToShow}
                setShowLocationMediaUploadPills={
                  setShowLocationMediaUploadPills
                }
                buttonMediaAction={mediaButtonHandler}
                bottomPosition={
                  capturedMedia.length && windowSize > 700
                    ? 130
                    : capturedMedia.length &&
                      windowSize < 700 &&
                      screenShowing === "library"
                    ? 100
                    : windowSize < 700 && screenShowing === "preview-captured"
                    ? 230
                    : 100
                }
              />
              {windowSize < 700 &&
              capturedMedia.length &&
              screenShowing === "library" ? (
                <button className="continue-button" onClick={openConfigHandler}>
                  <span className="icon icon-md icon-pink-light icon-arrow-right" />
                </button>
              ) : (
                false
              )}

              <PreviewMediaCardsList
                capturedMedia={capturedMedia}
                removeMediaFromSharing={removeMediaFromSharing}
                addMediaClick={addMediaClick}
                setMediaToShowCallBack={setMediaToShow}
                bottomPosition={
                  windowSize < 700 && screenShowing === "preview-captured"
                    ? 100
                    : 0
                }
              />
            </>
          ) : (
            false
          )}
        </section>
      ) : (
        false
      )}

      {secondaryScreenShowing === "config" ? (
        <CreatorContent
          addRosesInputHandler={addRosesInputHandler}
          setSecondaryScreenShowing={setSecondaryScreenShowing}
          createdTag={createdTag}
          createdTags={createdTags}
          descriptionContent={descriptionContent}
          previousPage={previousPage}
          rosesAmount={rosesAmount}
          setCreatedTag={setCreatedTag}
          setCreatedTags={setCreatedTags}
          setDescriptionContent={setDescriptionContent}
          shareContentBackButtonHandler={shareContentBackButtonHandler}
          shareContentHandler={shareContentHandler}
          uploadFilesMode={uploadFilesMode}
          uploadLocation={uploadLocation}
          setUploadType={setUploadType}
          saveForLaterHandler={saveForLaterHandler}
        />
      ) : (
        false
      )}

      {secondaryScreenShowing === "exclusive-list" ? (
        <ExclusiveList
          addConsumerInListToSend={addConsumerInListToSend}
          chatIdsForSendContent={chatIdsForSendContent}
          consumerList={consumerList}
          filteredConsumerList={filteredConsumerList}
          finishSelectionHandler={finishSelectionHandler}
          inputSearchConsumer={inputSearchConsumer}
          privateListBackButton={privateListBackButton}
          searchInConsumerList={searchInConsumerList}
          setChatIdsForSendContent={setChatIdsForSendContent}
          shareWihAllHandler={shareWihAllHandler}
        />
      ) : (
        false
      )}

      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default AddMediaPage;
