import React, { useEffect, useState } from "react";
import "./styles.scss";

interface ButtonNavigationProps {
  buttons: ButtonNavigationInfo[];
  className?: string;
  activatedButton?: number;
  horizontalMargin?: boolean;
  rightButtonIcon?: ButtonIconNavigation;
  buttonsColorNonSelected?: "midnight" | "dawn";
}

export interface ButtonNavigationInfo {
  text: string;

  textRight?: string;
  clickAction: () => void;
  id: number;
  iconLeft?: string;
}

export interface ButtonIconNavigation {
  iconButton?: string;
  clickAction: () => void;
}

const ButtonNavigation: React.FC<ButtonNavigationProps> = (props) => {
  const {
    buttons,
    className,
    activatedButton,
    horizontalMargin = true,
    rightButtonIcon,
    buttonsColorNonSelected = "midnight",
  } = props;
  const [actionedButton, setActionedButton] = useState(1);

  useEffect(() => {
    if (activatedButton) {
      setActionedButton(activatedButton);
    }
  }, [activatedButton]);

  return (
    <div className={`action-button-group ${className ?? ""}`}>
      {buttons.map((btn, index, arr) => {
        return (
          <button
            key={index}
            className={`action-button ${
              actionedButton === btn.id ? "active" : ""
            } ${
              index === 0
                ? "first-child"
                : arr.length === index + 1
                ? "last-child"
                : ""
            } ${
              !horizontalMargin ? "no-hor-margin" : ""
            } background-${buttonsColorNonSelected}`}
            onClick={() => {
              btn.clickAction();
              setActionedButton(btn.id);
            }}
          >
            {btn.iconLeft && (
              <span
                className={`icon icon-${btn.iconLeft} ${
                  actionedButton === btn.id ? "" : "icon-gray"
                }  icon-sm icon-left`}
              />
            )}

            <p
              className={`no-margin ${
                actionedButton === btn.id ? "active" : ""
              }`}
            >
              {btn.text}
            </p>

            <p
              className={`no-margin right-text ${
                actionedButton === btn.id ? "active" : ""
              }`}
            >
              {btn.textRight}
            </p>
          </button>
        );
      })}
      {rightButtonIcon ? (
        <button
          className={`action-button__button-right background-${buttonsColorNonSelected}`}
        >
          <span
            className={`icon ${rightButtonIcon.iconButton} 
						icon-md icon-gray 
						button-right__icon`}
          />
        </button>
      ) : (
        false
      )}
    </div>
  );
};

export default ButtonNavigation;
