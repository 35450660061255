import { useUserInfo } from "./userInfo";
import postSendRoses from "../api/rosesTransactions/postSendRoses";
import { useAvailableRoses } from "../contexts/availableRosesContext";
import { useProducerDetails } from "../contexts/producerDetailsContext";
import postUnlockChatContent from "../api/chat/postUnlockContent";

const useCanPurchasedMedia = () => {
  const { userInfo } = useUserInfo();
  const { availableRoses, updateSubtractAvailableRoses } = useAvailableRoses();
  const { producerDetails } = useProducerDetails();

  const canPurchase = async (price: number) => {
    return availableRoses >= price ? true : false;
  };

  const sendRose = async (price: number) => {
    updateSubtractAvailableRoses(price);
    await postSendRoses(
      userInfo.access_token,
      producerDetails.userId,
      price,
      "Unlocked Chat/Media/Contents"
    );
  };

  const purchaseMedia = async (messageId: string, price: number) => {
    if (!(await canPurchase(price))) return;

    await sendRose(price);
    await postUnlockChatContent(userInfo.access_token, messageId);
  };

  return { canPurchase, sendRose, purchaseMedia };
};

export { useCanPurchasedMedia };
