// import { useEffect, useRef, useState } from "react";
// import "./styles.scss";

// import { useScrollPosition } from "../../hooks/useScrollPosition";

// import messagesMock from "./mock";

const ObserverTest: React.FC = () => {
  return <></>;
  //   const messagesContainer = useRef<HTMLDivElement | null>(null);
  //   const itemsRef = useRef<(HTMLDivElement | null)[]>([]);
  //   const [messages, setMessages] = useState(messagesMock);

  //   const { isAtTop, isAtBottom } = useScrollPosition(messagesContainer);

  //   useEffect(() => {
  //     setMessages(messagesMock);
  //   }, []);

  //   const options = {
  //     root: messagesContainer.current,
  //     rootMargin: "0px",
  //     threshold: 1.0,
  //   };

  //   useEffect(() => {
  //     if (itemsRef.current.length === 0) return;

  //     const observer = new IntersectionObserver((entries, observer) => {
  //       entries.forEach((entry) => {
  //         if (entry.isIntersecting) {
  //           const beholded = entry.target;
  //           const messageId = beholded.id;
  //           const message = messages.find(
  //             (msg: { messageId: string }) => msg.messageId === messageId
  //           );
  //           console.log(message, message?.isRead);
  //           if (message && !message.isRead) {
  //             message.isRead = true;
  //             // markMessagesAsRead([messageId]);
  //           }

  //           observer.unobserve(entry.target);
  //         }
  //       });
  //     }, options);

  //     itemsRef.current.forEach((item) => {
  //       if (item) {
  //         observer.observe(item);
  //       }
  //     });

  //     return () => observer.disconnect();
  //   }, [messagesMock]);

  //   return (
  //     <div className="observer-test">
  //       <div className="observer-test__wrapper" ref={messagesContainer}>
  //         {messages.map((message, index) => {
  //           return (
  //             <div
  //               key={message.messageId}
  //               id={message.messageId}
  //               className="observer-test__box"
  //               ref={(el) => {
  //                 if (el) {
  //                   itemsRef.current[index] = el;
  //                 }
  //               }}
  //             >
  //               <div className="observer-test__text">{message.content}</div>
  //               <span
  //                 className={`
  //                   observer-test__isRead
  //                   ${message.isRead ? "observer-test__isRead--active" : ""}
  //                 `}
  //               ></span>
  //             </div>
  //           );
  //         })}
  //       </div>
  //     </div>
  //   );
};

export default ObserverTest;
