import { useCallback, useContext, useEffect, useState } from "react";
import "./styles.scss";

import NewProfile from "../Cards/NewProfile";
import CardsCatalogDetails from "../Cards/CardsCatalogDetails";
import CardsMediaLibrary from "../Cards/CardsMediaLibrary";
import Button from "../Buttons/Button";
import ButtonIcon from "../Buttons/ButtonIcon";
import { profile } from "console";
import PageTitle from "../../components/PageTitles";

import { ICatalogResponse, PhotosProfile } from "../../types/catalog";
import { Chat } from "../../types/chatInfo";
import { IToast } from "../../types/toast";

import { useChat } from "../../contexts/openedChatContext";
import { useWebSocket } from "../../contexts/webSocketContext";
import { ToggleChatOpenContext } from "../../contexts/toggleChatOpen";

import CreateChatContact from "../../api/postCreateChatContact";
import postAddToFavorite from "../../api/favorite/postAddToFavorite";
import getPublicDetails from "../../api/publicDetails/getProfileDetails";
import deleteRemoveFromFavorites from "../../api/favorite/deleteRemoveFromFavorites";

import { Params, useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { MomentsTriggerContext } from "../../contexts/momentTrigger";
import Toast from "../Toast";
import { FavoritesCatalogContext } from "../../contexts/favoritesCatalogTrigger";
import { FavoritesListContext } from "../../contexts/favoritesListTrigger";
import { useWindowWidthSize } from "../../hooks/useWindowWidthSize";

const ProducerInteractionPage: React.FC = () => {
  const {
    setFeedScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    componentFeed,
    componentTertiary,
    componentSecondary,
    params,
  } = useDeskNavigation();
  const { getChats } = useWebSocket();
  const { windowSize } = useWindowWidthSize();
  const { isChatPageOpen, setIsChatPageOpen } = useContext(
    ToggleChatOpenContext
  );
  const { favoritesCatalogTrigger, setFavoritesCatalogTrigger } = useContext(
    FavoritesCatalogContext
  );

  const { setFavoritesListTrigger } = useContext(FavoritesListContext);
  const { setMomentTrigger } = useContext(MomentsTriggerContext);
  const [chats, setChats] = useState<Chat[]>([]);
  const [isProfileFavorite, setIsProfileFavorite] = useState(false);

  const [openedChatUserIds, setOpenedChatUserIds] = useState<string[]>([]);
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [producerDetails, setProducerDetails] = useState<ICatalogResponse>(
    {} as ICatalogResponse
  );

  const { userId } = params;
  const { userInfo } = useUserInfo();
  const { setActiveChatId, activeChatId } = useChat();
  const [photoProducer, setPhotoProducer] = useState<PhotosProfile[] | []>([]);
  const [photoSelect, setPhotoSelect] = useState("");
  const [videoPublic, setVideoPublic] = useState<string | null>();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeChatIdState, setActiveChatIdState] = useState("");
  const [pageParams, setPageParams] = useState({} as Params);
  const pastTokenStorage = localStorage.getItem("user-info-past-token");

  useEffect(() => {
    if (isChatPageOpen) {
      sendMessageClick();
    }
  }, [userId]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [params]);

  useEffect(() => {
    const producerDetail = async () => {
      if (userInfo.access_token) {
        const response = await getPublicDetails(userInfo.access_token, userId);

        switch (response?.status) {
          case 200:
            setIsProfileFavorite(response.res.isFavorite);
            setProducerDetails(response.res);
            setPhotoProducer(response.res.profile.photos);

            if (response.res.profile.cover) {
              setVideoPublic(response.res.profile.cover?.url);
              setPhotoSelect(response.res.profile.cover?.url);
            } else {
              setPhotoSelect(response.res.profile.photos[0]?.url);
            }
            setCurrentIndex(0);

            break;
          default:
            // setPrincipalScreen("");
            break;
        }
      }
    };
    producerDetail();
  }, [userId, pastTokenStorage]);

  const clickLikeHandler = async () => {
    if (!isProfileFavorite) {
      setIsProfileFavorite(true);
      const response = await postAddToFavorite(
        userInfo.access_token,
        producerDetails.userId
      );
      switch (response.status) {
        case 201:
        case 204:
          setFavoritesListTrigger((prev) => !prev);
          break;
        case 409:
          break;

        default:
          setToastConfig({
            title: "Error",
            description:
              "We were unable to process the request. Please, Try again",
            type: "error",
          });
          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
          }, 3000);
          break;
      }
    } else {
      setIsProfileFavorite(false);
      const response = await deleteRemoveFromFavorites(
        userInfo.access_token,
        producerDetails.userId
      );

      switch (response.status) {
        case 204:
        case null:
          setFavoritesListTrigger((prev) => !prev);

          break;
        case 409:
          break;

        default:
          setToastConfig({
            title: "Error",
            description:
              "We were unable to process the request. Please, Try again 0000",
            type: "error",
          });
          setToastShow(true);

          setTimeout(() => {
            setToastShow(false);
          }, 3000);
          break;
      }
    }

    setMomentTrigger((prev) => !prev);
  };

  // Set unlocked chats
  const getListChats = useCallback(async () => {
    getChats({}, (res: Chat[]) => {
      const openedChatsIds: string[] | [] = res?.map(
        (chat: Chat) => chat?.chatParticipants[0]?.user?.userId
      );
      setOpenedChatUserIds(openedChatsIds);
      setChats(res);
    });
  }, [getChats, userId]);

  useEffect(() => {
    getListChats();
  }, [getChats, getListChats]);

  useEffect(() => {
    if (Object.keys(params).length > 0) {
      setPageParams(params);
    }
  }, [params]);

  const sendMessageClick = async () => {
    const creatorId = userId && userId !== "0" ? userId : pageParams.userId;

    if (creatorId) {
      const isOpenedChat = openedChatUserIds?.includes(creatorId);
      const chatContactService = CreateChatContact();
      const response = await chatContactService.postCreateChatContact(
        creatorId,
        userInfo.access_token
      );

      switch (response.status) {
        case 201:
          setActiveChatId(response.res.chatId);
          setActiveChatIdState(response.res.chatId);
          break;

        default:
          break;
      }
      setSecondaryScreen("/personal-chat", {
        chatId: response.res.chatId,
        userId: creatorId,
        isLocked: JSON.stringify(isOpenedChat),
      });
    }
  };
  // End Set unlocked chats

  const backPageHandler = () => {
    if (componentFeed) {
      setPrincipalScreen("");
    } else {
      setPrincipalScreen("");
      setSecondaryScreen("");
      setTertiaryScreen("");
      setIsChatPageOpen(false);
    }
  };

  const sendMessageHandler = () => {
    setIsChatPageOpen(true);
    sendMessageClick();
  };

  const handlerFeedButtonClick = () => {
    const creatorId = userId && userId !== "0" ? userId : pageParams.userId;
    const chatId = activeChatIdState
      ? activeChatIdState
      : (activeChatId as string);

    setFeedScreen("/feed", {
      userId: creatorId,
      chatId,
      isLocked: JSON.stringify(openedChatUserIds?.includes(creatorId)),
    });
  };

  return (
    <div id="producer-interaction">
      <PageTitle
        onBackClick={backPageHandler}
        hasCloseIcon={windowSize >= 768}
        className="margin-hor-16"
        buttonFeed={!Boolean(componentFeed)}
        buttonFeedClick={handlerFeedButtonClick}
      />
      <NewProfile
        // isFeedOpen={Boolean(componentFeed)}
        isIA={true}
        age={producerDetails?.profile?.age}
        // isVerified
        isVip={producerDetails?.isVip}
        idProfile={userId}
        name={producerDetails?.displayname}
        principalPhoto={photoSelect}
        profilePhoto={photoProducer[0]?.thumbnailUrl}
        // profileDetails={["Brazilian", "5’4” / 1,67m", "56kg / 123lb"]}
        // rating={producerDetails.profile.rating || Math.floor(Math.random() * 6)}
        heartButtonClick={clickLikeHandler}
        sendMessageClick={sendMessageHandler}
        buttonStyle={isProfileFavorite ? "transparentActive" : "transparent"}
        icon={
          isProfileFavorite
            ? "icon-heart-solid"
            : "icon-heart-outline icon-gray-light"
        }
        iconSecondary={"icon-messages icon-gray-light"}
        photos={photoProducer}
        changeNext={() => {
          setCurrentIndex((prevIndex) => {
            if (prevIndex === 0 && photoSelect?.endsWith(".mp4")) {
              setPhotoSelect(photoProducer[prevIndex]?.url);
              return 1;
            }
            if (prevIndex === 0 && !photoSelect?.endsWith(".mp4")) {
              setPhotoSelect(photoProducer[prevIndex + 1]?.url);
              return 1;
            }

            const nextIndex = prevIndex + 1;
            if (nextIndex < photoProducer.length) {
              setPhotoSelect(photoProducer[prevIndex]?.url || "");
              return nextIndex;
            }

            setPhotoSelect(photoProducer[photoProducer.length - 1]?.url || "");
            return prevIndex;
          });
        }}
        changePrevious={() => {
          setCurrentIndex((prevIndex) => {
            if (prevIndex === 0 && videoPublic?.endsWith(".mp4")) {
              setPhotoSelect(videoPublic);
              return 0;
            }
            if (prevIndex > 0) {
              const previousIndex = prevIndex - 1;
              setPhotoSelect(photoProducer[previousIndex]?.url || "");
              return previousIndex;
            }
            return prevIndex;
          });
        }}
      />
      {/* <div className="list-items-details margin-hor-24">
        {Object.entries(detailsMock).map(([detail, value], index) => (
          <ListItemSecondary
            key={index}
            showLabel
            labelContent={
              detail === "rosesToConnect"
                ? "Roses to Connect"
                : detail === "rosesToMeet"
                ? "Roses to Meet"
                : detail === "hairColor"
                ? "Hair Color"
                : detail.charAt(0).toUpperCase() + detail.slice(1)
            }
            showValue
            valueContent={value || ""}
            showSeparator={Object.entries(detailsMock).length > index + 1}
          />
        ))}
      </div>

      <div className="photo-album padding-hor-24">
        {cardsLibrary.map((card, index) => (
          <CardsMediaLibrary
            key={index}
            image={card.img}
            altImage={card.alt}
            rosesPrice={card.price}
            unlockClick={() => {}}
            isBlurred={parseFloat(card.price) > 0}
          />
        ))}
      </div>

      <div className="reviews-wrapper">
        <div className="header-review-wrapper">
          <img src={LockIcon} alt="lock icon" />
          <p>REVIEWS</p>
          <p>
            Begin interaction <br /> to see user's reviews
          </p>
        </div>

        <ListItemSecondary
          showLabel
          labelContent="Connect & Chat"
          showValue
          valueContent={detailsMock.rosesToConnect}
          showSeparator
        />
        <ListItemSecondary
          showLabel
          labelContent="Virtual Meetup"
          showValue
          valueContent={detailsMock.rosesToMeet}
        />
        <div className="footer-interaction-divider margin-vert-24" />
        <div className="buttons-interaction-container">
          <Button buttonStyle="primary" onClick={sendMessageHandler}>
            Send Message
          </Button>
          <ButtonIcon
            onClick={clickLikeHandler}
            buttonStyle={
              isProfileFavorite ? "heart-pink-gradient" : "heart-white-gradient"
            }
          />
        </div>
      </div>
 */}
      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default ProducerInteractionPage;
