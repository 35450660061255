import { useState } from "react";
import Header from "../../components/Headers/Header";
import CodeVerification from "../../components/CodeVerification";
import "./styles.scss";

import ClockIcon from "../../assets/icons/clock-icon-gray.svg";
import Button from "../../components/Buttons/Button";
import SubText from "../../components/Texts/SubText";
import { useUserInfo } from "../../hooks/userInfo";

import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import PageTitle from "../../components/PageTitles";
import Toast from "../../components/Toast";
import { IToast } from "../../types/toast";

const CreateEmailVerifyPage: React.FC = () => {
  const [codeValue, setCodeValue] = useState("");
  const [toastShow, setToastShow] = useState(false);
  const [isCodeEmailInputsWarning, setIsCodeEmailInputsWarning] =
    useState(false);

  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });

  const { userInfo } = useUserInfo();
  const { setPrincipalScreen } = useDeskNavigation();

  const addEmailSubmit = () => {
    setIsCodeEmailInputsWarning(false);

    setToastConfig({
      description: "",
      title: "",
      type: "info",
    });

    setPrincipalScreen("/send-photos");
  };

  return (
    <div className="create-email-verify margin-hor-16">
      <PageTitle
        title={"Settings"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/recovery-email")}
      />
      <main className="create-email-verify__main">
        <Header
          marginTop={0}
          title={
            <>
              Verify your <br />
              Email
            </>
          }
          subTitle={
            <>
              Enter the code we’ve sent by text to
              <span className="email-text padding-hor-4">
                {userInfo.user_email}
              </span>
              <span
                className="anchor-link"
                onClick={() => setPrincipalScreen("/recovery-email")}
              >
                Change
              </span>
            </>
          }
        />
        <CodeVerification
          fieldsNumber={5}
          valuesCodesCallback={(e) => setCodeValue(e)}
          isWarning={isCodeEmailInputsWarning}
        />
        <SubText icon={ClockIcon} altIcon="clock icon">
          This code should arrive within 30 seconds.
        </SubText>
      </main>
      <Button
        className="margin-bottom-40"
        buttonStyle="primary"
        onClick={addEmailSubmit}
      >
        Continue
      </Button>

      <Toast
        type={toastConfig.type}
        description={toastConfig.description}
        setIsVisible={setToastShow}
        isVisible={toastShow}
        title={toastConfig.title}
      />
    </div>
  );
};

export default CreateEmailVerifyPage;
