import { servicesApi } from "../fetchApi/services-api";
import fetchApi from "../fetchApi";

interface ListDetails {
  place?: string;
  region?: string;
  country?: string;
  country_code?: string;
  country_code_alpha_3?: string;
  locationId?: string;
}

const PostBlockedLocations = async (token: string, body: ListDetails) => {
  return await fetchApi({
    service: "user",
    endPoint: `/blocked-locations`,
    method: "POST",
    token,
    data: body,
  });
};

export default PostBlockedLocations;
