import fetchApi from "../fetchApi";

const getAuthTokenById = async (token: string, id: string) => {
  return await fetchApi({
    service: "auth",
    endPoint: `/token/${id}`,
    method: "GET",
    token,
  });
};

export default getAuthTokenById;
