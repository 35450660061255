import React, { useContext, useEffect, useState } from "react";
import "./styles.scss";
import Desk from "../../components/Wrappers/Desk";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import { useUserInfo } from "../../hooks/userInfo";
import { useNavigate } from "react-router-dom";
import getUserPreferences from "../../api/userPreferences/getUserPreferences";
import ModalInitialPreferences from "../../components/Modals/ModalInitialPreferences";
import getUserInfo from "../../api/userInfo/getUserInfo";
import getListUserChats from "../../api/chat/getListUserChats";
import { Chat, INewMessage } from "../../types/chatInfo";
import getListFavorites from "../../api/favorite/getListFavorites";
import { useWebSocket } from "../../contexts/webSocketContext";
import newMessageNotification from "../../assets/sounds/notification-01.MP3";
import { useChat } from "../../contexts/openedChatContext";
import { ToggleChatOpenContext } from "../../contexts/toggleChatOpen";
import { FavoritesCatalogContext } from "../../contexts/favoritesCatalogTrigger";
import { useModal } from "../../hooks/useModal";
import { useSearchCatalog } from "hooks/useSearchCatalog";

const Homepage: React.FC = () => {
  const { userInfo } = useUserInfo();
  const { openUseModal, setModalContent, setOpenUseModal } = useModal();
  const { favoritesCatalogTrigger, setFavoritesCatalogTrigger } = useContext(
    FavoritesCatalogContext
  );
  const navigate = useNavigate();

  const { addChatListener, removeListener } = useWebSocket();
  const { activeChatId } = useChat();
  const { setIsChatPageOpen } = useContext(ToggleChatOpenContext);

  const {
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    mainScreen,
    componentFeed,
    setFeedScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    setMainScreen,
  } = useDeskNavigation();

  const [profileAvatar, setProfileAvatar] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasANewMessage, setHasANewMessage] = useState(false);

  useEffect(() => {
    // if (userInfo.account_type === "CREATOR") {
    //   setMainScreen("/creator-dashboard");
    // } else {
    //   setMainScreen("/catalog");
    // }

    setMainScreen("/catalog");

    const requestUserPreferences = async () => {
      const response = await getUserPreferences(userInfo.access_token);
      switch (response.status) {
        case 200:
          if (response.res.preferedGenders?.length === 0) {
            setModalContent(
              <ModalInitialPreferences
                isOpen={isModalOpen}
                setIsOpen={setIsModalOpen}
              />
            );
            setOpenUseModal(true);
          }
          break;
        case 401:
          navigate("/login");
          break;
        default:
          navigate("/login");
          break;
      }
    };
    requestUserPreferences();
  }, [userInfo]);

  useEffect(() => {
    const handleNewMessage = (newMessage: INewMessage) => {
      if (newMessage.eventType === "NEW_MESSAGE") {
        if (
          newMessage?.payload?.user?.userId !== userInfo.user_id &&
          newMessage.payload.chatId !== activeChatId
        ) {
          playNotificationSound();
          setHasANewMessage(true);
        }
      }
    };

    addChatListener(handleNewMessage);

    return () => {
      removeListener("events", handleNewMessage);
    };
  }, [addChatListener, removeListener, activeChatId, userInfo]);

  const playNotificationSound = () => {
    const audio = new Audio(newMessageNotification);
    audio.play().catch((error) => {
      console.error("Error trying to play the audio:", error);
    });
  };

  useEffect(() => {
    const getUserInfoRequest = async () => {
      const response = await getUserInfo(userInfo.access_token);
      switch (response.status) {
        case 200:
          if (response?.res?.message?.statusCode === 401) {
            navigate("/login");
          } else {
            setProfileAvatar(response.res?.photos[0]?.thumbnailUrl);
          }
          break;
        case 401:
          navigate("/login");
          break;
        default:
          navigate("/login");
          break;
      }
    };

    const messageInChatRequest = async () => {
      const response = await getListUserChats(userInfo.access_token);
      switch (response.status) {
        case 200:
          if (response?.res?.message?.statusCode === 401) {
            navigate("/login");
          } else {
            if (
              response?.res?.filter((chat: Chat) => chat?._count.messages !== 0)
                .length > 0
            ) {
              setHasANewMessage(true);
            } else {
              setHasANewMessage(false);
            }
          }
          break;
        case 401:
          navigate("/login");
          break;
        default:
          navigate("/login");
          break;
      }
    };

    getUserInfoRequest();
    messageInChatRequest();
  }, [
    userInfo,
    componentPrincipal,
    componentSecondary,
    componentTertiary,
    addChatListener,
    navigate,
    hasANewMessage,
  ]);

  const clickProfileAvatar = () => {
    if (componentFeed) {
      setPrincipalScreen("/user-settings");
      setFeedScreen("");
      setTertiaryScreen("");
      setSecondaryScreen("");
    } else {
      setPrincipalScreen("/user-settings");
      setTertiaryScreen("");
      setSecondaryScreen("");
      setIsChatPageOpen(false);
    }
  };

  const favoritesClickHandler = () => {
    setFavoritesCatalogTrigger(true);

    setTertiaryScreen("");
    setSecondaryScreen("");
    setPrincipalScreen("");
    setFeedScreen("");
  };

  const openChatListHandler = () => {
    if (componentFeed) {
      setPrincipalScreen("/chat-list");
      setFeedScreen("");
      setTertiaryScreen("");
      setSecondaryScreen("");
    } else {
      setSecondaryScreen("");
      setTertiaryScreen("");
      setPrincipalScreen("/chat-list");
    }
  };
  const clickOnGrid = () => {
    setFavoritesCatalogTrigger(false);
    setFeedScreen("");
    setPrincipalScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setMainScreen("/catalog");
  };

  return (
    <>
      <Desk
        clickOnGrid={clickOnGrid}
        clickProfileAvatar={clickProfileAvatar}
        profileAvatar={profileAvatar}
        principalMain={componentPrincipal}
        secondaryMain={componentSecondary}
        tertiaryMain={componentTertiary}
        feedMain={componentFeed}
        hasANewMessage={hasANewMessage}
        catalogMain={mainScreen}
        favoritesClickHandler={favoritesClickHandler}
        clickChatList={openChatListHandler}
      />
    </>
  );
};

export default Homepage;
