import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import getCreatorMedia from "../../../api/user/getCreatorMedia";
import { useUserInfo } from "../../../hooks/userInfo";
import { ICreatorMedia } from "../../../types/creatorMedia";
import ButtonNavigation, {
  ButtonNavigationInfo,
} from "../../../components/Buttons/ButtonNavigation";
import PageTitle from "../../../components/PageTitles";
import { useWindowWidthSize } from "../../../hooks/useWindowWidthSize";
import InputText from "../../../components/Inputs/InputText";
import getAllMoments from "api/moments/getAllMoments";
import { IMomentsSaved, IMomentsSavedDetails } from "types/moments";
import ImageMessageModal from "components/Chat/ChatModals/ImageMessageModal";
import { useModal } from "hooks/useModal";
import { useModalSecondary } from "hooks/useModalSecondary";

interface MediaStorageGalleryProps {
  className?: string;
  selectedMedias: (mediaArray: MediaObj) => void;
  capturedMedia: ICapturedMedia[];
  setSecondaryScreenShowing: React.Dispatch<
    React.SetStateAction<"" | "config" | "exclusive-list">
  >;
  setScreenShowing: React.Dispatch<
    React.SetStateAction<"" | "library" | "preview-captured" | "camera">
  >;
}

type MediaObj = {
  type: string;
  media: string;
  id: string;
  thumbnail?: string;
};

type ICapturedMedia = {
  type: string;
  media: string;
  thumbnail?: string;
};

const MediaStorageGallery: React.FC<MediaStorageGalleryProps> = (props) => {
  const {
    className,
    selectedMedias,
    capturedMedia,
    setSecondaryScreenShowing,
    setScreenShowing,
  } = props;
  const [allMediaToShow, setAllMediaToShow] = useState<MediaObj[]>([]);
  const [searchFiles, setSearchFiles] = useState("");
  const [typeMediaToShow, setTypeMediaToShow] = useState<
    "all" | "videos" | "photos" | "saved" | "search"
  >("all");
  const [selectedMediasState, setSelectedMediasState] = useState<MediaObj[]>(
    []
  );
  const { setOpenUseModal } = useModal();
  const [cursorMoment, setCursorMoment] = useState("");
  const galleryRef = useRef<HTMLElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clickTime, setClickTime] = useState(0);

  const { userInfo } = useUserInfo();
  const { windowSize } = useWindowWidthSize();

  useEffect(() => {
    mediaCreatorRequest();
  }, [userInfo.access_token]);

  useEffect(() => {
    getAllMomentsSended();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && !isLoading) {
          // loadMoreItems();
        }
      },
      { threshold: 1.0 }
    );

    if (galleryRef.current) {
      observer.observe(galleryRef.current);
    }

    return () => {
      if (galleryRef.current) {
        observer.unobserve(galleryRef.current);
      }
    };
  }, [isLoading]);

  // useEffect(()=>{
  // 	setSelectedMediasState(capturedMedia)
  // }, [capturedMedia])

  const getAllMomentsSended = async () => {
    try {
      const request = await getAllMoments(
        userInfo.access_token,
        20,
        cursorMoment
      );

      const moments: IMomentsSavedDetails = request.res;

      const media = moments.moments?.map((media) => {
        return {
          type: media.file.fileType,
          media: media.file.thumbnailUrl || media.file.url,
          id: media.fileId,
        };
      });

      setAllMediaToShow((prev) => [...prev, ...media]);
    } catch (error) {
      console.error(error);
    }
  };

  const selectMediasHandler = (media: MediaObj) => {
    const mediaClicked = selectedMediasState.some(
      (item) => item.id === media.id
    )
      ? selectedMediasState.filter((item) => item.id !== media.id)
      : [...selectedMediasState, media];

    setSelectedMediasState(mediaClicked);
    selectedMedias(media);
  };

  const mediaCreatorRequest = async () => {
    try {
      const request = await getCreatorMedia(userInfo.access_token);
      const res: ICreatorMedia[] = request.res;

      const allMedias = res
        ?.filter((media) => media.fileType !== "AUDIO")
        ?.map((allMedia) => {
          return {
            type: allMedia.fileType,
            media: allMedia.thumbnailUrl || allMedia.url,
            id: allMedia.fileId,
          };
        });

      setAllMediaToShow((prev) => [...prev, ...allMedias]);

      switch (request.status) {
        case 200:
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const buttonsGroup: ButtonNavigationInfo[] = [
    {
      text: "All",
      clickAction: () => setTypeMediaToShow("all"),
      id: 1,
      textRight: allMediaToShow.length.toString(),
    },
    {
      text: "Photos",
      clickAction: () => setTypeMediaToShow("photos"),
      id: 2,
      textRight: allMediaToShow
        .filter((media) => media.type === "PHOTO")
        .length.toString(),
    },
    {
      text: "Videos",
      clickAction: () => setTypeMediaToShow("videos"),
      id: 3,
      textRight: allMediaToShow
        .filter((media) => media.type === "VIDEO")
        .length.toString(),
    },
    {
      text: "Saved",
      clickAction: () => setTypeMediaToShow("saved"),
      id: 4,
      textRight: "0",
    },
  ];

  const clickTouchDownMedia = (e: React.MouseEvent) => {
    setClickTime(Math.round(e.timeStamp));
  };

  const clickTouchLeaveMedia = (mediaFile: MediaObj, e: React.MouseEvent) => {
    const timeClickUp = Math.round(e.timeStamp);
    selectMediasHandler(mediaFile);

    // if (timeClickUp - clickTime < 500) {
    //   selectMediasHandler(mediaFile);
    // } else {
    //   setModalSecondaryContent(
    //     <div className="storage-gallery-preview-media-modal">
    //       {mediaFile.type === "PHOTO" ? (
    //         <img
    //           src={mediaFile.media}
    //           alt={`${mediaFile.id}`}
    //           className="storage-gallery-preview-media-modal__image"
    //         />
    //       ) : (
    //         <video autoPlay className="preview-file">
    //           <source src={mediaFile.media}></source>
    //         </video>
    //       )}
    //     </div>
    //   );
    // }

    // setOpenUseModalSecondary(true);
  };

  return (
    <section
      ref={galleryRef}
      id="media-storage-gallery"
      className={`${className ?? ""} `}
    >
      <PageTitle
        onBackClick={() => setOpenUseModal(false)}
        title="Create Content"
        subtitle="Share it with the world"
        className="media-storage-gallery__page-title"
        hasCloseIcon
        hasButtonNavigation
      />
      <ButtonNavigation
        className={`${
          windowSize < 700 ? "margin-bottom-16" : ""
        } padding-hor-16`}
        horizontalMargin={windowSize > 700}
        buttons={buttonsGroup}
        rightButtonIcon={
          windowSize > 700
            ? {
                iconButton: "icon-search",
                clickAction: () => console.log("search"),
              }
            : undefined
        }
      />

      {windowSize < 700 ? (
        <div className="padding-hor-16">
          <InputText
            searchInput
            inputType="text"
            iconRight=""
            onChangeInput={setSearchFiles}
            value={searchFiles}
          />
        </div>
      ) : (
        false
      )}
      <div className="media-storage-gallery__cards-wrapper">
        {allMediaToShow
          .filter((media) => {
            if (typeMediaToShow === "all") return true;
            if (typeMediaToShow === "photos" && media.type === "PHOTO")
              return true;
            if (typeMediaToShow === "videos" && media.type === "VIDEO")
              return true;
            return false;
          })
          .map((mediaFile, index) => {
            const isMediaIncluded = capturedMedia.find(
              (m) => m.media === mediaFile.media
            );

            return (
              <div
                className={`cards-wrapper__file-card ${
                  isMediaIncluded ? "file-card__selected" : ""
                }`}
                key={index}
                onMouseDown={(e) => clickTouchDownMedia(e)}
                onMouseUp={(e) => clickTouchLeaveMedia(mediaFile, e)}
              >
                {mediaFile.type === "PHOTO" ? (
                  <img
                    src={mediaFile.media}
                    alt={`${index} file`}
                    className="file-card__card"
                  />
                ) : (
                  <video src={mediaFile.media} className="file-card__card">
                    <source src={mediaFile.media}></source>
                  </video>
                )}
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default MediaStorageGallery;
