import React, { useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import Toast from "../../components/Toast";
import { IToast } from "../../types/toast";
import getUsersListAll from "../../api/getUsersListAll";
import { ICatalogResponse } from "../../types/catalog";
import { useUserInfo } from "../../hooks/userInfo";
import InputText from "../../components/Inputs/InputText";
import ListItemLink from "../../components/Lists/ListItemLink";
import getAuthTokenById from "../../api/auth/getAuthTokenById";
import { useModal } from "../../hooks/useModal";
import LoadingPage from "../../components/LoadingPage";
import * as jwtDecode from "jwt-decode";
import { IInfoToken } from "../../types/infoToken";
import getCreatorStatus from "../../api/creatorApplications/getCreatorStatus";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import getUserInfo from "../../api/userInfo/getUserInfo";
import { useNavigate } from "react-router-dom";

const UserSettingsChangeProfile: React.FC = () => {
  const { userInfo, setUserInfo } = useUserInfo();
  const { openUseModal, setModalContent, setOpenUseModal } = useModal();
  const { setPrincipalScreen } = useDeskNavigation();
  const navigate = useNavigate();
  const [toastShow, setToastShow] = useState(false);
  const [toastConfig, setToastConfig] = useState<IToast>({
    type: undefined,
    title: "",
    description: "",
  });
  const [catalogData, setCatalogData] = useState<ICatalogResponse[] | []>([]);
  const [catalogFilteredData, setCatalogFilteredData] = useState<
    ICatalogResponse[] | []
  >([]);
  const [searchCreator, setSearchCreator] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pastProfileAvatar, setPastProfileAvatar] = useState("");
  const [pastProfileName, setPastProfileName] = useState("");
  const [userPastToken, setUserPastToken] = useState("");
  const pastTokenStorage = localStorage.getItem("user-info-past-token");
  const pastNameStorage = localStorage.getItem("user-info-past-name");
  const pastAvatarStorage = localStorage.getItem("user-info-past-avatar");

  useEffect(() => {
    getCatalogData();

    if (pastTokenStorage && pastNameStorage && pastAvatarStorage) {
      setUserPastToken(pastTokenStorage);
      getUserDetailsInfo(pastTokenStorage);
      setPastProfileName(pastNameStorage);
      setPastProfileAvatar(pastAvatarStorage);
    }
  }, [pastTokenStorage, pastNameStorage, pastAvatarStorage]);

  const backPageClick = () => {
    setPrincipalScreen("/user-settings");
  };

  const getCatalogData = async () => {
    try {
      const response = await getUsersListAll(userInfo.access_token);

      switch (response?.status) {
        case 200:
          setCatalogData(response.res);
          setCatalogFilteredData(response.res);

          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const searchInputHandler = (search: string) => {
    setSearchCreator(search);
    const filterSearch = catalogData.filter((creator) =>
      creator.displayname.toLowerCase().startsWith(search.toLowerCase())
    );
    setCatalogFilteredData(filterSearch);
  };

  const tokenTreatment = async (token: string) => {
    if (!pastTokenStorage) {
      const pastToken = userInfo.access_token;
      const pastName = userInfo.display_name;

      setPastProfileName(pastName);
      localStorage.clear();
      getUserDetailsInfo(pastToken);
      localStorage.setItem("user-info-past-token", pastToken);
      localStorage.setItem("user-info-past-name", pastName);
    } else {
      localStorage.clear();
    }
    const userInfoToken = (await jwtDecode.jwtDecode(token)) as IInfoToken;

    setUserInfo("access_token", token);
    setUserInfo("display_name", userInfoToken?.validUser.displayname);
    setUserInfo("account_type", userInfoToken?.validUser.role);
    setUserInfo("user_phone", userInfoToken?.validUser.phone);
    setUserInfo("user_email", userInfoToken?.validUser.email);
    setUserInfo("user_id", userInfoToken?.validUser.userId);

    const responseStatus = await getCreatorStatus(token);

    if (responseStatus?.status === 200) {
      setUserInfo("referral_code", responseStatus?.res.referralCode);
      setUserInfo("creator_application_status", responseStatus?.res.status);
      setUserInfo(
        "creator_application_id",
        responseStatus?.res.creatorApplicationId
      );
    }
  };

  const selectedCreatorHandler = async (creator?: ICatalogResponse) => {
    setIsLoading(true);
    setModalContent(<LoadingPage />);
    setOpenUseModal(true);
    const token = pastTokenStorage ?? userInfo.access_token;

    try {
      if (creator && !pastTokenStorage) {
        const response = await getAuthTokenById(token, creator.userId);

        switch (response.status) {
          case 200:
            tokenTreatment(response.res.access_token);

            break;
          case 403:
            setToastConfig({
              description:
                "You do not have permission to perform this operation",
              title: "Permission denied",
              type: "error",
            });

            setToastShow(true);

            setTimeout(() => {
              setToastShow(false);
            }, 4000);
            break;

          default:
            setToastConfig({
              description: "We had a problem executing the request",
              title: "Oops...",
              type: "error",
            });

            setToastShow(true);

            setTimeout(() => {
              setToastShow(false);
            }, 4000);
            break;
        }
      } else if (pastTokenStorage) {
        tokenTreatment(pastTokenStorage);
      }
    } catch (error) {
      console.error(error);
      setToastConfig({
        description: "We had a problem executing the request",
        title: "Oops...",
        type: "error",
      });

      setToastShow(true);

      setTimeout(() => {
        setToastShow(false);
      }, 4000);
    } finally {
      setIsLoading(false);
      setOpenUseModal(false);
    }
  };

  const getUserDetailsInfo = async (token: string) => {
    const response = await getUserInfo(token);

    switch (response.status) {
      case 200:
        if (response?.res?.message?.statusCode === 401) {
          navigate("/login");
        } else {
          setPastProfileAvatar(response.res?.photos[0]?.url);
          localStorage.setItem(
            "user-info-past-avatar",
            response.res?.photos[0]?.url
          );
        }
        break;
      case 401:
        navigate("/login");
        break;
      default:
        navigate("/login");
        break;
    }
  };

  return (
    <div className="user-settings-change-profile">
      <PageTitle
        title={"Manage Profiles"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        className="margin-hor-16"
        onBackClick={backPageClick}
      />
      <main className="user-settings-change-profile__main margin-24">
        {pastTokenStorage && pastAvatarStorage && pastNameStorage ? (
          <ListItemLink
            highlightText={"Return to your profile"}
            simpleText={pastNameStorage}
            hasArrowRight
            onClick={selectedCreatorHandler}
            isImageCircle
            imageLeft={pastAvatarStorage}
            iconFill
          />
        ) : (
          <>
            <InputText
              inputType="text"
              searchInput
              placeholder="Search creator"
              onChangeInput={searchInputHandler}
              value={searchCreator}
            />{" "}
            <section className="main__creators-list-wrapper margin-top-16">
              {catalogFilteredData.map((creator, index, arr) => {
                return (
                  <ListItemLink
                    key={index}
                    highlightText={creator.displayname}
                    hasArrowRight
                    onClick={() => selectedCreatorHandler(creator)}
                    isImageCircle
                    hasSeparator={arr.length > index}
                    imageLeft={creator.profile.photos[0].url}
                    iconFill
                  />
                );
              })}
            </section>
          </>
        )}
      </main>
      <Toast
        type={toastConfig.type}
        isVisible={toastShow}
        setIsVisible={setToastShow}
        title={toastConfig.title}
        description={toastConfig.description}
      />
    </div>
  );
};

export default UserSettingsChangeProfile;
