import postUploadMedia from "../../api/chat/postUploadMedia";
import postMoment, { IPostMomentBody } from "../../api/moments/postMoment";
import postPost, { PostBodyRequest } from "../../api/posts/postPost";
import {
  base64OrBlobToFile,
  handleUploadMedia,
} from "../../utils/base64OrBlobToFile";

type ICapturedMedia = {
  type: string;
  media: string;
  thumbnail?: string;
};

const removeDuplicateIdsToSend = (list: string[]) => {
  const uniqueIds = Array.from(new Set(list));
  return uniqueIds;
};

export const uploadFileInFeed = async (
  capturedMedia: [] | ICapturedMedia[],
  filesPrice: number,
  token: string,
  userName: string
) => {
  try {
    const files = await Promise.all(
      capturedMedia.map((file, index) =>
        base64OrBlobToFile(file.media, `${userName}-${index}`)
      )
    );

    const validFiles = files.filter((file): file is File => file !== null);
    if (validFiles.length === 0) {
      throw new Error("error: validFiles.length === 0");
    }

    const prepareData: PostBodyRequest = {
      files: validFiles,
      price: filesPrice,
    };

    const request = await postPost(token, prepareData);

    switch (request.status) {
      case 201:
        break;
      default:
        break;
    }
  } catch (error) {
    console.error(error);
  }
};

export const uploadFileInMoment = async (
  capturedMedia: [] | ICapturedMedia[],
  filesPrice: number,
  token: string
) => {
  try {
    const uploadPromises = capturedMedia.map(async (fileMedia) => {
      const file = await handleUploadMedia([fileMedia.media]);

      const prepareData: IPostMomentBody = {
        file,
        price: filesPrice,
      };

      const request = await postMoment(token, prepareData);

      if (request?.status !== 201) {
        throw new Error(
          `Failed to create moment for file ${fileMedia.media}: ${request?.res}`
        );
      }
    });

    await Promise.all(uploadPromises);
  } catch (error) {
    console.error("Error uploading moment:", error);
  }
};

export const uploadFilesToChat = async (
  chatsIds: string[],
  capturedMedia: [] | ICapturedMedia[],
  token: string,
  descriptionContent: string,
  paidContent: boolean,
  rosesAmount: number,
  onUploadComplete: (() => void) | undefined
) => {
  const listChatIdsWithoutDuplicates = removeDuplicateIdsToSend(chatsIds);
  try {
    const files = await handleUploadMedia(
      capturedMedia.map((file) => file.media)
    );
    const uploadPromises = listChatIdsWithoutDuplicates.map((chatId) => {
      return postUploadMedia(token, {
        chatId,
        content: descriptionContent,
        price: rosesAmount,
        paidContent,
        files,
      });
    });
    await Promise.all(uploadPromises);
    if (onUploadComplete) {
      setTimeout(() => {
        onUploadComplete();
      }, 100);
    }
  } catch (error) {
    console.error(error);
  }
};
