import { useEffect, useState } from "react";
import "./styles.scss";
import PageTitle from "../../components/PageTitles";
import ShieldIconGray from "../../assets/icons/shieldIcons/personal-icon-gray.svg";
import Button from "../../components/Buttons/Button";
import { useDeskNavigation } from "../../hooks/useDeskNavigation";
import SubText from "../../components/Texts/SubText";
import CloseIconPeach from "../../assets/icons/closeIcons/close-icon-peach.svg";
import ListItemSecondary from "../../components/Lists/ListItemSecondary";
import ListItemToggle from "../../components/Lists/ListItemToggle";
import getUserSelfDetail from "../../api/getUserSelfDetail";
import { useUserInfo } from "../../hooks/userInfo";
import PatchUserSelfDetailApi from "../../api/patchUserSelfDetail";
import getBlockedLocations from "../../api/user/getBlockedLocations";
import PostBlockedLocations from "../../api/user/postBlockedLocations";
import DeleteBlockedLocations from "../../api/user/deleteBlockedLocations";
import getUserLocationHistory from "../../api/userInfo/getUserLocationHistory";
interface ListDetails {
  place?: string;
  region?: string;
  country?: string;
  country_code?: string;
  country_code_alpha_3?: string;
  locationId?: string;
  latitude?: number;
  longitude?: number;
  locationHistoryId: string;
}

const UserSettingsPrivacyPage: React.FC = () => {
  const [isProfileHidden, setIsProfileHidden] = useState(false);
  const [isBlockRequests, setIsBlockRequests] = useState(false);
  const [hideEveryone, setHideEveryone] = useState();
  const [hideLocation, setHideLocation] = useState<boolean>();
  const [historyLocationsBlocked, setHistoryLocationsBlocked] = useState<
    ListDetails[] | any[]
  >();
  const [locationList, setLocationList] = useState<ListDetails[]>();
  const [activeLocation, setActiveLocation] = useState<boolean>();
  const { setPrincipalScreen } = useDeskNavigation();
  const { userInfo } = useUserInfo();

  const handleToggleProfileHidden = (newState: boolean) => {
    setIsProfileHidden(newState);
  };

  const handleToggleBlockRequests = (newState: boolean) => {
    setIsBlockRequests(newState);
  };

  /*   const addLocationHandler = async () => {
    const res = await PostBlockedLocations(userInfo.access_token, locationMock);
    getUserDetail();
  }; */

  const openLocationPage = () => {
    setPrincipalScreen("/user-blocked-location");
  };

  const removeLocationHandler = async (index: number) => {
    if (locationList) {
      const locationId = locationList[index].locationHistoryId || "teste";

      const res = await DeleteBlockedLocations(
        userInfo.access_token,
        locationId
      );
      getUserDetail();
    }
  };

  const activateLocationShieldHandler = async (e: any) => {
    setActiveLocation(e);
    if (e === false) {
      hideSpecificLocationHandler(e);
      hideEveryoneHandler(e);
    }
  };

  const hideSpecificLocationHandler = async (e: any) => {
    const res = await PatchUserSelfDetailApi(userInfo.access_token, {
      blockLocation: e,
    });
    setHideLocation(e);
  };

  const hideEveryoneHandler = async (e: any) => {
    const res = await PatchUserSelfDetailApi(userInfo.access_token, {
      isHidden: e,
    });
    setHideEveryone(e);
  };

  const requestLocationHistory = async () => {
    const response = await getBlockedLocations(userInfo.access_token);

    if (response.res) {
      const history = response.res.map((item: any) => ({
        ...item.location,
        locationHistoryId: item.blockedLocationId,
      }));
      setLocationList(
        history
          .filter(
            (item: any, index: any, array: any) =>
              index ===
              array.findIndex((i: any) => i.locationId === item.locationId)
          )
          .map((item: ListDetails) => item)
      );
    }
  };

  const getUserDetail = async () => {
    const res = await getUserSelfDetail(userInfo.access_token);
    requestLocationHistory();
    // setHistoryLocationsBlocked([locationMock]);

    setHideLocation(res.res.blockLocation);
    setHideEveryone(res.res.isHidden);
    if (res.res.blockLocation || res.res.isHidden) {
      setActiveLocation(true);
    } else {
      setActiveLocation(false);
    }
  };

  useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <div className="user-settings-privacy ">
      <PageTitle
        title={"Account Privacy"}
        subtitle={"Configuration"}
        hasCloseIcon={false}
        onBackClick={() => setPrincipalScreen("/user-settings-account")}
        className="margin-hor-16"
      />
      <div className="list-container margin-hor-24">
        <SubText fontSize="14px">
          Emergency Assistance lets you choose contacts for emergencies.
          Contacts are notified only if an emergency occurs, receiving your last
          location and activity details for prompt help.
        </SubText>

        <ListItemToggle
          toggleCallBack={activateLocationShieldHandler}
          hasSeparator
          imageLeft={ShieldIconGray}
          isImageCircle
          toggleInitialState={activeLocation}
          simpleText="Profile will only show to specific users"
          highlightText="Activate Location Shield"
        />

        {activeLocation && (
          <>
            <ListItemToggle
              toggleCallBack={hideEveryoneHandler}
              hasSeparator
              toggleInitialState={hideEveryone}
              highlightText="Hide from everyone"
              simpleText="Only show profile to users with a direct link"
            />

            <ListItemToggle
              toggleCallBack={hideSpecificLocationHandler}
              hasSeparator
              toggleInitialState={hideLocation}
              highlightText="Hide specific location"
              simpleText={`Your profile will be hidden from users in these 
						locations`}
            />
            {locationList && hideLocation ? (
              <div className="location-list-item">
                {locationList.map((location: ListDetails, index, arr) => (
                  <ListItemSecondary
                    key={index}
                    showLabel={true}
                    labelContent={location.place + ", " + location.country}
                    showIconRight
                    iconRightContent={CloseIconPeach}
                    showSeparator={arr.length > index + 1}
                    className="location-list-item-location"
                    actionIconRight={() => {
                      removeLocationHandler(index);
                    }}
                  />
                ))}
                <Button
                  buttonStyle="tertiary"
                  onClick={openLocationPage}
                  className="margin-top-24"
                >
                  Add location
                </Button>
              </div>
            ) : (
              <div>
                <span>Nenhuma localização bloqueada</span>
                <Button
                  buttonStyle="tertiary"
                  onClick={openLocationPage}
                  className="margin-top-24"
                >
                  Add location
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserSettingsPrivacyPage;
