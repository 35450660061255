import React, { createContext, ReactNode, useState } from "react";

interface FavoritesCatalogTriggerProps {
  favoritesCatalogTrigger: boolean;
  setFavoritesCatalogTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FavoritesTriggerProviderProps {
  children: ReactNode;
}

export const FavoritesCatalogContext = createContext(
  {} as FavoritesCatalogTriggerProps
);

export const FavoritesCatalogTriggerProvider: React.FC<
  FavoritesTriggerProviderProps
> = ({ children }) => {
  const [favoritesCatalogTrigger, setFavoritesCatalogTrigger] = useState(false);

  return (
    <FavoritesCatalogContext.Provider
      value={{ favoritesCatalogTrigger, setFavoritesCatalogTrigger }}
    >
      {children}
    </FavoritesCatalogContext.Provider>
  );
};
