import React, { useState } from "react";
import "./styles.scss";

import ButtonIcon from "../../Buttons/ButtonIcon";

import { useDeskNavigation } from "../../../hooks/useDeskNavigation";
import { useIconContext } from "../../../contexts/iconNavContext";

import InputText from "../../Inputs/InputText";

import filterIcon from "../../../assets/icons/iconfilter.svg";
import { useSearchCatalog } from "hooks/useSearchCatalog";

interface IHeaderMobileBottom {
  hasANewMessage: boolean;
  className?: string;
  clickChatList?: (a?: any) => void;
}

const HeaderMobileBottom: React.FC<IHeaderMobileBottom> = (props) => {
  const { hasANewMessage, className, clickChatList } = props;

  const { getIconClass, handleButtonClick } = useIconContext();

  const { searchValue, setSearchValue } = useSearchCatalog();

  const {
    setFeedScreen,
    setPrincipalScreen,
    setSecondaryScreen,
    setTertiaryScreen,
    setMainScreen,
  } = useDeskNavigation();

  const clickOnSearch = () => {
    setFeedScreen("");
    setPrincipalScreen("");
    setSecondaryScreen("");
    setTertiaryScreen("");
    setMainScreen("/catalog");
  };

  return (
    <div className={`container_search ${className ? className : ""}`}>
      <div className="left-buttons-container">
        <ButtonIcon
          icon="icon-grid"
          buttonStyle="tertiary"
          iconClass={getIconClass("grid")}
          className="header-mobile-bottom-btn"
          onClick={() => {
            handleButtonClick("grid");
            setPrincipalScreen("");
          }}
        />
        <ButtonIcon
          icon="icon-heart-outline"
          buttonStyle="tertiary"
          iconClass={getIconClass("heart-outline")}
          className="header-mobile-bottom-btn"
          onClick={() => {
            handleButtonClick("heart-outline");
          }}
        />
      </div>
      <div className="container-input">
        <InputText
          onClick={clickOnSearch}
          value={searchValue}
          searchInput
          onChangeInput={setSearchValue}
          placeholder="Search"
          inputStyle="input-text-search"
          iconRight={filterIcon}
          inputType="text"
        />
      </div>
      <div className="right-buttons-container">
        <ButtonIcon
          icon="icon-messages"
          buttonStyle="tertiary"
          iconClass={getIconClass("messages")}
          className="header-mobile-bottom-btn"
          onClick={() => {
            clickChatList && clickChatList();
          }}
        />
        {hasANewMessage && <span className="sphere-icon"></span>}
      </div>
    </div>
  );
};

export default HeaderMobileBottom;
