import { IMoments } from "types/moments";
import fetchApi from "../fetchApi";

const getUserMoments = async (token: string, userId: string) => {
  return await fetchApi({
    service: "moments",
    method: "GET",
    endPoint: `/user/${userId}`,
    token,
  });
};

export default getUserMoments;
